import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ManagementPanelComponent } from './../../shared/components';

@Component({
  templateUrl: '../../shared/components/management-panel/management-panel.component.html',
  styleUrls: ['../../shared/components/management-panel/management-panel.component.scss']
})
export class ModeratorPanelComponent extends ManagementPanelComponent implements OnInit {

  constructor(public translate: TranslateService,
              public router: Router) {
    super(translate, router);
  }

  ngOnInit() {
    this.items = [
      { label: this.translate.instant('Label.Users'), routerLink: '/moderator/users' },
      { label: this.translate.instant('Label.CompanyStructure'), routerLink: '/moderator/locations' },
      { label: this.translate.instant('Label.ReservationTypes'), routerLink: '/moderator/reservation-types' },
      { label: this.translate.instant('Label.ReservationStatuses'), routerLink: '/moderator/reservation-statuses' },
      { label: this.translate.instant('Label.ReservationReport'), routerLink: '/moderator/reservation-report' },
    ];

    this.selectedItem = this.items.find(x => x.routerLink === this.router.url);
  }
}
