import { IConvertable } from '../../../../helper/interfaces';
import { countDuration } from '../../../../helper/date-time-helpers';

export class SlotItem implements IConvertable<any> {

    startDateTime: Date;
    endDateTime: Date;
    itemType: string;
    priority: number;
    canModify: boolean;
    periodicityDays: number;

    isChecked: boolean;

    get durationMinutes(): number {
        return countDuration(this.startDateTime, this.endDateTime);
    }

    convertFrom(input: any): this {
        Object.assign(this, input);
        this.startDateTime = new Date(input.startDateTime);
        this.endDateTime = new Date(input.endDateTime);
        return this;
    }
}
