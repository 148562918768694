import { IConvertable } from 'src/app/helper/interfaces';
import { Slot } from '../base';

export class SlotOutput implements IConvertable<any> {

    name: string;
    isActive: boolean;
    warehouseId: number;
    userIds: string[];

    convertFrom(input: any): this {
        this.name = input.name;
        this.isActive = input.isActive;
        this.warehouseId = input.warehouseId;
        this.userIds = input.userIds;

        return this;
    }
}