import { Component, OnInit, Input } from '@angular/core';
import { ReservationSlotItem } from '../../shared/models/base/slot-item/reservation-slot-item.model';
import { ColorHelperService } from 'src/app/shared/services/color-helper.service';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  @Input() reservation: ReservationSlotItem;

  constructor(private colorHelperService: ColorHelperService) { }

  ngOnInit() {
  }

  setHeaderStyle(): any {
    const style = {};
    if (this.reservation.statusColor) {
      // tslint:disable-next-line: no-string-literal
      style['color'] = this.colorHelperService.invertColor(this.reservation.statusColor, true);
      style['background-color'] = this.reservation.statusColor;
    } else {
      // tslint:disable-next-line: no-string-literal
      style['color'] = '#000';
      style['background-color'] = 'transparent';
    }
    return style;
  }
}
