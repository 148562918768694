<div class="ml-3 mr-3 mt-2 mb-3" style="width: 80vw; max-width: 400px;">
  <form [formGroup]="assignCompanyForm">

    <div class="form-group">
      <label for="role" translate>Label.Role</label>
      <p-dropdown [autoDisplayFirst]="false" [options]="availableRoles" formControlName="role" optionLabel="name"
        [style]="{'min-width':'0', 'width': '100%'}" [ngClass]="{ 'is-invalid': submitted && form.role.errors }"
        required>
      </p-dropdown>
      <app-form-error-message *ngIf="submitted" [control]="form.role"></app-form-error-message>

      <label for="role" translate>Label.Company</label>
      <p-dropdown [autoDisplayFirst]="false" [options]="availableCompanies" formControlName="company" optionLabel="name"
        [style]="{'min-width':'0', 'width': '100%'}" [ngClass]="{ 'is-invalid': submitted && form.company.errors }"
        required>
      </p-dropdown>
      <app-form-error-message *ngIf="submitted" [control]="form.company"></app-form-error-message>
    </div>

    <div class="d-flex flex-row-reverse mt-3">
      <button type="button" class="btn btn-outline-dark" icon="pi pi-times" (click)="saveClicked()"
        translate>Button.Save</button>
      <button type="button" class="btn btn-outline-dark mr-1" (click)="cancelClicked()" translate>Button.Cancel</button>
    </div>
  </form>
</div>