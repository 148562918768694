import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CoreService } from '../core.service';
import { AuthService } from '../../shared/services';
import { AlertService } from '../../alert';
import { CompanyRole } from '../../shared/models/base/companyRole.model';
import { UserExt } from '../../shared/models/extended/userExt.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

  currentUserSubject: Subscription;
  currentUser: UserExt;

  selectedCompanySubject: Subscription;
  selectedCompany: CompanyRole;

  constructor(private coreService: CoreService,
              private router: Router,
              private authService: AuthService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.currentUserSubject = this.coreService.user$.subscribe({
      next: (next) => this.currentUser = next,
    });

    this.selectedCompanySubject = this.coreService.selectedCompany$.subscribe({
      next: (next) => {
        this.alertService.clear();
        this.selectedCompany = next;
      }
    });
  }

  ngOnDestroy(): void {
    this.currentUserSubject.unsubscribe();
    this.selectedCompanySubject.unsubscribe();
  }

  isLoggedIn() {
    return !!this.coreService.userValue;
  }

  logOut() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  selectCompany(company: CompanyRole) {
    this.coreService.selectCompany(company);
  }
}
