import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { Alert, AlertType } from '../alert.model';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-alert',
  template: `
  <div *ngFor="let alert of alerts" [class]="getClass(alert)" class="pt-1 pb-1 mb-0">
    <button class="close mt-0 mr-1" (click)="remove(alert)">&times;</button>
    <span class="m-1 m-md-2" [innerHTML]="alert.message"></span>
    <footer class="ml-1 ml-md-2">
        {{alert.date | date: 'HH:mm:ss'}}
    </footer>
  </div>
  `,
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {

  @Input() id = 'global-alert';
  @Input() allowManyAlerts = false;
  @Input() autocloseDuration = 5000;
  @Input() class = '';
  @Input() scrollElementId: string;

  public alerts: Alert[] = [];

  private alertSubscription: Subscription;
  private routeSubscription: Subscription;

  constructor(private router: Router,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.alertSubscription = this.alertService.onAlert(this.id).subscribe(alert => {
      if (!alert.message) {
        // Wywołanie Clear
        this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);
        this.alerts.forEach(x => x.keepAfterRouteChange = false);
        return;
      }
      if (!this.allowManyAlerts) {
        this.alerts = [];
      }

      if (!alert.sticky) {
        this.scrollWindow();
      }

      this.alerts.push(alert);

      if (alert.autoClose) {
        setTimeout(() => this.remove(alert), this.autocloseDuration);
      }
    });

    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
  }

  ngOnDestroy(): void {
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  remove(alert: Alert) {
    const a = this.alerts.find(x => x === alert);
    if (a) {
      a.fade = true;
    }

    setTimeout(() => {
      this.alerts = this.alerts.filter(x => x !== alert);
    }, 250);
  }

  scrollWindow() {

    if (this.scrollElementId) { // Jeśli wskażemy konkretne id to zawsze scrollujemy wskazany element
      const element = document.getElementById(this.scrollElementId);
      if (element) {
        element.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
    } else {
      const elements = document.getElementsByClassName('ui-dialog-content');
      if (elements.length === 0) { // Jeśli to nie jest dialog, to scrollujemy całe okno
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      } else { // Jeśli jesteśmy w dialogu to scrollujemy do góry dialog
        elements[0].scroll({ top: 0, left: 0, behavior: 'smooth'});
      }
    }
  }

  getClass(alert: Alert): string {
    if (!alert) {
      return;
    }

    const classes = ['alert'];

    switch (alert && alert.type) {
      case AlertType.Success:
        classes.push('alert-success');
        break;
      case AlertType.Warning:
        classes.push('alert-warning');
        break;
      case AlertType.Error:
        classes.push('alert-danger');
        break;
    }

    if (alert.sticky) {
      classes.push('alert-sticky');
    }
    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ') + ' ' + this.class;
  }
}
