<app-base-popup (cancelClicked)="cancelClicked()" (saveClicked)="saveClicked()" [loading]="loading">
  <form [formGroup]="slotForm" style="margin-bottom: 30px">
    <div class="form-group">
      <label for="name" translate>Label.Name</label>
      <input formControlName="name" class="form-control" placeholder="{{'Label.Name' | translate}}" required
        [ngClass]="{ 'is-invalid': submitted && form.name.errors }" />
      <app-form-error-message *ngIf="submitted" [control]="form.name"></app-form-error-message>
    </div>

    <div class="form-group">
      <p-multiSelect [options]="users" formControlName="users" [filter]="false" optionLabel="email"
        [style]="{'width': '100%'}" defaultLabel="{{'Label.Users' | translate}}">
      </p-multiSelect>
    </div>

    <div class="form-check ml-3 mb-2">
      <input class="form-check-input" type="checkbox" formControlName="isActive" id='isActiveCb'
        [ngClass]="{ 'is-invalid': submitted && form.isActive.errors }">
      <label class="form-check-label" for="isActiveCb" translate>
        Label.Active
      </label>
    </div>
  </form>
</app-base-popup>