import { PlatformLog } from '../base/logging/platform-log.model';

export class PlatformLogsLazy {
    public logs: PlatformLog[];
    public totalItemsCount: number;

    convertFrom(input: any): this {
        Object.assign(this, input);

        if (input.items !== undefined) {
            this.logs = input.items.map(c => new PlatformLog().convertFrom(c));
        }

        return this;
    }
}
