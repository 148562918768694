import { DialogService } from 'primeng/dynamicdialog';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UserExt } from '../../../shared/models/extended';
import { Role, Company } from '../../../shared/models/base';
import { UserService } from '../../../shared/services';
import { CompanyRole } from '../../../shared/models/base/companyRole.model';
import { AlertService } from 'src/app/alert';
import { UserManagementAssignCompanyComponent } from '../user-management-assign-company/user-management-assign-company.component';

@Component({
  selector: 'app-user-management-edit',
  templateUrl: './user-management-edit.component.html',
  styleUrls: ['./user-management-edit.component.css'],
  providers: [DialogService]
})
export class UserManagementEditComponent implements OnInit {

  user: UserExt;
  availableRoles: Role[];
  availableCompanies: Company[];
  editUserForm: FormGroup;

  loading: boolean;
  submiting: boolean;
  submitted: boolean;

  get form() {
    return this.editUserForm.controls;
  }

  constructor(private alertService: AlertService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private translateService: TranslateService,
              private userService: UserService,
              private dialogService: DialogService) { }

  ngOnInit() {
    // TODO: Na razie nie wymagamy wypełnienia pól, bo edytujemy użytkownika zaraz po dodaniu - pasuje dodać info o tym, czy jest aktywowany
    this.editUserForm = this.formBuilder.group({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl({ value: '', disabled: true }),
      isAdmin: new FormControl(''),
    });

    if (this.route.snapshot.paramMap.has('userId')) {
      const userId = String(this.route.snapshot.paramMap.get('userId'));

      this.loading = true;

      this.userService.getUser(userId).subscribe({
        next: (next) => {
          this.form.firstName.setValue(next.firstName);
          this.form.lastName.setValue(next.lastName);
          this.form.email.setValue(next.email);
          this.form.isAdmin.setValue(next.isAdmin);
          this.user = next;
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        },
      });

      this.userService.getRoles().subscribe({
        next: (next) => this.availableRoles = next,
        error: (error) => this.alertService.error(error)
      });
    }
  }

  onEditUser() {
    this.alertService.clear();

    if (this.editUserForm.invalid) {
      return;
    }

    this.submiting = true;

    this.user.firstName = this.form.firstName.value;
    this.user.lastName = this.form.lastName.value;
    this.user.isAdmin = this.form.isAdmin.value;

    this.userService.patch(this.user)
      .subscribe({
        next: () => {
          this.translateService.get('Info.UserEditedSuccessfully', { userName: this.user.$firstAndLastName })
            .subscribe(msg => this.alertService.success(msg, {keepAfterRouteChange: true, autoClose: true}));

          this.router.navigate(['.'], { relativeTo: this.route.parent });
        },
        error: (error) => {
          this.alertService.error(error);
          this.submiting = false;
        }
      });
  }

  onCompanyDeleteInit(company: CompanyRole) {
    this.user.companies = this.user.companies.filter(c => c !== company);
  }

  onAssignCompanyClicked() {
    const addUserDialog = this.dialogService.open(UserManagementAssignCompanyComponent, {
      header: this.translateService.instant('Dialog.AssignCompany'),
    });

    addUserDialog.onClose.subscribe((companyRole: CompanyRole) => {
      if (companyRole) {
        if (this.user.companies.filter(c => c.company.id === companyRole.company.id).length > 0) {
          this.alertService.warning(this.translateService.instant('Info.UserHasAlreadyAssignedCompany',
          { companyName: companyRole.company.name }), false);
        } else {
          this.user.companies.push(companyRole);
        }
      }
    });
  }
}
