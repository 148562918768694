import { Role, Company, Slot } from '.';
import { IConvertable } from '../../../helper/interfaces';

export class CompanyRole implements IConvertable<any> {
  public company: Company;
  public role: Role;

  convertFrom(input: any): this {
    if (input.role) {
      this.role = new Role().convertFrom(input.role);
    }

    this.company = new Company().convertFrom(input);

    return this;
  }
}
