import { IConvertable } from 'src/app/helper/interfaces';
import { Translation } from '../base';

export class ReservationStatusOutput implements IConvertable<any> {
    id: number;
    name: string;
    color: string;

    displayNames: Translation[];

    convertFrom(input: any): this {
        this.id = input.id;
        this.name = input.displayName;
        this.color = input.color;

        if (input.displayNames) {
            this.displayNames = input.displayNames.map(c => new Translation().convertFrom(c));
        }

        return this;
    }
}
