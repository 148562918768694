export class Alert {
    componentId: string;
    type: AlertType;
    message: string;
    autoClose: boolean;
    keepAfterRouteChange: boolean;
    sticky: boolean;
    date: Date;
    fade: boolean;

    constructor(init?: Partial<Alert>) {
        Object.assign(this, init);

        this.date = new Date();
    }
}

export enum AlertType {
    Success,
    Error,
    Warning
}
