import { IConvertable } from '../../../helper/interfaces';

export class Location implements IConvertable<any> {

    public id: number;
    public code: string;
    public isActive: boolean;

    convertFrom(input: any): this {
        this.id = input.id;
        this.code = input.code;
        this.isActive = input.isActive;

        return this;
    }
}
