import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { CompanyRole } from '../../shared/models/base/companyRole.model';
import { Language } from '../../shared/models/base';
import { UserService, AuthService } from '../../shared/services';
import { UserExt } from '../../shared/models/extended/userExt.model';
import { AlertService } from 'src/app/alert';
import { CoreService } from 'src/app/core/core.service';

@Component({
  selector: 'app-account-preview',
  templateUrl: './account-preview.component.html',
  styleUrls: ['./account-preview.component.css']
})
export class AccountPreviewComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
              private alertService: AlertService,
              private coreService: CoreService,
              private authService: AuthService,
              private userService: UserService,
              private translateService: TranslateService) { }

  user: UserExt;
  companies: CompanyRole[];

  supportedLanguages: Language[];

  loading: boolean;
  submiting: boolean;
  submitted: boolean;

  editUserForm: FormGroup;

  get form() {
    return this.editUserForm.controls;
  }

  ngOnInit(): void {

    this.coreService.supportedLanguages$.subscribe({
      next: (next) => {
        this.supportedLanguages = next;
      },
      error: (error) => {
        this.alertService.error(error);
      }
    }).unsubscribe();

    this.editUserForm = this.formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl({ value: '', disabled: true }),
      language: new FormControl('', Validators.required)
    });

    const langValue = this.coreService.selectedLanguageValue;

    if (langValue) {
      this.form.language.setValue(langValue);
    }

    this.authService.getLogged().subscribe({
      next: (next) => {
        this.form.firstName.setValue(next.firstName);
        this.form.lastName.setValue(next.lastName);
        this.form.email.setValue(next.email);
        if (next?.languageId) {
          this.form.language.setValue(this.supportedLanguages?.find(lang => lang.id === next?.languageId));
        }
        this.user = next;
        this.companies = next.companies.filter(c => !!c.role);
        this.loading = false;
      },
      error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }

  onEditUser() {
    this.alertService.clear();

    if (this.editUserForm.invalid) {
      return;
    }

    this.submiting = true;

    this.user.firstName = this.form.firstName.value;
    this.user.lastName = this.form.lastName.value;

    this.userService.patch({
      id: this.user.id, firstName: this.user.firstName, lastName: this.user.lastName,
      languageId: this.form.language.value.id
    }).subscribe({
      next: () => {
        this.coreService.selectLanguage(this.form.language.value);
        this.alertService.success(this.translateService.instant('Info.AccountCorrectlyEdited'), {autoClose: true});
        this.submiting = false;
      },
      error: (error) => {
        this.alertService.error(error);
        this.submiting = false;
      }
    });
  }
}
