import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/alert';

import { CoreService } from 'src/app/core/core.service';
import { getDate, getTodayDate, parseDate } from 'src/app/helper/date-time-helpers';
import { Warehouse } from '../../models/base';
import { CompanyRole } from '../../models/base/companyRole.model';
import { LocationService, WarehouseService } from '../../services';
import { Location } from '../../models/base/location.model';
import { WarehouseLoadComponent } from '../warehouse-load/warehouse-load.component';
import { LocaleSettings } from 'primeng';

@Component({
  selector: 'app-slots-filters',
  templateUrl: './slots-filters.component.html',
  styleUrls: ['./slots-filters.component.css']
})
export class SlotsFiltersComponent implements OnInit, OnDestroy {

  @ViewChild('warehouseLoad') warehouseLoad: WarehouseLoadComponent;

  @Output() filterChanged = new EventEmitter<any>();

  @Input() warehouseLoadVisible = true;
  @Input() selectionDateMode = 'single'; // Do wyboru: Single, Multiple, Range
  @Input() useQueryParams = true;

  queryParamsSubscription: Subscription;

  calendarConfiguration: LocaleSettings = {
    firstDayOfWeek: 1,
    dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    dayNamesShort: ['Nie', 'Pon','Wt','Śr','Czw','Pią','Sob'],
    dayNamesMin: ['Nie', 'Pon','Wt','Śr','Czw','Pią','Sob'],
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecien', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    monthNamesShort: ['STY', 'LUT', 'MAR', 'KWI', 'MAJ', 'CZE', 'LIP', 'SIE', 'WRZ', 'PAŹ', 'LIS', 'GRU'],
    today: 'today',
    clear: 'clear'
  };

  selectedCompanySubscription: Subscription;
  selectedCompany: CompanyRole;

  selectedDate: Date | Date[];

  locations: Location[];
  selectedLocation: Location;

  warehouses: Warehouse[];
  selectedWarehouse: Warehouse;

  constructor(public coreService: CoreService,
              private warehouseService: WarehouseService,
              private alertService: AlertService,
              private activatedRoute: ActivatedRoute,
              private locationService: LocationService,
              private router: Router) { }

  ngOnInit(): void {

    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {
      this.setDateFromQuery(params);
    });
    this.selectedCompanySubscription = this.coreService.selectedCompany$.subscribe(company => {
      this.selectedCompany = company;
      if (this.selectedCompany) {
        this.getLocations();
      }
    });
  }

  ngOnDestroy(): void {
    this.selectedCompanySubscription.unsubscribe();

    this.queryParamsSubscription.unsubscribe();
  }

  getLocations() {
    this.locationService.getLocations(this.selectedCompany.company.id, true, null, null).subscribe({
      next: (next) => {
        this.locations = next.locations;
        if (this.locations.length === 1) {
          this.selectLocation(this.locations[0]);
        } else {
          this.emitFilterChanged();
        }
      },
      error: (error) => {
        this.alertService.error(error);
      }
    });
  }

  getWarehouses() {
    this.warehouseService.getWarehouses(this.selectedCompany.company.id, this.selectedLocation.id, true, null, null).subscribe({
      next: (next) => {
        this.warehouses = next.warehouses;
        if (this.warehouses.length === 1) {
          this.selectWarehouse(this.warehouses[0]);
        } else {
          this.emitFilterChanged();
        }
      },
      error: (error) => {
        this.alertService.error(error);
      }
    });
  }

  selectDate(value: Date | Date[]) {

    if (this.useQueryParams) {
      if (value instanceof Date) {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            date: parseDate(value),
          },
          queryParamsHandling: 'merge'
        });
      } else {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            dateFrom: parseDate(value[0]),
            dateTo: parseDate(value[1]),
          },
          queryParamsHandling: 'merge'
        });
      }
    }

    if (!this.selectedDate) {
      this.selectedDate = value;
    } else {
      this.selectedDate = value;
      this.emitFilterChanged();
    }
  }

  setDateFromQuery(queryParams: Params) {

    if (!this.useQueryParams) {
      this.selectDate(getTodayDate());
      return;
    }

    const dateString = queryParams.date;

    if (dateString) {
      if (dateString !== parseDate(this.selectedDate as Date)) {
        this.selectDate(getDate(dateString));
      }
    } else {
      this.selectDate(getTodayDate());
    }
  }

  selectLocation(value) {
    this.selectedLocation = value;
    this.selectedWarehouse = null;

    if (this.selectedLocation) {
      this.getWarehouses();
    } else {
      this.emitFilterChanged();
    }
  }

  selectWarehouse(value) {
    this.selectedWarehouse = value;
    this.emitFilterChanged();
  }

  emitFilterChanged() {
    this.filterChanged.emit({
      date: this.selectedDate,
      locationId: this.selectedLocation?.id,
      locationCode: this.selectedLocation?.code,
      warehouseId: this.selectedWarehouse?.id,
      warehouseName: this.selectedWarehouse?.name
    });
    this.updateWarehouseLoad();
  }

  updateWarehouseLoad() {
    this.warehouseLoad?.getWarehouseLoad(this.selectedCompany.company.id, this.selectedDate as Date,
      this.selectedLocation?.id, this.selectedWarehouse?.id);
  }
}
