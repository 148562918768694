export function MilisecondsToHours(miliseconds: number): number {
    if (!miliseconds) {
        return miliseconds;
    }
    return miliseconds / 60000 / 60;
}

export function MilisecondsToMinutes(miliseconds: number): number {
    if (!miliseconds) {
        return miliseconds;
    }
    return miliseconds / 60000;
}

export function HoursToMiliseconds(hours: number): number {
    if (!hours) {
        return hours;
    }
    return hours * 60000 * 60;
}

export function MinutesToMiliseconds(minutes: number): number {
    if (!minutes) {
        return minutes;
    }
    return minutes * 60000;
}

export function MinutesToDays(minutes: number): number {
    if (!minutes) {
        return minutes;
    }
    return minutes / 1440;
}
