import { ActionLog } from '../base/logging/action-log.model';

export class ActionLogsLazy {
    public logs: ActionLog[];
    public totalItemsCount: number;

    convertFrom(input: any): this {
        Object.assign(this, input);

        if (input.items !== undefined) {
            this.logs = input.items.map(c => new ActionLog().convertFrom(c));
        }

        return this;
    }
}
