import { PlatformLogLevel } from '../models/base/logging/platform-log-level.model';
import { createHttpParams } from '../../helper/http-params-creator-helpers';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { ActionLogsLazy } from '../models/lazy';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { parseDate } from '../../helper/date-time-helpers';
import { User, Language } from '../models/base';
import { PlatformLogsLazy } from '../models/lazy/platform-logs-lazy.model';
import { ServerInfo } from '../models/base/internal/server-info.model';

import { CoreService } from 'src/app/core/core.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(private http: HttpClient,
              private coreService: CoreService) { }

  baseUrl = environment.baseUrl + '/platform';

  getActionLogs(skip?: number, take?: number, ddateFrom?: Date, ddateTo?: Date, usersIds?: string[],
                url?: string, responseCodes?: number[], durationGreaterThan?: number, orderByDesc?: boolean,
                requestParameters?: string, httpMethods?: string[])
                : Observable<ActionLogsLazy> {

    const dateTimeFrom = parseDate(ddateFrom);
    const dateTimeTo = parseDate(ddateTo);

    const params = createHttpParams({
      skip, take, dateTimeFrom, dateTimeTo, usersIds, url,
      responseCodes, durationGreaterThan, orderByDesc, orderBy: 'date', requestParameters, httpMethods
    });

    return this.http.get(this.baseUrl + '/action-logs', { params })
      .pipe(map(logsLazy => new ActionLogsLazy().convertFrom(logsLazy)));
  }

  getActionLogUsers(): Observable<User[]> {
    return this.http.get<any[]>(this.baseUrl + '/action-logs/users')
      .pipe(map(users => users.map(user => new User().convertFrom(user))));
  }

  getPlatformLogs(skip?: number, take?: number, ddateFrom?: Date, ddateTo?: Date,
                  logLevels?: number[], message?: string): Observable<PlatformLogsLazy> {
    const dateTimeFrom = parseDate(ddateFrom);
    const dateTimeTo = parseDate(ddateTo);

    const params = createHttpParams({ skip, take, dateTimeFrom, dateTimeTo, logLevels, message });

    return this.http.get(this.baseUrl + '/logs', { params })
      .pipe(map(logsLazy => new PlatformLogsLazy().convertFrom(logsLazy)));
  }

  getLogLevels(): Observable<PlatformLogLevel[]> {
    return this.http.get<any[]>(this.baseUrl + '/logs/levels')
      .pipe(map(levels => levels.map(level => new PlatformLogLevel().convertFrom(level))));
  }

  getLanguages(): Observable<Language[]> {
    return this.http.get<any[]>(this.baseUrl + '/languages')
    .pipe(map(response => {
      const languages: Language[] = response.map(language => new Language().convertFrom(language));
      this.coreService.saveSupportedLanguages(languages);
      return languages;
    }));
  }

  getInfo(): Observable<ServerInfo> {
    return this.http.get(this.baseUrl + '/info')
    .pipe(map(info => new ServerInfo().convertFrom(info)));
  }
}
