import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { UserExt } from '../models/extended';
import { AlertService } from 'src/app/alert';
import { CoreService } from 'src/app/core/core.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  baseUrl = environment.baseUrl + '/auth';
  private preventAutoLogIn: boolean;

  constructor(private http: HttpClient,
              private coreService: CoreService,
              private alertService: AlertService,
              private translateService: TranslateService) { }

  login(email: string, password: string) {
    return this.http.post(this.baseUrl + '/login', {
      email,
      password
    }).pipe(map(response => {
      if (response) {
        const token: string = (response as any).token.toString();

        if (token) {
          this.coreService.saveToken(token);
        }
      }
    }));
  }

  getLogged(): Observable<UserExt> {
    return this.http.get(environment.baseUrl + '/users/logged')
      .pipe(map(response => {
        if (response) {
          const user: UserExt = new UserExt().convertFrom(response);

          if (this.preventAutoLogIn) {
            this.preventAutoLogIn = false;
            return;
          }

          if (user) {
            this.coreService.saveUser(user);

            this.coreService.selectedCompany$.subscribe(val => {

              if (val === null) {
                val = JSON.parse(localStorage.getItem('selectedCompany'));
              }

              if (user.companies.length > 0 || user.isAdmin) {
                if (val) {
                  if (user.companies.filter(comp => comp.company.id === val.company.id).length > 0) {
                    this.coreService.selectCompany(val);
                  } else {
                    this.coreService.selectCompany(user.companies[0]);
                  }
                } else {
                  this.coreService.selectCompany(user.companies[0]);
                }
              } else {
                this.alertService.warning(this.translateService.instant('Info.YouAreNotAssignedToAnyCompany'),
                                          {keepAfterRouteChange: true});
                this.coreService.selectCompany(null);
              }
            }).unsubscribe();

            return user;
          }
        }
      }));
  }

  logout(preventFirstAutoLog: boolean = false) {
    this.coreService.selectCompany(null);
    this.coreService.saveUser(null);
    this.coreService.saveToken(null);

    this.preventAutoLogIn = preventFirstAutoLog;
  }
}
