import { AlertModule } from './../alert/alert.module';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  CompanyManagementComponent,
  CompanyManagementListComponent,
  CompanyManagementAddComponent,
  CompanyManagementEditComponent,
  AdminPanelComponent,
  UserManagementComponent,
  UserManagementEditComponent,
  UserManagementListComponent,
  ActionLogsPreviewComponent,
  PlatformLogsPreviewComponent,
  UserManagementAddUserComponent,
  UserManagementAssignCompanyComponent,
  CompanyAddUserPopupComponent
} from './';

@NgModule({
  declarations: [
    CompanyManagementComponent,
    CompanyManagementListComponent,
    CompanyManagementAddComponent,
    CompanyManagementEditComponent,
    AdminPanelComponent,
    UserManagementComponent,
    UserManagementEditComponent,
    UserManagementListComponent,
    UserManagementAddUserComponent,
    UserManagementAssignCompanyComponent,
    ActionLogsPreviewComponent,
    PlatformLogsPreviewComponent,
    CompanyAddUserPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule
  ],
  exports: [
    CompanyManagementComponent,
    CompanyManagementListComponent,
    CompanyManagementAddComponent,
    CompanyManagementEditComponent,
    AdminPanelComponent,
    UserManagementComponent,
    UserManagementEditComponent,
    UserManagementListComponent,
    UserManagementAddUserComponent,
    UserManagementAssignCompanyComponent,
    ActionLogsPreviewComponent,
    PlatformLogsPreviewComponent,
  ],
  entryComponents: [
    UserManagementAddUserComponent,
    UserManagementAssignCompanyComponent,
    CompanyAddUserPopupComponent
  ]
})
export class AdminModule { }
