<div class="m-2">
  <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.UserEdit</h2>
  <form [formGroup]="editUserForm" (ngSubmit)="onEditUser()" class="row">

    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mt-4">
      <h5 class="form-title pb-lg-3 pb-sm-1" translate>Label.UserData</h5>

      <div class="form-group">
        <label for="firstName" translate>Label.FirstName</label>
        <input type="text" formControlName="firstName" class="form-control"
          placeholder="{{'Label.FirstName' | translate}}"
          [ngClass]="{ 'is-invalid': submitted && form.firstName.errors }" />

        <app-form-error-message *ngIf="submitted" [control]="form.firstName"></app-form-error-message>
      </div>

      <div class="form-group">
        <label for="lastName" translate>Label.LastName</label>
        <input type="text" formControlName="lastName" class="form-control"
          placeholder="{{'Label.LastName' | translate}}"
          [ngClass]="{ 'is-invalid': submitted && form.lastName.errors }" />

        <app-form-error-message *ngIf="submitted" [control]="form.lastName"></app-form-error-message>
      </div>

      <div class="form-group">
        <label for="email" translate>Label.Email</label>
        <input type="text" formControlName="email" class="form-control" />
      </div>

      <div class="form-check ml-3">
        <input class="form-check-input" type="checkbox" formControlName="isAdmin" id='isAdminCb'
          [ngClass]="{ 'is-invalid': submitted && form.isAdmin.errors }">
        <label class="form-check-label" for="isAdminCb" translate>
          Label.Administrator
        </label>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mt-4">
      <h5 class="form-title pb-lg-3 pb-sm-1" translate>Label.AssignedCompanies</h5>

      <p-scrollPanel [style]="{'width': '100%'}">
        <p-table [value]="user?.companies" dataKey="company.id" editMode="row" [loading]="loading"
          [style]="{'min-width':'430px', 'min-height':'300px' }">
          <ng-template pTemplate="header">
            <tr>
              <th translate>Label.CompanyName</th>
              <th translate>Label.Role</th>
              <th translate>Label.Options</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-company let-editing="editing">
            <tr [pEditableRow]="company">
              <td>
                {{company.company.name}}
              </td>

              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown [options]="availableRoles" [(ngModel)]="company.role"
                      [ngModelOptions]="{standalone: true}" optionLabel="name"
                      [style]="{'min-width':'0', 'width': '100%'}"></p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{company.role.name}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td style="text-align:center">
                <button *ngIf="!editing" type="button" pInitEditableRow
                  class="btn btn-sm btn-outline-dark mr-1 pi pi-pencil"></button>

                <button *ngIf="!editing" type="button" class="btn btn-sm btn-outline-dark pi pi-trash"
                  (click)="onCompanyDeleteInit(company)"></button>

                <button *ngIf="editing" type="button" pSaveEditableRow class="btn btn-sm btn-outline-dark pi pi-check"
                  style="margin-right: .5em"></button>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="summary" let-rowData>
            <div style="text-align:left">
              <button type="button" class="btn btn-sm btn-outline-dark mt-2" (click)="onAssignCompanyClicked()"
                translate>Button.AssignCompany</button>
            </div>
          </ng-template>
        </p-table>
      </p-scrollPanel>
    </div>

    <div class="form-group mt-4 ml-3">
      <button [disabled]="submiting || loading" type="submit" class="btn btn-outline-dark" translate>
        <span *ngIf="submiting" class="spinner-border spinner-border-sm mr-1"></span>
        Button.SaveChanges
      </button>
    </div>
  </form>
</div>
