import { IConvertable } from '../../../helper/interfaces';

export class Company implements IConvertable<any> {
  public id: number;
  public name: string;
  public isActive: boolean;

  convertFrom(input: any): this {
    return Object.assign(this, input);
  }
}
