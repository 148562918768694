import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-base-popup',
  templateUrl: './base-popup.component.html',
  styleUrls: ['./base-popup.component.scss']
})
export class BasePopupComponent implements OnInit {

  @Input() loading: boolean;

  @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
  @Output() saveClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onSaveClicked() {
    this.saveClicked.emit();
  }

  onCancelClicked() {
    this.cancelClicked.emit();
  }
}
