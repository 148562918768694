import { IConvertable } from 'src/app/helper/interfaces';
import { Location } from '../base';

export class LocationOutput implements IConvertable<Location> {

    code: string;
    isActive: boolean;

    convertFrom(input: Location): this {
        this.code = input.code;
        this.isActive = input.isActive;

        return this;
    }
}
