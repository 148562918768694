import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-error-message',
  templateUrl: './form-error-message.component.html',
  styleUrls: ['./form-error-message.component.css']
})
export class FormErrorMessageComponent implements OnInit {

  @Input() control: AbstractControl;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  getErrorMesssage(): string {
    if (this.control.errors) {
      if (this.control.errors.required) {
        return this.translate.instant('Info.FieldIsRequired');
      } else if (this.control.errors.email) {
        return this.translate.instant('Info.EmailIsNotCorrect');
      } else if (this.control.errors.timeIncorrect) {
        return this.translate.instant('Info.TimeIsNotCorrect');
      } else if (this.control.errors.durationLessThanPeriod) {
        return this.translate.instant('Info.DurationCannotBeGreaterThanPeriodicityc');
      } else if (this.control.errors.min) {
        return this.translate.instant('Info.ValueCannotBeLessThan', {val: this.control.errors.min.min});
      } else if (this.control.errors.max) {
        return this.translate.instant('Info.ValueCannotBeGreaterThan', {val: this.control.errors.max.max});
      } else {
        return this.translate.instant('Info.FieldIsNotCorrect');
      }
    }
  }
}
