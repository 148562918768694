import { MinutesToMiliseconds, MilisecondsToMinutes } from './duration-helpers';

export function parseDate(date: Date): string {
    if (!date) {
        return undefined;
    }
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();

    return (yyyy + '-' + mm + '-' + dd);
}

export function parseDateTimeToLocalDateTimeString(date: Date): string {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();

    const HH = String(date.getHours()).padStart(2, '0');
    const MM = String(date.getMinutes()).padStart(2, '0');
    const SS = String(date.getSeconds()).padStart(2, '0');

    return (yyyy + '-' + mm + '-' + dd + 'T' + HH + ':' + MM + ':' + SS);
}

export function getDate(dateString: string): Date {
    const date = new Date(dateString);

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
}

export function getYesterdayDate(): Date {
    const d = getTodayDate();
    d.setDate(d.getDate() - 1);

    return d;
}

export function getTodayDate(): Date {
    const date = new Date();

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
}

export function addDays(date: Date, numberOfDays: number): Date {
    const d = new Date(date);
    d.setDate(d.getDate() + numberOfDays);
    return d;
}

export function addMinutes(date: Date, numberOfMinutes: number): Date {
    const d = new Date(date);
    d.setTime(d.getTime() + MinutesToMiliseconds(numberOfMinutes));
    return d;
}

export function countDuration(startDate: Date, endDate: Date) {
    if (MilisecondsToMinutes(endDate.getTime() - startDate.getTime()) > 0) {
      return Math.abs(MilisecondsToMinutes(endDate.getTime() - startDate.getTime()));
    }
    return 0;
}

export function getMinutesFromMidnight(date: Date): number {
    return date.getHours() * 60 + date.getMinutes();
}

export function getMinutesToMidnight(date: Date): number {
    return 1440 - getMinutesFromMidnight(date);
}

export function min(dateOne: Date, dateTwo: Date): Date {
    if (dateOne.getTime() < dateTwo.getTime()) {
        return dateOne;
    }
    return dateTwo;
}

export function max(dateOne: Date, dateTwo: Date): Date {
    if (dateOne.getTime() > dateTwo.getTime()) {
        return dateOne;
    }
    return dateTwo;
}
