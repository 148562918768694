import { IConvertable } from '../../../helper/interfaces';
import { UserExt } from '../extended';
import { CompanyRoleOutput } from './companyRole.output.model';

export class UserEdit implements IConvertable<UserExt> {

  firstName: string;
  lastName: string;
  isAdmin: boolean;
  languageId: number;

  companies: CompanyRoleOutput[];

  convertFrom(input: UserExt): this {
    this.firstName = input.firstName;
    this.lastName = input.lastName;
    this.isAdmin = input.isAdmin;
    this.languageId = input.languageId;

    if (input.companies !== undefined && input.companies !== null) {
      this.companies = input.companies.map(cr => new CompanyRoleOutput().convertFrom(cr));
    }

    return this;
  }
}
