import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageBoxComponent } from './component/message-box.component';

import { SharedModule } from './../shared/shared.module';

@NgModule({
  declarations: [MessageBoxComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    MessageBoxComponent
  ]
})
export class MessageBoxModule { }
