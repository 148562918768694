import { Component, OnInit, OnDestroy, HostListener, AfterViewInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Carousel } from 'primeng/carousel';
import { TranslateService } from '@ngx-translate/core';

import { SlotService,
  ReservationService,
  ReservationTypeService,
  ReservationStatusService
} from '../../shared/services';
import { Slot } from '../../shared/models/base';
import { AlertService } from 'src/app/alert';
import { CompanyRole } from '../../shared/models/base/companyRole.model';

import { CoreService } from 'src/app/core/core.service';
import { SlotsFiltersComponent } from 'src/app/shared/components/slots-filters/slots-filters.component';

@Component({
  selector: 'app-slots-carousel',
  templateUrl: './slots-carousel.component.html',
  styleUrls: ['./slots-carousel.component.css']
})
export class SlotsCarouselComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('carousel') carousel: QueryList<Carousel>;
  @ViewChild('filters') filters: SlotsFiltersComponent;

  selectedCompanySubscription: Subscription;
  selectedCompany: CompanyRole;

  selectedDate: Date;

  slots: Slot[];

  minimumTimeBetweenRefresh = 60000; // Czas w milisekundach - 1min
  currentPage = 0;
  currentResponseOptions;

  onResizeTimeStamp = 0;
  onResizeScreenWidth = 0;

  constructor(private slotSevice: SlotService,
              private reservationService: ReservationService,
              private reservationTypeService: ReservationTypeService,
              private reservationStatusService: ReservationStatusService,
              public coreService: CoreService,
              private alertService: AlertService,
              private translateService: TranslateService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // Bez tego buguje się wybranie awizacji na mobilnym
    this.carousel.first.changePageOnTouch = (e, diff) => { };
    // Bez tego buguje się scrollowanie w góre/dół na mobilnym
    this.carousel.first.onTouchMove = (e) => { };

    this.selectedCompanySubscription = this.coreService.selectedCompany$.subscribe(company => {
      this.selectedCompany = company;
      this.getTypesAndStatuses();

      this.carousel.changes.pipe(() => {
        this.slots = undefined;
        return new Observable();
      });
    });
  }

  ngOnDestroy(): void {
    this.selectedCompanySubscription.unsubscribe();
    this.slots = undefined;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.timeStamp - this.onResizeTimeStamp > 300 // Jeśli od ostatniego uruchamienia minęło > 300ms
      && event.target.innerWidth !== this.onResizeScreenWidth) { // Tylko jeśli zmieniała się szerokość
      this.onResizeTimeStamp = event.timeStamp;
      this.onResizeScreenWidth = event.target.innerWidth;
      this.getAllSlotsItems();
    }
  }

  onFilterChanged(event) {
    this.selectedDate = event.date;

    this.getSlots(event.locationId, event.warehouseId);
  }

  getTypesAndStatuses() {
    if (!this.selectedCompany) {
      return;
    }

    this.reservationStatusService.getStatuses(this.selectedCompany.company.id)
      .subscribe({
        next: (next) => {
          this.coreService.saveReservationStatuses(next);
        },
        error: (error) => {
          this.alertService.error(error);
        }
      });

    this.reservationTypeService.getTypes(this.selectedCompany.company.id)
      .subscribe({
        next: (next) => {
          this.coreService.saveReservationTypes(next);
        },
        error: (error) => {
          this.alertService.error(error);
        }
      });
  }

  getSlots(locationId: number, warehouseId: number) {
    if (!this.selectedCompany) {
      return;
    }

    this.slotSevice.getSlots(this.selectedCompany.company.id, true, locationId, warehouseId)
      .toPromise()
      .then(s => {
        this.slots = s;
        if (this.slots.length === 0) {
          this.alertService.warning(this.translateService.instant('Info.YouAreNotAssignedToAnySlot'));
        }
        if (this.carousel) {
          this.updateResponsiveOptions();
          if (this.carousel.first) {
            this.carousel.first.ngAfterContentInit();
          }
          this.getAllSlotsItems();
        }
      })
      .catch(error => this.alertService.error(error));
  }

  getAllSlotsItems(forceUpdate: boolean = false) {
    if (!this.slots) {
      return;
    }

    const noVisibleSlots = this.getNumberOfVisibleSlots();
    const currentDateTime: Date = new Date();

    if (forceUpdate) {
      this.slots.forEach(slot => {
        slot.items = null;
      });
    }

    for (let i = this.currentPage; i < this.currentPage + noVisibleSlots; i++) {
      if (!this.slots[i].items ||
        currentDateTime.getTime() - this.slots[i].lastRefreshDateTime?.getTime() > this.minimumTimeBetweenRefresh) {
          this.getSlotItems(this.slots[i].id);
      }
    }
  }

  getSlotItems(slotId: number) {
    this.reservationService.getSlotItems(this.selectedCompany.company.id, slotId, this.selectedDate)
    .subscribe({
      next: (next) => {
        // Jak użytkownik opuści stronę zanim się załaduje to bez tego lecą błędy w konsoli
        if (this.slots) {
          this.slots.find(slot => slot.id === slotId).items = next;
          this.slots.find(slot => slot.id === slotId).lastRefreshDateTime = new Date();
        }
      },
      error: (error) => {
        this.alertService.error(error);
      }
    });
  }

  onSlotChanged(slotId: number) {
    this.getSlotItems(slotId);
    this.filters.updateWarehouseLoad();
  }

  onPageChanged(event) {
    this.currentPage = event.page;
    this.getAllSlotsItems();
  }

  updateResponsiveOptions() {
    if (!this.slots || !this.carousel || !this.carousel.first) {
      return;
    }

    this.carousel.first.numVisible = Math.min(this.slots.length, 4);
    this.currentResponseOptions = [
      {
        breakpoint: '200000px', // Trzeba zrobić duży zakres, bo nie pobiorą się awizacje
        breakpointVal: 200000,
        numVisible: Math.min(this.slots.length, 7),
        numScroll: 1
      },
      {
        breakpoint: '2400px',
        breakpointVal: 2400,
        numVisible: Math.min(this.slots.length, 6),
        numScroll: 1
      },
      {
        breakpoint: '2000px',
        breakpointVal: 2000,
        numVisible: Math.min(this.slots.length, 5),
        numScroll: 1
      },
      {
        breakpoint: '1600px',
        breakpointVal: 1600,
        numVisible: Math.min(this.slots.length, 4),
        numScroll: 1
      },
      {
        breakpoint: '1300px',
        breakpointVal: 1300,
        numVisible: Math.min(this.slots.length, 3),
        numScroll: 1
      },
      {
        breakpoint: '1024px',
        breakpointVal: 1024,
        numVisible: Math.min(this.slots.length, 2),
        numScroll: 1
      },
      {
        breakpoint: '576px',
        breakpointVal: 576,
        numVisible: Math.min(this.slots.length, 1),
        numScroll: 1
      }
    ];
    this.carousel.first.responsiveOptions = this.currentResponseOptions;
  }

  getNumberOfVisibleSlots(): number {
    if (!this.currentResponseOptions) {
      return 0;
    }

    const width = window.innerWidth;

    for (let i = this.currentResponseOptions.length - 1; i >= 0; i--) {
      if (this.currentResponseOptions[i].breakpointVal > width) {
        return this.currentResponseOptions[i].numVisible;
      }
    }
    return this.currentResponseOptions[0].numVisible;
  }
}
