import { IConvertable } from '../../../helper/interfaces';
import { Role, Slot, User } from '.';
import { UserExt } from '../extended';

export class UserRole implements IConvertable<any> {
  public user: User | UserExt;
  public role: Role;
  public slots: Slot[];

  constructor(user: User = null, role: Role = null) {

    if (user != null) {
      this.user = user;
    } else {
      this.user = new User();
    }
    if (role != null) {
      this.role = role;
    } else {
      this.role = new Role();
    }
  }

  convertFrom(input: any): this {
    this.role = new Role().convertFrom(input.role);
    this.user = new User().convertFrom(input);

    return this;
  }
}
