<form class="form-inline m-2 ml-lg-5 mr-lg-5">

    <div class="input-group col-12 col-sm-6 col-lg-3 p-0">
        <label for="calendarInput" translate>Label.SelectedDate</label>
        <div style="width: 100%;">
            <p-calendar id="calendarInput" name="calendarInput" [ngModel]='selectedDate' (ngModelChange)="selectDate($event)"
                [style]="{'min-width': '150px', 'width': '100%' }" [dateFormat]="coreService.dateTimePatternValue" [locale]="calendarConfiguration"
                [inputStyle]="{'min-width': '150px', 'width': '90%' }" [selectionMode]="selectionDateMode">
            </p-calendar>
        </div>
    </div>

    <div class="input-group col-12 col-sm-6 col-lg-3 p-0">
        <label for="selectedLocation" translate>Label.SelectedLocation</label>
        <div style="width: 100%;">
            <p-dropdown [autoDisplayFirst]="false" [options]="locations" [ngModel]='selectedLocation' (ngModelChange)="selectLocation($event)"
                name="locationInput" id="selectedLocation"
                optionLabel="code" [style]="{'min-width':'150px', 'width':'90%'}">
            </p-dropdown>
            <button [hidden]="!selectedLocation" class="btn btn-sm pi pi-times"
                style="background-color:transparent; width:10%" title="{{'Title.RemoveFilter' | translate}}"
                (click)="selectLocation(null);"></button>
        </div>
    </div>

    <div class="input-group col-12 col-sm-6 col-lg-3 p-0">
        <label for="selectedWarehouse" translate>Label.SelectedWarehouse</label>
        <div style="width: 100%;">
            <p-dropdown [autoDisplayFirst]="false" [options]="warehouses" [ngModel]='selectedWarehouse' (ngModelChange)="selectWarehouse($event)"
                name="warehouseInput" id="selectedWarehouse" optionLabel="name"
                [style]="{'min-width':'150px', 'width': '90%'}" [disabled]="selectedLocation == null">
            </p-dropdown>
            <button [hidden]="!selectedWarehouse" class="btn btn-sm pi pi-times"
                style="background-color:transparent; width:10%" title="{{'Title.RemoveFilter' | translate}}"
                (click)="selectWarehouse(null)"></button>
        </div>
    </div>

    <div class="input-group col-12 col-sm-6 col-lg-3 p-0" *ngIf="warehouseLoadVisible">
        <app-warehouse-load #warehouseLoad style="width:100%"></app-warehouse-load>
    </div>
</form>
