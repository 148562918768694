<div>
  <form [formGroup]="editCompanyForm" (ngSubmit)="onEditCompany()" class="m-1 row">

    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mt-4">
      <h5 class="form-title pb-lg-3 pb-sm-1" translate>Label.CompanyData</h5>

      <div class="form-group">
        <label for="name" translate>Label.CompanyName</label>
        <input type="text" formControlName="name" class="form-control" placeholder="{{'Label.CompanyName' | translate}}"
          required [ngClass]="{ 'is-invalid': submitted && form.name.errors }" />

          <app-form-error-message *ngIf="submitted" [control]="form.name"></app-form-error-message>
      </div>

      <div class="form-check ml-3">
        <input class="form-check-input" type="checkbox" formControlName="isActive" id='isActiveCb'
          [ngClass]="{ 'is-invalid': submitted && form.isActive.errors }">
        <label class="form-check-label" for="isActiveCb" translate>
          Label.Active
        </label>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mt-4 p-0">
      <h5 class="form-title pb-lg-3 pb-sm-1" translate>Label.AssignedUsers</h5>

      <p-table [value]="company?.users" dataKey="user.email" editMode="row" [loading]="loading">
        <ng-template pTemplate="header">
          <tr>
            <th translate>Label.FirstNameAndLastName</th>
            <th translate>Label.EmailAddress</th>
            <th translate>Label.Role</th>
            <th translate>Label.Options</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-userRole let-editing="editing">
          <tr [pEditableRow]="userRole">
            <td>
              {{userRole.user.firstName}} {{userRole.user.lastName}}
            </td>
            <td>
              {{userRole.user.email}}
            </td>

            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-dropdown [options]="availableRoles" [(ngModel)]="userRole.role"
                    [ngModelOptions]="{standalone: true}" optionLabel="name"
                    [style]="{'min-width':'0', 'width': '100%'}"></p-dropdown>
                </ng-template>
                <ng-template pTemplate="output">
                  {{userRole.role.name}}
                </ng-template>
              </p-cellEditor>
            </td>

            <td style="text-align:center">
              <button *ngIf="!editing" type="button" title="{{'Title.EditUser' | translate}}" pInitEditableRow
                class="btn btn-sm btn-outline-dark mr-1 pi pi-pencil"></button>

              <button *ngIf="!editing" type="button" title="{{'Title.DeleteUser' | translate}}" class="btn btn-sm btn-outline-dark pi pi-trash"
                (click)="onUserDeleteClicked(userRole)"></button>

              <button *ngIf="editing" type="button" title="{{'Title.ConfirmChanges' | translate}}" pSaveEditableRow class="btn btn-sm btn-outline-dark pi pi-check"
                style="margin-right: .5em"></button>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="summary">
          <div style="text-align:left">
            <button type="button" class="btn btn-sm btn-outline-dark mt-2" (click)="showAddUserDialog()"
              translate>Button.AddUser</button>
          </div>
        </ng-template>
      </p-table>

    </div>

    <div class="form-group mt-4 ml-3">
      <button [disabled]="submiting || loading" type="submit" class="btn btn-outline-dark" translate>
        <span *ngIf="submiting" class="spinner-border spinner-border-sm mr-1"></span>
        Button.SaveChanges
      </button>
    </div>
  </form>
</div>
