import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  upload(companyId: number, slotId: number, reservationId: number, files: File[]) {
    const formData = new FormData();

    Array.from(files).map((file, index) => {
      return formData.append('file' + index, file, file.name);
    });

    return this.http.post(this.baseUrl + '/companies/' + companyId + '/slots/' + slotId +
                          '/reservations/' + reservationId + '/attachments', formData);
  }

  download(companyId: number, slotId: number, reservationId: number, attachmentId: number) {
    return this.http.get(this.baseUrl + '/companies/' + companyId + '/slots/' + slotId +
                          '/reservations/' + reservationId + '/attachments/' + attachmentId, {responseType: 'blob' as 'json'});
  }

  delete(companyId: number, slotId: number, reservationId: number, attachmentId: number) {
    return this.http.delete(this.baseUrl + '/companies/' + companyId + '/slots/' + slotId +
                          '/reservations/' + reservationId + '/attachments/' + attachmentId);
  }
}
