import { IConvertable } from '../../../../helper/interfaces';

export class PlatformLog implements IConvertable<any> {
    id: number;
    dateTime: Date;
    logLevel: string;
    message: string;

    convertFrom(input: any): this {
        Object.assign(this, input);

        this.dateTime = input.dateTime;

        return this;
    }
}
