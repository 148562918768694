import { MinutesToMiliseconds } from '../../../helper/duration-helpers';
import { parseDateTimeToLocalDateTimeString } from '../../../helper/date-time-helpers';
import { IConvertable } from '../../../helper/interfaces';
import { Reservation } from '../base';

export class ReservationCreate implements IConvertable<Reservation> {

    startDateTime: string;
    duration: number;
    unitsCount: number;
    typeId: number;
    statusId: number;
    companyName: string;
    documentNumber: string;
    vehicle: string;
    remarks: string;
    periodicityDays: number;

    convertFrom(input: Reservation): this {

        this.startDateTime = parseDateTimeToLocalDateTimeString(input.startDateTime);
        this.duration = MinutesToMiliseconds(input.durationMinutes);
        this.unitsCount = input.unitsCount;
        this.typeId = input.type.id;
        this.statusId = input.status.id;
        this.companyName = input.companyName;
        this.vehicle = input.vehicle;
        this.remarks = input.remarks;
        this.periodicityDays = input.periodicityDays;
        this.documentNumber = input.documentNumber;

        return this;
    }
}
