import { CompanyRole } from './../base/companyRole.model';
import { IConvertable } from '../../../helper/interfaces';

export class CompanyRoleOutput implements IConvertable<CompanyRole> {

  companyId: number;
  roleId: number;

  convertFrom(input: CompanyRole): this {
    this.companyId = input.company?.id;
    this.roleId = input.role?.id;

    return this;
  }
}
