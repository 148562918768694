import { TranslateService } from '@ngx-translate/core';
import { SlotItem } from '../models/base/slot-item/slot-item.model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { Reservation } from '../models/base';
import { createHttpParams } from '../../helper/http-params-creator-helpers';
import { ReservationCreate } from '../models/output/reservation.create.model';
import { SlotItemService } from './slot-item.service';
import { parseDate } from 'src/app/helper/date-time-helpers';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(private http: HttpClient,
              private translateService: TranslateService,
              private slotItemService: SlotItemService) { }

  baseUrl = environment.baseUrl + '/companies';

  getReservation(companyId: number, slotId: number, reservationId: number): Observable<Reservation> {

    return this.http.get<any[]>(this.baseUrl + '/' + companyId + '/slots/' + slotId + '/reservations/' + reservationId)
      .pipe(map(reservation => new Reservation().convertFrom(reservation)));
  }

  getSlotItems(companyId: number, slotId: number, ddate: Date): Observable<SlotItem[]> {
    const date = parseDate(ddate);
    const params = createHttpParams({ date });

    return this.http.get<any[]>(this.baseUrl + '/' + companyId + '/slots/' + slotId + '/items', { params })
      .pipe(map(items => {
        const convertedItems = items.map(item => new SlotItem().convertFrom(item));
        if (!this.slotItemService.validItems(convertedItems)) {
          throw Error(this.translateService.instant('Info.ItemsWithTheSamePriorityOverlaps'));
        } else {
          return this.slotItemService.handleItems(convertedItems);
        }
      }));
  }

  add(companyId: number, slotId: number, reservation: Reservation): Observable<any> {
    return this.http.post(this.baseUrl + '/' + companyId + '/slots/' + slotId + '/reservations',
      new ReservationCreate().convertFrom(reservation));
  }

  edit(companyId: number, slotId: number, reservationId: number, reservation: Reservation) {
    return this.http.post(this.baseUrl + '/' + companyId + '/slots/' + slotId + '/reservations/' + reservationId,
      new ReservationCreate().convertFrom(reservation));
  }

  delete(companyId: number, slotId: number, reservationId: number) {
    return this.http.delete(this.baseUrl + '/' + companyId + '/slots/' + slotId + '/reservations/' + reservationId);
  }
}
