<div class="page-container">
    <div class="content-wrap">
      <header>
        <app-navbar></app-navbar>
      </header>
      <section class="container-fluid">
        <app-message-box></app-message-box>
        <app-alert [allowManyAlerts]="false" class="col-xl-10 offset-xl-1"></app-alert>
        <router-outlet></router-outlet>
      </section>
    </div>
    <footer>
      <p style="text-align: right; padding-right: 20px;">
        <app-application-version></app-application-version>
      </p>
    </footer>
  </div>