import { IConvertable } from '../../../helper/interfaces';
import { User } from '../base';

export class UserCreate implements IConvertable<User> {
    email: string;
    isAdmin: string;

    convertFrom(input: User): this {
        return Object.assign(this, input);
    }
}
