<div class="mt-2 mb-3" style="width: 80vw; max-width: 500px; height:80vh; max-height:700px">

    <div style="position: fixed; z-index: 1100; left: 46%; top: 45%;">
        <p-progressSpinner [hidden]="isReadyToShow()"></p-progressSpinner>
    </div>
    <form [formGroup]="addReservationForm" class="ml-3 mr-3 mb-2" [ngStyle]="setFormStyle()">
        <app-alert id="add-reservation-alert"></app-alert>

        <div class="form-group">
            <div class="mt-1" style="width: 100%; ">
                <label for="startDateTime" translate>Label.StartDateTime</label> <br>
                <p-calendar #calendar [hideOnDateTimeSelect]="false" [showTime]="true" formControlName="startDateTime"
                    [dateFormat]="coreService.dateTimePatternValue" [style]="{'width': '100%', 'min-width': '100%'}"
                    [inputStyle]="{'width': '100%', 'min-width': '100%'}"
                    [locale] = "calendarConfiguration"
                    [ngClass]="{ 'is-invalid': submitted && form.startDateTime.errors }" required>
                    <p-footer>
                        <button class="btn btn-sm btn-outline-dark" (click)="calendar.overlayVisible = false"
                            translate>Button.SaveDate</button>
                    </p-footer>
                </p-calendar>
                <app-form-error-message *ngIf="submitted" [control]="form.startDateTime"></app-form-error-message>
            </div>

            <div class="mt-1">
                <label for="reservationType" translate>Label.ReservationType</label>
                <p-dropdown (onChange)="reservationTypeChanged()" [autoDisplayFirst]="false" [options]="availableTypes"
                    formControlName="reservationType" optionLabel="name" [style]="{'min-width':'0', 'width': '100%'}"
                    [ngClass]="{ 'is-invalid': submitted && form.reservationType.errors }" required>
                </p-dropdown>
                <app-form-error-message *ngIf="submitted" [control]="form.reservationType"></app-form-error-message>
            </div>

            <div class="mt-1 container row ml-0 mr-0 p-0">
                <div class="col-12 col-sm-6 pl-0">
                    <label for="endDateTime" translate>Label.EndDateTime</label> <br>
                    <p-calendar #calendarEnd [hideOnDateTimeSelect]="false" [showTime]="true"
                        formControlName="endDateTime" [dateFormat]="coreService.dateTimePatternValue"
                        [style]="{'width': '100%', 'min-width': '100%'}"
                        [inputStyle]="{'width': '100%', 'min-width': '100%'}"
                        [ngClass]="{ 'is-invalid': submitted && form.endDateTime.errors }" required>
                        <p-footer>
                            <button class="btn btn-sm btn-outline-dark" (click)="calendarEnd.overlayVisible = false"
                                translate>Button.SaveDate</button>
                        </p-footer>
                    </p-calendar>
                    <app-form-error-message *ngIf="submitted" [control]="form.endDateTime"></app-form-error-message>
                </div>
                <div class="col-12 col-sm-6 p-0">
                    <label for="durationMinutes" translate>Label.DurationMinutes</label>
                    <input type="number" formControlName="durationMinutes" class="form-control"
                        placeholder="{{'Label.DurationMinutes' | translate}}" (change)="durationChanged()"
                        [ngClass]="{ 'is-invalid': submitted && form.durationMinutes.errors }" required />
                    <app-form-error-message *ngIf="submitted" [control]="form.durationMinutes"></app-form-error-message>
                </div>
            </div>

            <div class="mt-1">
                <label for="unitsCount" translate>Label.UnitsCount</label>
                <input type="number" formControlName="unitsCount" class="form-control"
                    placeholder="{{'Label.UnitsCount' | translate}}"
                    [ngClass]="{ 'is-invalid': submitted && form.unitsCount.errors }" />
                <app-form-error-message *ngIf="submitted" [control]="form.unitsCount"></app-form-error-message>
            </div>

            <div class="mt-1">
                <label for="status" translate>Label.Status</label>
                <p-dropdown [autoDisplayFirst]="false" [options]="availableStatuses" formControlName="status"
                    optionLabel="name" [style]="{'min-width':'0', 'width': '100%'}"
                    [ngClass]="{ 'is-invalid': submitted && form.status.errors }" required>
                </p-dropdown>
                <app-form-error-message *ngIf="submitted" [control]="form.status"></app-form-error-message>
            </div>

            <div class="mt-2">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" (change)="isCycleChanged()"
                        formControlName="isCycle" id='isCycle'>
                    <label class="form-check-label" for="isCycle" translate>Label.IsCycle</label>
                </div>
                <ng-container *ngIf="form.isCycle.value" class="mt-2">
                    <label for="periodicityDays" translate>Label.CyclePeriod</label>
                    <input type="number" formControlName="periodicityDays" class="form-control"
                        placeholder="{{'Label.CyclePeriod' | translate}}" min="1" max="30"
                        [ngClass]="{ 'is-invalid': submitted && form.periodicityDays.errors }" />
                    <app-form-error-message *ngIf="submitted" [control]="form.periodicityDays"></app-form-error-message>
                </ng-container>
            </div>

            <div class="mt-1">
                <label for="companyName" translate>Label.ClientName</label>
                <input type="text" formControlName="companyName" class="form-control"
                    placeholder="{{'Label.ClientName' | translate}}"
                    [ngClass]="{ 'is-invalid': submitted && form.companyName.errors }" required />
                <app-form-error-message *ngIf="submitted" [control]="form.companyName"></app-form-error-message>
            </div>

            <div class="mt-1">
                <label for="documentNumber" translate>Label.DocumentNumber</label>
                <input type="text" formControlName="documentNumber" class="form-control"
                    placeholder="{{'Label.DocumentNumber' | translate}}"
                    [ngClass]="{ 'is-invalid': submitted && form.documentNumber.errors }" />
                <app-form-error-message *ngIf="submitted" [control]="form.documentNumber"></app-form-error-message>
            </div>

            <div class="mt-1">
                <label for="vehicle" translate>Label.Vehicle</label>
                <input type="text" formControlName="vehicle" class="form-control"
                    placeholder="{{'Label.Vehicle' | translate}}"
                    [ngClass]="{ 'is-invalid': submitted && form.vehicle.errors }" />
                <app-form-error-message *ngIf="submitted" [control]="form.vehicle"></app-form-error-message>
            </div>

            <div class="mt-1">
                <label for="remarks" translate>Label.Remarks</label>
                <textarea rows="2" type="text" formControlName="remarks" class="form-control"
                    placeholder="{{'Label.Remarks' | translate}}"
                    [ngClass]="{ 'is-invalid': submitted && form.remarks.errors }"></textarea>
            </div>

            <div class="mt-2" [hidden]="!reservation?.attachments">
                <label translate>Label.Attachments</label>
                <ul>
                    <li *ngFor="let attachment of reservation?.attachments" class="row">
                        <label style="width:80%; overflow:hidden;">{{attachment.name}}</label>
                        <div style="width:20%">
                            <button (click)="deleteAttachmentClicked(attachment.id)" [hidden]="!reservation?.canModify"
                                class="btn btn-sm btn-outline-dark pi pi-times mr-1"
                                title="{{'Title.DeleteAttachment' | translate}}"></button>
                            <button (click)="downloadAttachmentClicked(attachment.id)"
                                class="btn btn-sm btn-outline-dark pi pi-download"
                                title="{{'Title.DownloadAttachment' | translate}}"></button>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="mt-1">
                <label for="attachements" translate>Label.AddAttachment</label>
                <div class="custom-file">
                    <input type="file" class="custom-file-input" formControlName="attachements"
                        (change)="attachementChanged($event)" multiple>
                    <label *ngIf="!form.attachements.value" class="custom-file-label" for="attachements"
                        translate>Label.SelectFile</label>
                    <label *ngIf="form.attachements.value" style="white-space: nowrap; overflow: hidden;"
                        class="custom-file-label" for="attachements">{{getAttachmentNames()}}</label>
                </div>
            </div>
        </div>

        <div class="row mt-3 ml-1 mr-1" style="width:100%;">
            <div *ngIf="reservation" style="margin-left: 0px; margin-right: auto;">
                <button [disabled]="!isReadyToShow() || (!reservation?.canModify == config?.data.reservationId)"
                    type="button" class="btn btn-outline-dark" (click)="deleteClicked()"
                    translate>Button.Delete</button>
            </div>

            <div style="margin-left: auto; margin-right: 0px; width: fit-content;">
                <button [disabled]="!isReadyToShow()" type="button" class="btn btn-outline-dark mr-1"
                    (click)="cancelClicked()" translate>Button.Cancel</button>
                <button [disabled]="!isReadyToShow() || (!reservation?.canModify == config?.data.reservationId)"
                    type="button" class="btn btn-outline-dark" (click)="saveClicked()" translate>Button.Save</button>
            </div>
        </div>
    </form>
</div>