import { Company } from '../base';
import { UserRole } from '../base/userRole.model';
import { IConvertable } from '../../../helper/interfaces';

export class CompanyExt extends Company implements IConvertable<any> {

  public users: UserRole[];

  constructor(name: string = null, isActive: boolean = null, users: UserRole[] = null) {
    super();

    this.name = name;
    this.isActive = isActive;
    this.users = users;
  }

  convertFrom(input: any): this {
    Object.assign(this, input);

    if (input.users !== undefined) {
      this.users = input.users.map(user => new UserRole().convertFrom(user));
    }

    return this;
  }
}
