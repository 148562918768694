import { Component, OnInit } from '@angular/core';

import { AlertService } from 'src/app/alert';
import { WarehouseLoad } from '../../models/base';
import { WarehouseService } from '../../services';

@Component({
  selector: 'app-warehouse-load',
  templateUrl: './warehouse-load.component.html',
  styleUrls: ['./warehouse-load.component.css']
})
export class WarehouseLoadComponent implements OnInit {

  warehouseLoad: WarehouseLoad;

  constructor(private warehouseService: WarehouseService,
              private alertService: AlertService) { }

  ngOnInit(): void {
  }

  getWarehouseLoad(companyId: number, date: Date, locationId?: number, warehouseId?: number) {
    this.warehouseService.getWarehouseLoad(companyId, date, locationId, warehouseId)
      .subscribe({
        next: (next) => {
          this.warehouseLoad = next;
        },
        error: (error) => {
          this.alertService.error(error);
        }
      });
  }

}
