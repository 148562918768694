<div class="row">
  <div class="col-xl-10 offset-xl-1 box-shadow jumbotron bg-light">
    <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.ModeratorReservationReport</h2>

    <p-scrollPanel [style]="{width: '100%'}">
      <p-table [value]="reservationHistrory?.reservations" dataKey="id" [lazy]="true" [paginator]="true" [rows]="20"
        [loading]="loading" [style]="{'min-width':'800px', 'min-height': '420px' }">

        <ng-template pTemplate="caption">
          <div style="text-align:left">
            <app-slots-filters #filters [warehouseLoadVisible]="false" selectionDateMode="range" [useQueryParams]="false"
              (filterChanged)="onFilterChanged($event)"></app-slots-filters>

            <button type="button" class="btn btn-sm btn-outline-dark mr-1" (click)="getReservationHistory()" [disabled]="!canGetHistory"
              translate>Button.GetData</button>
            <button type="button" class="btn btn-sm btn-outline-dark" (click)="exportExcel()" [disabled]="!canExport"
              translate>Button.ExportExcel</button>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th translate>Label.Id</th>
            <th translate>Label.StartDateTime</th>
            <th translate>Label.EndDateTime</th>
            <th translate>Label.UnitsCount</th>
            <th translate>Label.ClientName</th>
            <th translate>Label.SlotName</th>
            <th translate>Label.DocumentNumber</th>
            <th translate>Label.ReservationType</th>
            <th translate>Label.ReservationStatusName</th>
            <th translate>Label.ChangedDateTime</th>
            <th translate>Label.ChangedBy</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-record>
          <tr [style]="{'text-decoration': !record.deletedDateTime ? 'none' : 'line-through'}">
            <td>
              {{record.id}}
            </td>
            <td>
              {{record.startDateTime | date:'dd-MM-yyyy HH:mm:ss'}}
            </td>
            <td>
              {{record.endDateTime | date:'dd-MM-yyyy HH:mm:ss'}}
            </td>
            <td>
              {{record.unitsCount}}
            </td>
            <td>
              {{record.companyName}}
            </td>
            <td>
              {{record.slotName}}
            </td>
            <td>
              {{record.documentNumber}}
            </td>
            <td>
              {{record.type}}
            </td>
            <td>
              {{record.statusName}}
            </td>
            <td>
              {{record.changedDateTime | date:'dd-MM-yyyy HH:mm:ss'}}
            </td>
            <td>
              {{record.changedBy}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-scrollPanel>
  </div>
</div>