import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Role, Slot } from '../../../shared/models/base';
import { UserRole } from '../../../shared/models/base/userRole.model';
import { AlertService } from 'src/app/alert';
import { CompanyService } from './../../../shared/services/company.service';
import { CoreService } from './../../../core/core.service';
import { SlotService, UserService } from '../../../shared/services';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  user: UserRole;
  availableRoles: Role[];

  slots: Slot[];
  userSlots: Slot[];

  public loading = false;
  public submitted = false;
  public editUserForm: FormGroup;

  get form() {
    return this.editUserForm.controls;
  }

  constructor(private ref: DynamicDialogRef,
              private formBuilder: FormBuilder,
              private config: DynamicDialogConfig,
              private coreService: CoreService,
              private companyService: CompanyService,
              private translateService: TranslateService,
              private alertService: AlertService,
              private slotservice: SlotService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.user = this.config.data.user;
    this.loading = true;

    this.editUserForm = this.formBuilder.group({
      role: new FormControl('', Validators.required),
      slots: new FormControl('')
    });

    this.userService.getRoles().subscribe(roles => {
      this.availableRoles = roles;
      this.form.role.setValue(roles.find(role => role.id === this.user.role.id));
      this.loading = !this.slots || !this.availableRoles || !this.user;
    });

    this.slotservice.getSlots(this.coreService.selectedCompanyValue.company.id).subscribe(slotsVal => {
      this.slots = slotsVal;

      if (this.userSlots) {
        this.form.slots.setValue(this.slots.filter(s => !!this.userSlots.find(us => us.id === s.id)));
      }
      this.loading = !this.slots || !this.availableRoles || !this.user;
    });

    this.slotservice.getSlots(this.coreService.selectedCompanyValue.company.id, null, null, null, this.config.data.user.user.id)
    .subscribe(slotsVal => {
      this.userSlots = slotsVal;

      if (this.slots) {
      this.form.slots.setValue(this.slots.filter(s => !!slotsVal.find(us => us.id === s.id)));
      }
      this.loading = !this.slots || !this.availableRoles || !this.userSlots;
    });
  }

  cancelClicked() {
    this.ref.close();
  }

  saveClicked() {
    this.submitted = true;

    if (this.editUserForm.invalid) {
      return;
    }
    this.loading = true;

    this.user.role = this.form.role.value;
    this.user.slots = this.form.slots.value;

    this.companyService.editUser(this.coreService.selectedCompanyValue.company.id, this.user).subscribe({
      next: (next) => {
        this.translateService.get('Info.UserEditedSuccessfully', {userName: this.user.user.email })
          .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true, autoClose: true }));
        this.ref.close(this.user);
      },
      error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }
}
