import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { createHttpParams } from '../../helper/http-params-creator-helpers';
import { environment } from 'src/environments/environment';
import { Slot, User } from '../models/base';
import { Observable } from 'rxjs';
import { SlotOutput } from '../models/output/slot.output';

@Injectable({
  providedIn: 'root'
})
export class SlotService {

  baseUrl = environment.baseUrl + '/companies';

  constructor(private http: HttpClient) { }

  getSlots(companyId: number, isAvailable?: boolean,
           locationId?: number, warehouseId?: number, userId?: string): Observable<Slot[]> {
    const params = createHttpParams({ isAvailable, locationId, warehouseId, userId });

    return this.http.get<any[]>(this.baseUrl + '/' + companyId + '/slots', { params })
      .pipe(map(slots => slots.map(slot => new Slot().convertFrom(slot))));
  }

  getSlotUsers(companyId: number, slotId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/' + companyId + '/slots/' + slotId + '/users')
    .pipe(map(val => val.items.map(user => new User().convertFrom(user))));
  }

  add(companyId: number, slot: any) {
    return this.http.post(this.baseUrl + '/' + companyId + '/slots/', new SlotOutput().convertFrom(slot));
  }

  edit(companyId: number, slotId: number, slot: any) {
    return this.http.post(this.baseUrl + '/' + companyId + '/slots/' + slotId, new SlotOutput().convertFrom(slot));
  }

  delete(companyId: number, slotId: number) {
    return this.http.delete(this.baseUrl + '/' + companyId + '/slots/' + slotId);
  }
}
