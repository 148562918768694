import { IConvertable } from '../../../helper/interfaces';
import { Location } from './../base/location.model';

export class LocationsLazy implements IConvertable<any> {

    public locations: Location[];
    public totalItemsCount: number;

    convertFrom(input: any): this {
        Object.assign(this, input);

        if (input.items !== undefined) {
            this.locations = input.items.map(c => new Location().convertFrom(c));
        }

        return this;
    }
}
