<div class="row">
    <div class="col-xl-10 offset-xl-1 box-shadow jumbotron bg-light">
        <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.ModeratorUsersManagement</h2>

        <p-scrollPanel [style]="{width: '100%'}">
            <p-table [value]="users" dataKey="user.email" [lazy]="true" (onLazyLoad)="loadUsersLazy($event)"
                [paginator]="true" [rows]="20" [totalRecords]="totalUsersCount" [loading]="loading"
                [style]="{'min-width':'800px' }">

                <ng-template pTemplate="caption">
                    <div style="text-align:left">
                        <button type="button"
                            class="btn btn-sm btn-outline-dark" (click)="onAddUserClicked()"
                            translate>Button.AddUser</button>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th translate>Label.FirstName</th>
                        <th translate>Label.LastName</th>
                        <th translate>Label.EmailAddress</th>
                        <th translate>Label.Role</th>
                        <th translate>Label.IsActivated</th>
                        <th translate>Label.Options</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-userRole>
                    <tr>
                        <td>
                            {{userRole.user.firstName}}
                        </td>
                        <td>
                            {{userRole.user.lastName}}
                        </td>
                        <td>
                            {{userRole.user.email}}
                        </td>
                        <td>
                            {{userRole.role.name}}
                        </td>
                        <td>
                            <div *ngIf="userRole.user.isConfirmed">
                                {{'Label.Yes' | translate}}
                            </div>
                            <div *ngIf="!userRole.user.isConfirmed">
                                {{'Label.No' | translate}}
                            </div>
                        </td>

                        <td style="text-align:center">
                            <button *ngIf = "!userRole.user.isConfirmed" type="button" class="btn btn-sm btn-outline-dark mr-1 pi pi-envelope"
                                title="{{'Title.ActivateAccount' | translate}}" (click) = "onSendActivationEmail(userRole)"></button>

                            <button type="button" class="btn btn-sm btn-outline-dark mr-1 pi pi-pencil"
                                title="{{'Title.EditUser' | translate}}" (click)="onEditUserClicked(userRole)"></button>

                            <button type="button" class="btn btn-sm btn-outline-dark pi pi-trash"
                                title="{{'Title.DeleteUser' | translate}}" (click)="onDeleteUserClicked(userRole)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-scrollPanel>
    </div>
</div>
