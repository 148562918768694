import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { CoreService } from '../../../core/core.service';
import { Language, Translation } from 'src/app/shared/models/base';
import { ReservationStatus } from '../../../shared/models/base/reservation/reservation-status.model';
import { AlertService } from '../../../alert/alert.service';
import { ReservationStatusService } from '../../../shared/services';

@Component({
  selector: 'app-add-reservation-status',
  templateUrl: './add-reservation-status.component.html',
  styleUrls: ['./add-reservation-status.component.css']
})
export class AddReservationStatusComponent implements OnInit {

  loading = true;
  submitted = false;
  reservationStatusForm: FormGroup;

  supportedLanguages: Language[];

  reservationStatus: ReservationStatus;

  get form() {
    return this.reservationStatusForm.controls;
  }

  constructor(private formBuilder: FormBuilder,
              public config: DynamicDialogConfig,
              private ref: DynamicDialogRef,
              private coreService: CoreService,
              private reservationStatusService: ReservationStatusService,
              private translateService: TranslateService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.reservationStatusForm = this.formBuilder.group({
      color: new FormControl('', Validators.required)
    });

    this.coreService.supportedLanguages$.subscribe({
      next: (next) => {
        this.supportedLanguages = next;
      }
    }).unsubscribe();

    if (this.config.data.reservationStatusId) {
      this.reservationStatusService.getStatus(this.coreService.selectedCompanyValue.company.id, this.config.data.reservationStatusId)
        .subscribe({
          next: (next) => {
            this.reservationStatus = next;

            this.form.color.setValue(this.reservationStatus.color);

            for (const translation of next.displayNames) {
              this.reservationStatusForm.addControl(translation.languageId.toString(),
              new FormControl(translation.displayName, Validators.required));
            }
            this.loading = false;
          },
          error: (error) => {
            this.alertService.error(error);
            this.loading = false;
          }
        });
    } else {
      this.supportedLanguages.forEach(lang => {
        this.reservationStatusForm.addControl(lang.id.toString(), new FormControl('', Validators.required));
      });
      this.loading = false;
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  saveClicked() {
    this.submitted = true;

    if (this.reservationStatusForm.invalid) {
      return;
    }
    this.loading = true;

    const status = new ReservationStatus();
    status.id = this.reservationStatus?.id;
    status.color = this.form.color.value;
    status.displayNames = [];

    for (const language of this.supportedLanguages) {
      status.displayNames
        .push(new Translation().convertFrom({ languageId: language.id, displayName: this.form[language.id].value }));
    }

    const currentLanguageId = JSON.parse(localStorage.getItem('language')).id;
    status.name = status.displayNames.find(name => name.languageId === currentLanguageId).displayName;

    if (this.config.data.reservationStatusId) { // edycja statusu
      this.reservationStatusService.edit(this.coreService.selectedCompanyValue.company.id, status).subscribe({
        next: (next) => {
          this.translateService.get('Info.ReservationStatusEditedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true }));
          this.ref.close(status);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    } else { // dodanie statusu
      this.reservationStatusService.add(this.coreService.selectedCompanyValue.company.id, status).subscribe({
        next: (next: any) => {
          status.id = next.id;
          this.translateService.get('Info.ReservationStatusAddedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true }));
          this.ref.close(status);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    }
  }
}
