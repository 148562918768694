import { BasePopupComponent } from './components/base-popup/base-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DropdownModule } from 'primeng/dropdown';
import { CarouselModule } from 'primeng/carousel';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TreeTableModule } from 'primeng/treetable';

import { HelperModule } from './../helper/helper.module';

import {
  AuthService,
  CompanyService,
  FilesService,
  PlatformService,
  ReservationStatusService,
  ReservationTypeService,
  UserService,
  SlotService,
  ReservationService,
} from './services';

import {
  ManagementPanelComponent,
  WarehouseLoadComponent,
  SlotsFiltersComponent,
  FormErrorMessageComponent
} from './components';

@NgModule({
  declarations: [
    SlotsFiltersComponent,
    WarehouseLoadComponent,
    FormErrorMessageComponent,
    ManagementPanelComponent,
    BasePopupComponent
  ],
  entryComponents: [
  ],
  imports: [
    CommonModule,
    TableModule,
    DropdownModule,
    DialogModule,
    TabMenuModule,
    ButtonModule,
    DynamicDialogModule,
    CalendarModule,
    MultiSelectModule,
    ScrollPanelModule,
    CarouselModule,
    CardModule,
    ProgressSpinnerModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    HelperModule,
    TreeTableModule
  ],
  exports: [
    SlotsFiltersComponent,
    FormErrorMessageComponent,
    ManagementPanelComponent,
    BasePopupComponent,

    TableModule,
    DropdownModule,
    DialogModule,
    TabMenuModule,
    ButtonModule,
    DynamicDialogModule,
    CalendarModule,
    MultiSelectModule,
    ScrollPanelModule,
    CarouselModule,
    CardModule,
    ProgressSpinnerModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    HelperModule,
    TreeTableModule
  ],
  providers: [
    AuthService,
    CompanyService,
    FilesService,
    PlatformService,
    ReservationStatusService,
    ReservationTypeService,
    ReservationService,
    SlotService,
    UserService
  ]
})
export class SharedModule { }
