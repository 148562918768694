import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { SharedModule } from './../shared/shared.module';

import { ResponseResetPasswordComponent,
  RequestResetPasswordComponent,
  ActivateAccountComponent,
  AccountPreviewComponent,
  LoginComponent
 } from './';

@NgModule({
  declarations: [
    AccountPreviewComponent,
    ActivateAccountComponent,
    RequestResetPasswordComponent,
    ResponseResetPasswordComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,

    ScrollPanelModule,
    DropdownModule,
    TableModule
  ],
  exports: [
    AccountPreviewComponent,
    ActivateAccountComponent,
    RequestResetPasswordComponent,
    ResponseResetPasswordComponent,
    LoginComponent
  ]
})
export class UserModule { }
