import { CompanyExt } from '../extended';
import { IConvertable } from '../../../helper/interfaces';
import { UserRoleOutput } from './userRole.output.model';

export class CompanyCreate implements IConvertable<CompanyExt> {

  name: string;
  isActive: boolean;
  users: UserRoleOutput[];

  convertFrom(input: CompanyExt): this {

    this.name = input.name;
    this.isActive = input.isActive;

    this.users = input.users.map(ur => new UserRoleOutput().convertFrom(ur));

    return this;
  }
}
