import { ReservationType } from '../base/reservation/reservation-type.model';
import { IConvertable } from '../../../helper/interfaces/IConvertable';
import { Translation } from '../base';

export class ReservationTypeOutput implements IConvertable<ReservationType> {
    id: number;
    duration: number;
    unitsCount: number;
    displayNames: Translation[];
    isReservationDurationChangeAvailable: boolean;

    convertFrom(input: ReservationType): this {
        this.id = input.id;
        this.duration = input.duration;
        this.unitsCount = input.unitsCount;
        this.isReservationDurationChangeAvailable = input.isReservationDurationChangeAvailable;

        if (input.displayNames) {
            this.displayNames = input.displayNames.map(t => new Translation().convertFrom(t));
        }

        return this;
    }
}
