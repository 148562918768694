import { SlotService } from './../../../shared/services/slot.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { AlertService } from './../../../alert/alert.service';
import { CompanyService } from './../../../shared/services/company.service';
import { UserService } from './../../../shared/services/user.service';
import { UserRole } from './../../../shared/models/base/userRole.model';
import { Language } from './../../../shared/models/base/internal/language.model';
import { CoreService } from 'src/app/core/core.service';
import { Role, Slot, User } from 'src/app/shared/models/base';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  supportedLanguages: Language[];
  availableRoles: Role[];

  slots: Slot[];

  public loading = false;
  public submitted = false;
  public addUserForm: FormGroup;

  get form() {
    return this.addUserForm.controls;
  }

  constructor(private formBuilder: FormBuilder,
              private ref: DynamicDialogRef,
              private coreService: CoreService,
              private translateService: TranslateService,
              private companyService: CompanyService,
              private slotservice: SlotService,
              private alertService: AlertService,
              private userService: UserService) { }

  ngOnInit() {
    this.addUserForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      language: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      slots: new FormControl('')
    });

    this.coreService.supportedLanguages$.subscribe({
      next: (next) => {
        this.supportedLanguages = next;
        this.form.language.setValue(next[0]);
      }
    }).unsubscribe();

    this.userService.getRoles().subscribe(roles => {
      this.availableRoles = roles;
      this.loading = !this.slots || !this.availableRoles;
    });

    this.slotservice.getSlots(this.coreService.selectedCompanyValue.company.id).subscribe(slotsVal => {
      this.slots = slotsVal;
      this.loading = !this.slots || !this.availableRoles;
    });
  }

  cancelClicked() {
    this.ref.close();
  }

  saveClicked() {
    this.submitted = true;

    if (this.addUserForm.invalid) {
      return;
    }
    this.loading = true;

    const u = new UserRole();
    u.role = this.form.role.value;
    u.user = new User();
    u.user.email = this.form.email.value;
    u.user.languageId = this.form.language.value.id;
    u.slots = this.form.slots.value;

    this.companyService.addUser(this.coreService.selectedCompanyValue.company.id, u).subscribe({
      next: (next: any) => {
        this.translateService.get('Info.UserAddedSuccessfully', { userName: u.user.email })
          .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true, autoClose: true }));
        u.user.id = next.id;
        this.ref.close(u);
      },
      error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }
}
