import { Component, OnInit } from '@angular/core';

import { LazyLoadEvent } from 'primeng/api/public_api';

import { PlatformLog, PlatformLogLevel } from '../../shared/models/base';
import { PlatformService } from '../../shared/services';
import { getYesterdayDate, getTodayDate, addDays } from '../../helper/date-time-helpers';
import { AlertService } from 'src/app/alert';

import { CoreService } from 'src/app/core/core.service';
import { LocaleSettings } from 'primeng';

@Component({
  selector: 'app-platform-logs-preview',
  templateUrl: './platform-logs-preview.component.html',
  styleUrls: ['./platform-logs-preview.component.css']
})
export class PlatformLogsPreviewComponent implements OnInit {

  itemsOnPage = 100;

  calendarConfiguration: LocaleSettings = {
    firstDayOfWeek: 1,
    dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    dayNamesShort: ['Nie', 'Pon','Wt','Śr','Czw','Pią','Sob'],
    dayNamesMin: ['Nie', 'Pon','Wt','Śr','Czw','Pią','Sob'],
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecien', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    monthNamesShort: ['STY', 'LUT', 'MAR', 'KWI', 'MAJ', 'CZE', 'LIP', 'SIE', 'WRZ', 'PAŹ', 'LIS', 'GRU'],
    today: 'today',
    clear: 'clear'
  };

  message: string;
  logs: PlatformLog[];
  totalLogsCount: number;
  rangeDates: Date[];
  logLevels: PlatformLogLevel[];
  selectedLogLevels: PlatformLogLevel[];

  loading: boolean;


  constructor(private platformService: PlatformService,
              private alertService: AlertService,
              public coreService: CoreService) { }

  ngOnInit() {

    this.loading = true;

    this.rangeDates = [getYesterdayDate(), getTodayDate()];

    this.platformService.getLogLevels()
    .subscribe({
      next: (next) => {
        this.logLevels = next;
      },
      error: (error) => {
        this.alertService.error(error);
      }
    });
  }

  loadLogsLazy(event: LazyLoadEvent) {
    this.getLogs(event.first, this.itemsOnPage);
  }

  refreshLogs() {
    this.logs = null;
    this.totalLogsCount = 0;
    this.getLogs(0, this.itemsOnPage);
  }

  getLogs(skip: number, take: number) {
    this.loading = true;

    const logLevelValues = !!this.selectedLogLevels ? this.selectedLogLevels.map(level => level.value) : null;

    this.platformService.getPlatformLogs(skip, take, this.rangeDates[0], addDays(this.rangeDates[1], 1), logLevelValues, this.message)
    .subscribe({
      next: (ret) => { this.logs = ret.logs; this.totalLogsCount = ret.totalItemsCount; this.loading = false; },
      error: (error) => { this.alertService.error(error); this.loading = false; },
    });
  }
}
