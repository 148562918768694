<div class="m-1">
  <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.UserManagement</h2>

  <p-scrollPanel [style]="{width: '100%'}">
    <p-table [value]="users" dataKey="user.id" [lazy]="true" (onLazyLoad)="loadUsersLazy($event)" [paginator]="true"
      [rows]="20" [totalRecords]="totalUsersCount" [loading]="loading" [style]="{'min-width':'700px' }">

      <ng-template pTemplate="caption">
        <div class="row ml-1 mr-1">
          <div style="margin-left: 0; margin-right: auto;">
            <button type="button" class="btn btn-sm btn-outline-dark mr-3" (click)="onAddUserClicked()"
              translate>Button.AddUser</button>
          </div>
          <div style="text-align: left; margin-right: 0; margin-left: auto;">
            <label class="mr-2" translate>Label.FilterCompany</label>
            <p-dropdown [autoDisplayFirst]="false" [options]="companies" optionLabel="name"
              (onChange)="onCompanyFilterChanged()" [(ngModel)]="selectedCompany"></p-dropdown>
            <button *ngIf="selectedCompany" class="btn btn-sm pi pi-times ml-1" style="background-color:transparent"
              title="{{'Title.RemoveFilter' | translate}}" (click)="resetCompanyFilter()"></button>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th translate>Label.FirstName</th>
          <th translate>Label.LastName</th>
          <th translate>Label.EmailAddress</th>
          <th translate>Label.UserType</th>
          <th translate>Label.IsActivated</th>
          <th translate>Label.Options</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-user>
        <tr>
          <td>
            {{user?.firstName}}
          </td>
          <td>
            {{user?.lastName}}
          </td>
          <td>
            {{user?.email}}
          </td>
          <td>
            <div *ngIf="user?.isAdmin">
              {{'Label.Administrator' | translate}}
            </div>
            <div *ngIf="!user?.isAdmin">
              {{'Label.User' | translate}}
            </div>
          </td>
          <td>
            <div *ngIf="user?.isConfirmed">
              {{'Label.Yes' | translate}}
            </div>
            <div *ngIf="!user?.isConfirmed">
              {{'Label.No' | translate}}
            </div>
          </td>

          <td style="text-align:center">
            <button *ngIf = "!user.isConfirmed" type="button" class="btn btn-sm btn-outline-dark mr-1 pi pi-envelope"
              title="{{'Title.ActivateAccount' | translate}}" (click) = "onSendActivationEmail(user)"></button>

            <button type="button" class="btn btn-sm btn-outline-dark mr-1 pi pi-pencil"
              title="{{'Title.EditUser' | translate}}" (click)="onEditUserClicked(user)"></button>

            <button type="button" class="btn btn-sm btn-outline-dark pi pi-trash"
              title="{{'Title.DeleteUser' | translate}}" (click)="onDeleteUserClicked(user)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-scrollPanel>
</div>
