<div class="row">
    <div class="col-xl-10 offset-xl-1 box-shadow jumbotron bg-light">
        <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.ModeratorReservationTypesManagement</h2>

        <p-scrollPanel [style]="{width: '100%'}">
            <p-table [value]="reservationTypes" dataKey="id" [lazy]="true" [paginator]="true" [rows]="20"
                [loading]="loading" [style]="{'min-width':'800px' }">

                <ng-template pTemplate="caption">
                    <div style="text-align:left">
                        <button type="button" class="btn btn-sm btn-outline-dark" (click)="onAddTypeClicked()"
                            translate>Button.AddType</button>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th translate>Label.Name</th>
                        <th translate>Label.DurationMinutes</th>
                        <th translate>Label.UnitsCount</th>
                        <th translate>Label.IsReservationDurationChangeAvailable</th>
                        <th translate>Label.Options</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-type>
                    <tr>
                        <td>
                            {{type.name}}
                        </td>
                        <td>
                            {{type.durationMinutes}}
                        </td>
                        <td>
                            {{type.unitsCount}}
                        </td>
                        <td>
                            <div *ngIf="type.isReservationDurationChangeAvailable">
                                {{'Label.Yes' | translate}}
                            </div>
                            <div *ngIf="!type.isReservationDurationChangeAvailable">
                                {{'Label.No' | translate}}
                            </div>
                        </td>

                        <td style="text-align:center">
                            <button type="button" class="btn btn-sm btn-outline-dark mr-1 pi pi-pencil"
                                title="{{'Title.EditType' | translate}}" (click)="onEditTypeClicked(type)"></button>

                            <button type="button" class="btn btn-sm btn-outline-dark pi pi-trash"
                                title="{{'Title.DeleteType' | translate}}" (click)="onDeleteTypeClicked(type)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-scrollPanel>
    </div>
</div>