import { Component, OnInit } from '@angular/core';

import { MessageBoxData } from '../message-box.model';
import { MessageBoxService } from '../message-box.service';

@Component({
  selector: 'app-message-box',
  template: `
<p-dialog *ngIf="visible" header="{{data?.title}}" [(visible)]="visible"
  [focusOnShow]="false" showEffect="fade" [modal]="true">
  <div class="message">
    <p>{{data?.message}}</p>
  </div>
  <p-footer>
    <button (click)="onButtonClicked('no')" class="btn btn-outline-dark">
      {{!data.noButton ? ('Button.No' | translate) : data.noButton}}
    </button>
    <button (click)="onButtonClicked('yes')" class="btn btn-outline-dark">
      {{!data.yesButton ? ('Button.Yes' | translate) : data.yesButton}}
    </button>
  </p-footer>
</p-dialog>
  `,
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {

  visible = false;
  data: MessageBoxData;

  constructor(private messageBoxService: MessageBoxService) { }

  ngOnInit(): void {
    this.messageBoxService.onShow().subscribe((data) => {
      this.data = data;
      this.visible = true;
    });
  }

  onButtonClicked(btnType: string) {
    this.visible = false;
    this.data.result.next(btnType);
  }
}
