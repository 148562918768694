import { IConvertable } from 'src/app/helper/interfaces';
import { Warehouse } from '../base';

export class WarehouseOutput implements IConvertable<Warehouse> {

    name: string;
    isActive: boolean;
    maxUnitsCount: number;

    convertFrom(input: Warehouse): this {
        this.name = input.name;
        this.isActive = input.isActive;
        this.maxUnitsCount = input.maxUnitsCount;

        return this;
    }
}