<div class="ml-3 mr-3 mt-2 mb-3" style="width: 80vw; max-width: 400px;">
  <div style="position: fixed; z-index: 1100; left: 46%; top: 45%;">
    <p-progressSpinner [hidden]="!loading"></p-progressSpinner>
  </div>
  <ng-content></ng-content>

  <div class="row mt-3 ml-1 mr-1" style="width:100%;">
    <div style="margin-left: auto; margin-right: 0px; width: fit-content;">
      <button [disabled]="loading" type="button" class="btn btn-outline-dark mr-1" (click)="onCancelClicked()"
        translate>Button.Cancel</button>
      <button [disabled]="loading" type="button" class="btn btn-outline-dark" (click)="onSaveClicked()"
        translate>Button.Save</button>
    </div>
  </div>
</div>