<app-base-popup (cancelClicked)="cancelClicked()" (saveClicked)="saveClicked()">
  <form [formGroup]="locationForm">
      <div class="form-group">
          <label for="code" translate>Label.Code</label>
          <input formControlName="code" class="form-control"
              placeholder="{{'Label.Code' | translate}}" required
              [ngClass]="{ 'is-invalid': submitted && form.code.errors }" />
          <app-form-error-message *ngIf="submitted" [control]="form.code"></app-form-error-message>
      </div>

      <div class="form-check ml-3">
          <input class="form-check-input" type="checkbox" formControlName="isActive" id='isActiveCb'
              [ngClass]="{ 'is-invalid': submitted && form.isActive.errors }">
          <label class="form-check-label" for="isActiveCb" translate>
              Label.Active
          </label>
      </div>
  </form>
</app-base-popup>