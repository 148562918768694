import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MinutesToDays, MinutesToMiliseconds } from './duration-helpers';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  timeInternalValidator(slotIntervalMinutes: number): ValidatorFn {
    return (formControl: AbstractControl) => {
      const d = new Date(formControl.value);

      if (!slotIntervalMinutes) {
        return null;
      }

      if (d.getTime() % MinutesToMiliseconds(slotIntervalMinutes) === 0) {
        return null;
      } else {
        return { timeIncorrect: true };
      }
    };
  }

  durationLessThanPeriodValidator(periodControlName: string, durationControlName: string) {
    return (formGroup: FormGroup) => {
      const periodControl = formGroup.controls[periodControlName];
      const durationControl = formGroup.controls[durationControlName];


      if (periodControl.value > 0 && periodControl.value < MinutesToDays(durationControl.value)) {
        periodControl.setErrors({ durationLessThanPeriod: true });
        durationControl.setErrors({ durationLessThanPeriod: true });
      } else {
        periodControl.setErrors(null);
        durationControl.setErrors(null);
      }
    };
  }
  mustMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        control.setErrors({ mustMatch: true });
        matchingControl.setErrors({ mustMatch: true });
      } else {
        control.setErrors(null);
        matchingControl.setErrors(null);
      }
    };
  }
}
