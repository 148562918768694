import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { CoreService } from '../../../core/core.service';
import { AlertService } from '../../../alert/alert.service';
import { CompanyService, SlotService, UserService } from './../../../shared/services';
import { User } from 'src/app/shared/models/base';
import { UserRole } from 'src/app/shared/models/base/userRole.model';


@Component({
  selector: 'app-add-slot',
  templateUrl: './add-slot.component.html',
  styleUrls: ['./add-slot.component.css']
})
export class AddSlotComponent implements OnInit {

  loading = true;
  submitted = false;
  slotForm: FormGroup;

  users: User[];

  get form() {
    return this.slotForm.controls;
  }

  constructor(private formBuilder: FormBuilder,
              public config: DynamicDialogConfig,
              private ref: DynamicDialogRef,
              private coreService: CoreService,
              private translateService: TranslateService,
              private alertService: AlertService,
              private slotService: SlotService,
              private companyService: CompanyService) { }

  ngOnInit() {
    this.slotForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      users: new FormControl([]),
      isActive: new FormControl(false)
    });

    this.companyService.getCompanyUsers(this.coreService.selectedCompanyValue.company.id).subscribe({
      next: (next) => {
        this.users = next.users.map(user => user.user);
        this.loading = false;
      },
      error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });

    if (this.config?.data?.slot) {
      this.slotService.getSlotUsers(this.coreService.selectedCompanyValue.company.id, this.config.data.slot.id).subscribe({
        next: (next) => {
          this.slotForm.setValue(
          {
            name: this.config.data.slot.name,
            users: this.users?.filter(user => !!next?.find(x => x.id === user.id)),
            isActive: this.config.data.slot.isActive
          });
          this.loading = !this.users;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  saveClicked() {
    this.submitted = true;

    if (this.slotForm.invalid) {
      return;
    }
    this.loading = true;

    const slot = {
      name: this.form.name.value,
      userIds: this.form.users.value.map(user => user.id),
      isActive: this.form.isActive.value,
      warehouseId: this.config.data.warehouseId
    };

    if (this.config?.data?.slot) {
      this.slotService.edit(this.coreService.selectedCompanyValue.company.id, this.config.data.slot.id, slot)
      .subscribe({
        next: (next: any) => {
          this.translateService.get('Info.SlotEditedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true }));
          this.ref.close({ ...slot, id: this.config.data.slot.id });
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      })
    } else {
      this.slotService.add(this.coreService.selectedCompanyValue.company.id, slot).subscribe({
        next: (next: any) => {
          this.translateService.get('Info.SlotAddedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true }));
          this.ref.close({ ...slot, id: next.id });
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    }
  }
}
