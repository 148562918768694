import { CompanyService, UserService } from '../../../shared/services';
import { Component, OnInit } from '@angular/core';
import { Company, Role } from '../../../shared/models/base';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompanyRole } from '../../../shared/models/base/companyRole.model';

@Component({
  selector: 'app-user-management-assign-company',
  templateUrl: './user-management-assign-company.component.html',
  styleUrls: ['./user-management-assign-company.component.css']
})
export class UserManagementAssignCompanyComponent implements OnInit {

  availableRoles: Role[];
  availableCompanies: Company[];

  public submitted = false;
  public assignCompanyForm: FormGroup;

  get form() {
    return this.assignCompanyForm.controls;
  }

  constructor(private formBuilder: FormBuilder,
              private ref: DynamicDialogRef,
              private companyService: CompanyService,
              private userService: UserService) { }

  ngOnInit() {
    this.companyService.getCompanies().subscribe(comp => this.availableCompanies = comp.companies);
    this.userService.getRoles().subscribe(roles => this.availableRoles = roles);

    this.assignCompanyForm = this.formBuilder.group({
      role: new FormControl('', Validators.required),
      company: new FormControl('', Validators.required),
    });
  }

  cancelClicked() {
    this.ref.close();
  }

  saveClicked() {
    this.submitted = true;

    if (this.assignCompanyForm.invalid) {
      return;
    }

    const cr: CompanyRole = new CompanyRole();
    cr.role = this.form.role.value;
    cr.company = this.form.company.value;

    this.ref.close(cr);
  }
}
