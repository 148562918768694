import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UserRole } from '../../shared/models/base/userRole.model';
import { UsersExtLazy } from '../models/lazy/usersExt.lazy.model';
import { UserExt } from '../models/extended';
import { UserEdit } from '../models/output/user.edit.model';
import { createHttpParams } from '../../helper/http-params-creator-helpers';
import { UserCreate } from '../models/output/user.create.model';
import { CompanyRole } from '../models/base/companyRole.model';
import { Role, User } from '../models/base';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl + '/users';

  resetPassword(email: string) {
    return this.http.post(this.baseUrl + '/reset-password', {
      email,
    });
  }

  sendActivationEmail(user: User) {
      return this.http.post(this.baseUrl + '/' + user.id + '/send-activate-account-notification', {});
  }

  confirmResetPassword(email: string, password: string, token: string) {
    return this.http.post(this.baseUrl + '/confirm-reset-password', {
      email,
      password,
      token
    });
  }

  activateAccount(firstName: string, lastName: string, password: string, languageId: number, confirmationToken: string, userId: string) {
    return this.http.post(this.baseUrl + '/' + userId + '/activate', {
      firstName,
      lastName,
      password,
      languageId,
      confirmationToken
    });
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<any[]>(this.baseUrl + '/roles')
      .pipe(map(roles => roles.map(role => new Role().convertFrom(role))));
  }

  getUsers(skip?: number, take?: number, companyId?: number, slotId?: number): Observable<UsersExtLazy> {
    const params = createHttpParams({
      skip,
      take,
      companyId,
      slotId
    });

    return this.http.get(this.baseUrl, { params })
      .pipe(map(usersExtLazy => new UsersExtLazy().convertFrom(usersExtLazy)));
  }

  getUser(userId: string, companyId: number = null): Observable<UserExt> {
    const params = createHttpParams({
      companyId,
    });
    return this.http.get(this.baseUrl + '/' + userId, { params })
      .pipe(map(user => new UserExt().convertFrom(user)));
  }

  patch(user: any): Observable<UserExt> {
    return this.http.post(this.baseUrl + '/' + user.id, new UserEdit().convertFrom(user))
      .pipe(map(u => new UserExt().convertFrom(u)));
  }

  delete(userId: string) {
    return this.http.delete(this.baseUrl + '/' + userId);
  }

  add(user: User): Observable<User> {
    return this.http.post(this.baseUrl, new UserCreate().convertFrom(user))
      .pipe(map(u => new User().convertFrom(u)));
  }
}
