import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { AlertService } from '../../../alert';
import { CoreService } from 'src/app/core/core.service';
import { Language } from '../../../shared/models/base/internal/language.model';
import { Role } from '../../../shared/models/base/role.model';
import { UserService } from '../../../shared/services/user.service';
import { User } from 'src/app/shared/models/base';
import { UserRole } from '../../../shared/models/base/userRole.model';

@Component({
  templateUrl: './company-add-user-popup.component.html',
  styleUrls: ['./company-add-user-popup.component.css']
})
export class CompanyAddUserPopupComponent implements OnInit {

  availableRoles: Role[];
  supportedLanguages: Language[];

  submitted = false;
  addUserForm: FormGroup;

  get form() {
    return this.addUserForm.controls;
  }

  constructor(private formBuilder: FormBuilder,
              private alertService: AlertService,
              private userService: UserService,
              private ref: DynamicDialogRef,
              private coreService: CoreService) { }

  ngOnInit() {
    this.userService.getRoles().subscribe({
      next: (next) => this.availableRoles = next,
      error: (error) => {
        this.alertService.error(error);
        this.ref.close();
      }
    });

    this.addUserForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required)
    });

    this.coreService.supportedLanguages$.subscribe({
      next: (next) => {
        this.supportedLanguages = next;
        this.form.language.setValue(next[0]);
      }
    }).unsubscribe();
  }

  cancelClicked() {
    this.ref.close();
  }

  saveClicked() {
    this.submitted = true;

    if (this.addUserForm.invalid) {
      return;
    }
    const user = new User();
    user.email = this.form.email.value;
    user.languageId = this.form.language.value.id;

    const u: UserRole = new UserRole(user, this.form.role.value);
    this.ref.close(u);
  }
}
