import { max } from 'src/app/helper/date-time-helpers';
import { Injectable } from '@angular/core';
import { SlotItem } from '../models/base/slot-item/slot-item.model';

@Injectable({
    providedIn: 'root'
})
export class SlotItemService {
    constructor() { }

    isOverlap(itemOne: SlotItem, itemTwo: SlotItem): boolean {
        return itemOne.startDateTime < itemTwo.endDateTime && itemTwo.startDateTime < itemOne.endDateTime;
    }

    sortByDateAndPriority(itemOne: SlotItem, itemTwo: SlotItem): number {
        if (itemOne.startDateTime === itemTwo.startDateTime) {
            return itemOne.priority - itemTwo.priority;
        }
        return itemOne.startDateTime.getTime() - itemTwo.startDateTime.getTime();
    }

    handleItems(items: SlotItem[]): SlotItem[] {

        if (!items.find(item => !item.isChecked)) {
            return items.filter(item => item.startDateTime.getTime() !== item.endDateTime.getTime())
                .sort((x, y) => this.sortByDateAndPriority(x, y));
        }

        items = items.sort((x, y) => this.sortByDateAndPriority(x, y));

        for (const item of items) {
            const overlapsingItems = items.filter(arrItem =>
                this.isOverlap(item, arrItem) &&
                arrItem.priority > item.priority)
                .sort((x, y) => this.sortByDateAndPriority(x, y));

            for (const overlapsingItem of overlapsingItems) {
                if (item.endDateTime > overlapsingItem.startDateTime) {
                    if (item.endDateTime < overlapsingItem.endDateTime) {
                        item.endDateTime = max(item.startDateTime, overlapsingItem.startDateTime);
                    } else if (item.startDateTime >= overlapsingItem.startDateTime) {
                        item.startDateTime = overlapsingItem.endDateTime;
                    } else {
                        const newObj = new SlotItem();
                        Object.assign(newObj, item);
                        newObj.startDateTime = overlapsingItem.endDateTime;
                        items.push(newObj);
                        item.endDateTime = overlapsingItem.startDateTime;
                        return this.handleItems(items.filter(x => x.startDateTime !== x.endDateTime));
                    }
                }
            }
            item.isChecked = true;
        }
        return items.filter(item => item.startDateTime.getTime() !== item.endDateTime.getTime())
            .sort((x, y) => this.sortByDateAndPriority(x, y));
    }

    validItems(items: SlotItem[]): boolean {

        items = items.sort((x, y) => this.sortByDateAndPriority(x, y));

        if (items.some(item => item.endDateTime.getTime() <= item.startDateTime.getTime())) {
            return false;
        }

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            // naprawa buga blokującego podgląd periodycznych zdarzeń nachdodzących na siebie
            if (item.periodicityDays > 0) {
                continue;
            }

            // pobieramy nachodzące elementy o takim samym priorytecie
            if (items.some((arrItem, idx) =>
                this.isOverlap(item, arrItem) && idx !== i && arrItem.priority === item.priority)) {
                return false;
            }
        }
        return true;
    }

}
