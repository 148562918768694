import { Component, OnInit } from '@angular/core';
import { PlatformService } from '../../shared/services';
import { ServerInfo } from '../../shared/models/base';
import { version } from '../../../../package.json';

@Component({
  selector: 'app-application-version',
  templateUrl: './application-version.component.html',
  styleUrls: ['./application-version.component.css']
})
export class ApplicationVersionComponent implements OnInit {

  serverVersion: ServerInfo;
  clientVersion: string = version;

  constructor(private platformService: PlatformService) { }

  ngOnInit(): void {
    this.platformService.getInfo().subscribe({
      next: (next) => {
        this.serverVersion = next;
      }
    });
  }
}
