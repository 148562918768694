import { Subject } from 'rxjs';

export class MessageBoxData {
    title: string;
    message: string;

    yesButton: string;
    noButton: string;

    result = new Subject<string>();
}
