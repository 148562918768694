import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReservationType } from '../models/base';
import { environment } from 'src/environments/environment';
import { ReservationTypeOutput } from '../models/output/reservation-type.output.model';

@Injectable({
  providedIn: 'root'
})
export class ReservationTypeService {

  baseUrl = environment.baseUrl + '/companies';

  constructor(private http: HttpClient) { }

  getTypes(companyId: number): Observable<ReservationType[]> {
    return this.http.get<any[]>(this.baseUrl + '/' + companyId + '/reservation-types')
      .pipe(map(types => types.map(type => new ReservationType().convertFrom(type))));
  }

  getType(companyId: number, reservationTypeId: number): Observable<ReservationType> {
    return this.http.get<any[]>(this.baseUrl + '/' + companyId + '/reservation-types/' + reservationTypeId)
      .pipe(map(type => new ReservationType().convertFrom(type)));
  }

  add(companyId: number, reservationType: ReservationType) {
    return this.http.post(this.baseUrl + '/' + companyId + '/reservation-types',
                          new ReservationTypeOutput().convertFrom(reservationType));
  }

  edit(companyId: number, reservationType: ReservationType) {
    return this.http.post(this.baseUrl + '/' + companyId + '/reservation-types/' + reservationType.id,
                          new ReservationTypeOutput().convertFrom(reservationType));
  }

  delete(companyId: number, reservationTypeId: number) {
    return this.http.delete(this.baseUrl + '/' + companyId + '/reservation-types/' + reservationTypeId);
  }
}
