import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LazyLoadEvent } from 'primeng/api/public_api';
import { DialogService } from 'primeng/dynamicdialog';

import { CompanyService } from '../../shared/services';
import { UserRole } from '../../shared/models/base/userRole.model';
import { CompanyRole } from '../../shared/models/base/companyRole.model';
import { AlertService } from 'src/app/alert';
import { MessageBoxService, MessageBoxData } from 'src/app/message-box';

import { CoreService } from 'src/app/core/core.service';
import { EditUserComponent, AddUserComponent } from './../_popups';

@Component({
  selector: 'app-moderator-users-management',
  templateUrl: './moderator-users-management.component.html',
  styleUrls: ['./moderator-users-management.component.css'],
  providers: [DialogService]
})
export class ModeratorUsersManagementComponent implements OnInit, OnDestroy {

  users: UserRole[];
  totalUsersCount: number;

  loading: boolean;

  selectedCompany: CompanyRole;
  selectedCompanySub: Subscription;

  constructor(
              private companyService: CompanyService,
              private alertService: AlertService,
              private coreService: CoreService,
              private messageBoxService: MessageBoxService,
              private translateService: TranslateService,
              private dialogService: DialogService) { }

  ngOnInit() {
    this.selectedCompanySub = this.coreService.selectedCompany$.subscribe(comp => {
      this.selectedCompany = comp;
      if (this.selectedCompany) {
        this.getUsers(0, 20);
      }
    });
  }

  ngOnDestroy() {
    this.selectedCompanySub.unsubscribe();
  }

  onAddUserClicked() {
    if (this.selectedCompany.company.isActive){
      const addUserDialog = this.dialogService.open(AddUserComponent, {
      header: this.translateService.instant('Dialog.AddUser'),
      });

      addUserDialog.onClose.subscribe((user: UserRole) => {
        if (user) {
          this.users.push(user);
          this.totalUsersCount += 1;
        }
      });
    }
    else {
      this.alertService.warning("Firma nie jest aktywna, dodawanie użytkowników jest niemożliwe.");
    }
  }
  onSendActivationEmail(userRole: UserRole) {
    const data = new MessageBoxData();
    data.title = this.translateService.instant('Dialog.EmailActivation');
    data.message = this.translateService.instant('Dialog.EmailActivationMessage', {userEmail: userRole.user.email});
    data.result.subscribe((result) => {
      if (result === 'yes'){
        this.sendActivationEmail(userRole);
      }
      data.result.unsubscribe;
    })
    this.messageBoxService.show(data);
}

sendActivationEmail(userRole: UserRole) {
  this.companyService.sendActivationEmail(userRole, this.selectedCompany)
  .subscribe({
    next: () => {
      this.translateService.get('Info.ActivationEmailSent')
      .subscribe(msg => this.alertService.success(msg, {autoClose: true}));
      this.loading = false;
    },
    error: (error) => {
      this.alertService.error(error);
      this.loading = false;
    }
  });
}

  onEditUserClicked(user: UserRole) {
    const editUserDialog = this.dialogService.open(EditUserComponent, {
      header: this.translateService.instant('Dialog.EditUser'),
      data: { user }
    });

    editUserDialog.onClose.subscribe((editedUser: UserRole) => {
      if (editedUser) {
        const idx = this.users.indexOf(user);
        this.users[idx] = editedUser;
      }
    });
  }

  onDeleteUserClicked(user: UserRole) {
    const data = new MessageBoxData();
    data.title = this.translateService.instant('Dialog.ConfirmDeletingUser');
    data.message = this.translateService.instant('Dialog.DeletingUser', { userName: user.user.email });
    data.result.subscribe((result) => {
      if (result === 'yes') {
        this.deleteUser(user.user.id);
      }
      data.result.unsubscribe();
    });
    this.messageBoxService.show(data);
  }

  loadUsersLazy(event: LazyLoadEvent) {
      this.getUsers(event.first, event.rows);
  }

  deleteUser(userId: string) {
    this.companyService.deleteUser(this.selectedCompany.company.id, userId)
      .subscribe({
        next: () => {
          this.translateService.get('Info.SuccessfullyDeletedUser')
            .subscribe(msg => this.alertService.success(msg, { autoClose: true }));
          this.users = this.users.filter(u => u.user.id !== userId);
          this.loading = false;
          this.totalUsersCount -= 1;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  getUsers(skip: number, take: number) {
    if (!this.selectedCompany) {
      setTimeout(_ => this.getUsers(skip, take), 100);
      return;
    }

    this.loading = true;

    if (!this.coreService.userValue.isAdmin && this.selectedCompany.role && !this.selectedCompany.role.isModerator) {
      this.alertService.warning(this.translateService.instant('Info.YouHaveToBeAModerator'));
      this.users = null;
      this.totalUsersCount = 0;
    } else {
      this.alertService.clear();
      this.companyService.getCompanyUsers(this.selectedCompany.company.id, skip, take).subscribe({
        next: (next) => {
          this.users = next.users;
          this.totalUsersCount = next.totalItemsCount;
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    }
  }
}
