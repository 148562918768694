<div class="row">
    <div class="col-xl-10 offset-xl-1 box-shadow jumbotron bg-light">
        <div class="m-1">
            <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.ActionLogsPreview</h2>
            <p-scrollPanel [style]="{width: '100%'}">
                <p-table [value]="logs" dataKey="id" [lazy]="true" (onLazyLoad)="loadLogsLazy($event)"
                    [style]="{'min-width':'750px','min-height':'600px' }" [paginator]="true" [rows]="itemsOnPage"
                    [totalRecords]="totalLogsCount" [loading]="loading">

                    <ng-template pTemplate="caption">
                        <div class="row">
                            <div style="margin-left: 10px; margin-right: auto;">
                                <button class="btn btn-sm btn-outline-dark" (click)="refreshLogs()"
                                    translate>Button.RefreshLogs</button>
                            </div>

                            <div style="margin-left: auto; margin-right: 10px; width:100%; max-width: 400px;">
                                <input type="text" class="form-control" placeholder="{{'Label.Parameters' | translate}}"
                                    [(ngModel)]="requestParameters" (keyup.enter)="refreshLogs()">
                            </div>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 40px"></th>
                            <th style="width:150px;" translate>Label.DateTime</th>
                            <th translate>Label.User</th>
                            <th translate>Label.Url</th>
                            <th translate>Label.HttpMethod</th>
                            <th translate>Label.ResponseCode</th>
                            <th translate>Label.Duration</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                <p-calendar [dateFormat]="coreService.dateTimePatternValue"
                                    [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true"
                                    (onClose)="refreshLogs()" [style]="{'width':'100%'}"
                                    [locale] = "calendarConfiguration"
                                    [inputStyle]="{'width':'100%'}"></p-calendar>
                            </th>
                            <th>
                                <p-multiSelect [options]="users" [(ngModel)]="selectedUsers" [filter]="false"
                                    optionLabel="email" [style]="{'width': '100%'}"
                                    defaultLabel="{{'Label.AllUsers' | translate}}" (onPanelHide)="refreshLogs()">
                                </p-multiSelect>
                            </th>
                            <th>
                                <input type="text" class="form-control" placeholder="{{'Label.Url' | translate}}"
                                    [(ngModel)]="url" (keyup.enter)="refreshLogs()">
                            </th>
                            <th>
                                <p-multiSelect [options]="httpMethods" [(ngModel)]="selectedHttpMethods"
                                    [filter]="false" [style]="{'width': '100%'}"
                                    defaultLabel="{{'Label.AllHttpMethods' | translate}}" (onPanelHide)="refreshLogs()">
                                </p-multiSelect>
                            </th>
                            <th>
                                <input type="text" class="form-control"
                                    placeholder="{{'Label.AllHttpCodes' | translate}}" [(ngModel)]="responseCodes"
                                    (keyup.enter)="refreshLogs()">
                            </th>
                            <th>
                                <input type="numeric" class="form-control"
                                    placeholder="{{'Label.DurationLongerThan' | translate}}"
                                    [(ngModel)]="minimalDuration" (keyup.enter)="refreshLogs()">
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-expanded="expanded" let-log>
                        <tr>
                            <td>
                                <a href="#" [pRowToggler]="log">
                                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                                </a>
                            </td>
                            <td>
                                {{log.dateTime | date:'dd-MM-yyyy HH:mm:ss'}} 
                            </td>
                            <td>
                                {{log.user?.email}}
                            </td>
                            <td>
                                {{log.url}}
                            </td>
                            <td>
                                {{log.httpMethod}}
                            </td>
                            <td>
                                {{log.responseStatusCode}}
                            </td>
                            <td>
                                {{log.duration}}
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="rowexpansion" let-log>
                        <tr>
                            <td [attr.colspan]="7">
                                <div class="container-fluid" style="font-size:14px">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-lg-5">
                                            <div class="row">
                                                <b>{{'Label.LogId' | translate}}:</b>&nbsp; {{log.id}}
                                            </div>
                                            <div class="row">
                                                <b>{{'Label.Action' | translate}}: </b>&nbsp; {{log.controllerName}} /
                                                {{log.actionName}}
                                            </div>
                                            <div class="row">
                                                <b>{{'Label.User' | translate}}: </b>&nbsp; {{log.user?.firstName}}
                                                {{log.user?.lastName}} ({{log.user?.id}})
                                            </div>
                                            <br>
                                            <div class="row">
                                                <b>{{'Label.Parameters' | translate}}: </b>
                                            </div>
                                            <p-scrollPanel [style]="{width: '100%', height: '200px'}">
                                                <pre>{{log.requestParametersObj | json}}</pre>
                                            </p-scrollPanel>
                                        </div>  
                                        <div class="col-12 col-sm-12 col-lg-7">

                                            <div class="row">
                                                <b>{{'Label.ResponseValue' | translate}}: </b>
                                            </div>
                                            <p-scrollPanel [style]="{width: '100%', height: '300px'}">
                                                <div *ngIf="log.isResponseValueJson">
                                                    <pre>{{log.responseValueObj | json}}</pre>
                                                </div>
                                                <div *ngIf="!log.isResponseValueJson" class="breakLines">
                                                    {{log.responseValueObj }}
                                                </div>
                                            </p-scrollPanel>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-scrollPanel>
        </div>
    </div>
</div>