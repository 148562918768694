import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { CoreService } from '../../../core/core.service';
import { AlertService } from '../../../alert/alert.service';
import { Location } from '../../../shared/models/base/location.model';
import { WarehouseService } from './../../../shared/services';
import { Warehouse } from 'src/app/shared/models/base';

@Component({
  selector: 'app-add-warehouse',
  templateUrl: './add-warehouse.component.html',
  styleUrls: ['./add-warehouse.component.css']
})
export class AddWarehouseComponent implements OnInit {

  loading = true;
  submitted = false;
  warehouseForm: FormGroup;

  get form() {
    return this.warehouseForm.controls;
  }

  constructor(private formBuilder: FormBuilder,
              public config: DynamicDialogConfig,
              private ref: DynamicDialogRef,
              private coreService: CoreService,
              private translateService: TranslateService,
              private alertService: AlertService,
              private warehouseService: WarehouseService) { }

  ngOnInit() {
    this.warehouseForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      maxUnitsCount: new FormControl('', [Validators.required]),
      isActive: new FormControl(false)
    });

    if (this.config?.data?.warehouse) {
      this.warehouseForm.setValue({
        name: this.config.data.warehouse.name,
        maxUnitsCount: this.config.data.warehouse.maxUnitsCount,
        isActive: this.config.data.warehouse.isActive
      });
    }
    this.loading = false;
  }

  cancelClicked() {
    this.ref.close();
  }

  saveClicked() {
    this.submitted = true;

    if (this.warehouseForm.invalid) {
      return;
    }
    this.loading = true;

    const warehouse = new Warehouse();
    warehouse.name = this.form.name.value;
    warehouse.maxUnitsCount = this.form.maxUnitsCount.value;
    warehouse.isActive = this.form.isActive.value;

    if (this.config?.data?.warehouse) {
      this.warehouseService.edit(this.coreService.selectedCompanyValue.company.id, this.config.data.locationId, this.config.data.warehouse.id, warehouse)
      .subscribe({
        next: (next: any) => {
          this.translateService.get('Info.WarehouseEditedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true }));
          warehouse.id = next.id;
          this.ref.close(warehouse);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    } else {
      this.warehouseService.add(this.coreService.selectedCompanyValue.company.id, this.config.data.locationId, warehouse).subscribe({
        next: (next: any) => {
          this.translateService.get('Info.WarehouseAddedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true }));
          warehouse.id = next.id;
          this.ref.close(warehouse);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    }
  }
}
