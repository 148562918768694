import { IConvertable } from '../../../../helper/interfaces';

export class PlatformLogLevel implements IConvertable<any> {

    value: number;
    name: string;

    convertFrom(input: any): this {
        return Object.assign(input);
    }
}
