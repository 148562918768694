import { createHttpParams } from 'src/app/helper/http-params-creator-helpers';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WarehousesLazy } from '../models/lazy';
import { Warehouse, WarehouseLoad } from '../models/base';
import { parseDate } from 'src/app/helper/date-time-helpers';
import { map } from 'rxjs/operators';
import { WarehouseOutput } from '../models/output/warehouse.output.model';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl + '/companies';

  getWarehouses(companyId: number, locationId: number, isAvailable?: boolean, skip?: number, take?: number): Observable<WarehousesLazy> {
    const params = createHttpParams({ skip, take, isAvailable, locationId });

    return this.http.get(this.baseUrl + '/' + companyId + '/warehouses', { params })
      .pipe(map(warehousesLazy => new WarehousesLazy().convertFrom(warehousesLazy)));
  }

  getWarehouseLoad(companyId: number, ddate: Date, locationId?: number, warehouseId?: number): Observable<WarehouseLoad> {
    const date = parseDate(ddate);
    const params = createHttpParams({ locationId, warehouseId, date });

    return this.http.get(this.baseUrl + '/' + companyId + '/warehouse-load', { params })
      .pipe(map(warehouseLoad => new WarehouseLoad().convertFrom(warehouseLoad)));
  }

  add(comapnyId: number, locationId: number, warehouse: Warehouse) {
    return this.http.post(this.baseUrl + '/' + comapnyId + '/locations/' + locationId + '/warehouses',
    new WarehouseOutput().convertFrom(warehouse));
  }

  edit(comapnyId: number, locationId: number, warehouseId: number, warehouse: Warehouse) {
    return this.http.post(this.baseUrl + '/' + comapnyId + '/locations/' + locationId + '/warehouses/' + warehouseId,
    new WarehouseOutput().convertFrom(warehouse));
  }

  delete(comapnyId: number, locationId: number, warehouseId: number) {
    return this.http.delete(this.baseUrl + '/' + comapnyId + '/locations/' + locationId + '/warehouses/' + warehouseId);
  }
}
