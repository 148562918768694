import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DialogService } from 'primeng/dynamicdialog';

import { Role } from '../../../shared/models/base';
import { CompanyService, UserService, AuthService } from '../../../shared/services';
import { UserRole } from '../../../shared/models/base/userRole.model';
import { CompanyExt } from '../../../shared/models/extended';
import { AlertService } from 'src/app/alert';
import { CompanyAddUserPopupComponent } from '../company-management-add-user-popup/company-add-user-popup.component';

@Component({
  selector: 'app-company-management-add',
  templateUrl: './company-management-add.component.html',
  styleUrls: ['./company-management-add.component.css'],
  providers: [DialogService]
})
export class CompanyManagementAddComponent implements OnInit {

  users: Array<UserRole>;
  availableRoles: Role[];

  addCompanyForm: FormGroup;

  loading = false;
  submitted = false;

  get form() {
    return this.addCompanyForm.controls;
  }

  constructor(private alertService: AlertService,
              private formBuilder: FormBuilder,
              private companyService: CompanyService,
              private userService: UserService,
              private translateService: TranslateService,
              private router: Router,
              private authService: AuthService,
              private route: ActivatedRoute,
              private dialogService: DialogService) { }

  ngOnInit() {
    this.users = new Array<UserRole>();

    this.addCompanyForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
    });

    this.userService.getRoles().subscribe({
      next: (next) => this.availableRoles = next,
      error: (error) => this.alertService.error(error)
    });
  }

  onAddCompany() {
    this.submitted = true;
    this.alertService.clear();

    if (this.addCompanyForm.invalid) {
      return;
    }

    this.loading = true;

    this.companyService.addCompany(new CompanyExt(this.form.name.value, true, this.users))
      .subscribe({
        next: () => {
          // Odświeżamy listę firm w navbarze
          this.authService.getLogged().subscribe({
            error: (error) => {
              this.alertService.error(error);
              this.loading = false;
            }
          });
          this.translateService.get('Info.CompanyAddedSuccessfully')
          .subscribe(msg => this.alertService.success(msg, {keepAfterRouteChange: true, autoClose: true}));
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  onRowDeleteInit(user: UserRole) {
    this.users = this.users.filter(u => u.user.email !== user.user.email);
  }

  showAddUserDialog() {
    const addUserDialog = this.dialogService.open(CompanyAddUserPopupComponent, {
      header: this.translateService.instant('Dialog.AddUser'),
    });

    addUserDialog.onClose.subscribe((userRole: UserRole) => {
      if (userRole) {
        this.addUser(userRole);
      }
    });
  }

  addUser(newUser: UserRole) {
    if (newUser != null && newUser !== undefined) {
      if (this.users.find(u => u.user.email === newUser.user.email)) {
        this.alertService.warning(this.translateService.instant('Info.UserAlreadyExistsOnList'));
      } else {
        this.users.push(newUser);
      }
    }
  }
}
