<div class="row m-2">
  <div class="jumbotron bg-light col-sm-10 col-lg-6 col-xl-4 offset-sm-1 offset-lg-3 offset-xl-4">

    <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.Login</h2>

    <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
      <div class="form-group">  
        <label for="email" translate>Label.EmailAddress</label>
        <input type="email" formControlName="email" class="form-control"
          placeholder="{{'Label.EmailAddress' | translate}}" required
          [ngClass]="{ 'is-invalid': submitted && form.email.errors }" autofocus/>

          <app-form-error-message *ngIf="submitted" [control]="form.email"></app-form-error-message>
      </div>

      <div class="form-group">
        <label for="password" translate>Label.Password</label>
        <input type="password" formControlName="password" class="form-control"
          placeholder="{{'Label.Password' | translate}}" required
          [ngClass]="{ 'is-invalid': submitted && form.password.errors }" />

          <app-form-error-message *ngIf="submitted" [control]="form.password"></app-form-error-message>
      </div>

      <div class="form-group">
        <button [disabled]="loading" type="submit" class="btn btn-outline-dark" translate>
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Button.Login
        </button>
      </div>
    </form>
  </div>
</div>
<div class="col-md-6 offset-md-3 form-group mt-1">
   <a routerLink="/request-reset-password" class="col-12 btn text-muted">{{'Label.ForgetPassword' | translate}}</a>
</div>
