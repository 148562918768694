import { IConvertable } from '../../../helper/interfaces';
import { UserRole } from '../base/userRole.model';

export class UserRolesLazy implements IConvertable<any> {

    public users: UserRole[];
    public totalItemsCount: number;

    convertFrom(input: any): this {
        if (input.items !== undefined) {
            this.users = input.items.map(c => new UserRole().convertFrom(c));
        }
        this.totalItemsCount = input.totalItemsCount;

        return this;
    }
}
