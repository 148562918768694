import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { CoreService } from '../../../core/core.service';
import { Language, Translation } from 'src/app/shared/models/base';
import { ReservationType } from '../../../shared/models/base/reservation/reservation-type.model';
import { AlertService } from '../../../alert/alert.service';
import { ReservationTypeService } from '../../../shared/services';
import { MinutesToMiliseconds } from 'src/app/helper/duration-helpers';

@Component({
  selector: 'app-add-reservation-type',
  templateUrl: './add-reservation-type.component.html',
  styleUrls: ['./add-reservation-type.component.css']
})
export class AddReservationTypeComponent implements OnInit {

  loading = true;
  submitted = false;
  reservationTypeForm: FormGroup;

  supportedLanguages: Language[];

  reservationType: ReservationType;

  get form() {
    return this.reservationTypeForm.controls;
  }

  constructor(private formBuilder: FormBuilder,
              public config: DynamicDialogConfig,
              private ref: DynamicDialogRef,
              private coreService: CoreService,
              private reservationTypeService: ReservationTypeService,
              private translateService: TranslateService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.reservationTypeForm = this.formBuilder.group({
      durationMinutes: new FormControl('', [Validators.required, Validators.min(0)]),
      unitsCount: new FormControl('', [Validators.required, Validators.min(0)]),
      isReservationDurationChangeAvailable: new FormControl(false, )
    });

    this.coreService.supportedLanguages$.subscribe({
      next: (next) => {
        this.supportedLanguages = next;
      }
    }).unsubscribe();

    if (this.config.data.reservationTypeId) {
      this.reservationTypeService.getType(this.coreService.selectedCompanyValue.company.id, this.config.data.reservationTypeId)
        .subscribe({
          next: (next) => {
            this.reservationType = next;

            this.form.durationMinutes.setValue(this.reservationType.durationMinutes);
            this.form.unitsCount.setValue(this.reservationType.unitsCount);
            this.form.isReservationDurationChangeAvailable.setValue(this.reservationType.isReservationDurationChangeAvailable);

            for (const translation of next.displayNames) {
              this.reservationTypeForm.addControl(translation.languageId.toString(),
              new FormControl(translation.displayName, Validators.required));
            }
            this.loading = false;
          },
          error: (error) => {
            this.alertService.error(error);
            this.loading = false;
          }
        });
    } else {
      this.supportedLanguages.forEach(lang => {
        this.reservationTypeForm.addControl(lang.id.toString(), new FormControl('', Validators.required));
      });
      this.loading = false;
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  saveClicked() {
    this.submitted = true;

    if (this.reservationTypeForm.invalid) {
      return;
    }
    this.loading = true;

    const reservation = new ReservationType();
    reservation.id = this.reservationType?.id;
    reservation.duration = MinutesToMiliseconds(this.form.durationMinutes.value);
    reservation.unitsCount = this.form.unitsCount.value;
    reservation.isReservationDurationChangeAvailable = this.form.isReservationDurationChangeAvailable.value;
    reservation.displayNames = [];

    for (const language of this.supportedLanguages) {
      reservation.displayNames
        .push(new Translation().convertFrom({ languageId: language.id, displayName: this.form[language.id].value }));
    }

    const currentLanguageId = JSON.parse(localStorage.getItem('language')).id;
    reservation.name = reservation.displayNames.find(name => name.languageId === currentLanguageId).displayName;

    if (this.config.data.reservationTypeId) { // edycja typu
      this.reservationTypeService.edit(this.coreService.selectedCompanyValue.company.id, reservation).subscribe({
        next: (next) => {
          this.translateService.get('Info.ReservationTypeEditedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true }));
          this.ref.close(reservation);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    } else { // dodanie typu
      this.reservationTypeService.add(this.coreService.selectedCompanyValue.company.id, reservation).subscribe({
        next: (next: any) => {
          reservation.id = next.id;
          this.translateService.get('Info.ReservationTypeAddedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true }));
          this.ref.close(reservation);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    }
  }
}
