import { IConvertable } from '../../../helper/interfaces';

export class WarehouseLoad implements IConvertable<any> {

    unitsCount: number;
    maxUnitsCount: number;

    convertFrom(input: any): this {
        this.maxUnitsCount = input.maxUnitsCount;
        this.unitsCount = input.unitsCount;

        return this;
    }
}
