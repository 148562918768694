import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { AuthService } from '../../shared/services';
import { AlertService } from 'src/app/alert';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  loading = false;
  submitted = false;

  returnUrl: string;

  get form() {
    return this.loginForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  onLogin() {

    this.submitted = true;
    this.alertService.clear();

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authService.login(this.form.email.value, this.form.password.value)
      .pipe(concatMap(() => this.authService.getLogged()))
      .subscribe({
        next: () => this.router.navigateByUrl(this.returnUrl),
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }
}
