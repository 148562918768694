import { IConvertable } from '../../../../helper/interfaces';

export class Attachment implements IConvertable<any> {

    id: number;
    name: string;

    convertFrom(input: any): this {
        this.id = input.id;
        this.name = input.name;

        return this;
    }
}
