import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { CoreService } from '../../../core/core.service';
import { AlertService } from '../../../alert/alert.service';
import { Location } from '../../../shared/models/base/location.model';
import { LocationService } from './../../../shared/services/location.service';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.css']
})
export class AddLocationComponent implements OnInit {

  loading = true;
  submitted = false;
  locationForm: FormGroup;

  get form() {
    return this.locationForm.controls;
  }

  constructor(private formBuilder: FormBuilder,
              public config: DynamicDialogConfig,
              private ref: DynamicDialogRef,
              private coreService: CoreService,
              private translateService: TranslateService,
              private alertService: AlertService,
              private locationService: LocationService) { }

  ngOnInit() {
    this.locationForm = this.formBuilder.group({
      code: new FormControl('', [Validators.required]),
      isActive: new FormControl(false)
    });

    if (this.config?.data?.location) {
      this.locationForm.setValue({code: this.config.data.location.code, isActive: this.config.data.location.isActive});
    }
    this.loading = false;
  }

  cancelClicked() {
    this.ref.close();
  }

  saveClicked() {
    this.submitted = true;

    if (this.locationForm.invalid) {
      return;
    }
    this.loading = true;

    const location = new Location();
    location.code = this.form.code.value;
    location.isActive = this.form.isActive.value;

    if (this.config?.data?.location) {
      this.locationService.edit(this.coreService.selectedCompanyValue.company.id, this.config.data.location.id, location).subscribe({
        next: (next: any) => {
          this.translateService.get('Info.LocationEditedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true }));
          location.id = this.config.data.location.id;
          this.ref.close(location);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    } else {
      this.locationService.add(this.coreService.selectedCompanyValue.company.id, location).subscribe({
        next: (next: any) => {
          this.translateService.get('Info.LocationAddedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true }));
          location.id = next.id;
          this.ref.close(location);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    }
  }
}
