import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompanyRole } from '../../shared/models/base/companyRole.model';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/alert';

import { CoreService } from 'src/app/core/core.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AddReservationTypeComponent } from '../_popups';
import { TranslateService } from '@ngx-translate/core';
import { ReservationType } from 'src/app/shared/models/base';
import { ReservationTypeService } from '../../shared/services';
import { MessageBoxData, MessageBoxService } from 'src/app/message-box';

@Component({
  selector: 'app-moderator-reservation-types-management',
  templateUrl: './moderator-reservation-types-management.component.html',
  styleUrls: ['./moderator-reservation-types-management.component.css'],
  providers: [DialogService]
})
export class ModeratorReservationTypesManagementComponent implements OnInit, OnDestroy {

  selectedCompany: CompanyRole;
  selectedCompanySub: Subscription;

  reservationTypes: ReservationType[];

  loading: boolean;

  constructor(private coreService: CoreService,
              private reservationTypeService: ReservationTypeService,
              private alertService: AlertService,
              private translateService: TranslateService,
              private messageBoxService: MessageBoxService,
              private dialogService: DialogService) { }

  ngOnInit() {
    this.loading = true;
    this.selectedCompanySub = this.coreService.selectedCompany$.subscribe(comp => {
      this.selectedCompany = comp;
      this.getTypes();
    });
  }

  ngOnDestroy() {
    this.selectedCompanySub.unsubscribe();
  }

  getTypes() {
    if (!this.selectedCompany) {
      return;
    }

    this.reservationTypeService.getTypes(this.selectedCompany.company.id)
      .subscribe({
        next: (next) => {
          // Korzystamy z api, żeby mieć świeże dane
          this.coreService.saveReservationTypes(next);
          this.reservationTypes = next;
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  onAddTypeClicked() {
    const dialog = this.dialogService.open(AddReservationTypeComponent, {
      header: this.translateService.instant('Dialog.AddReservationType'),
      data: { }
    });

    dialog.onClose.subscribe((editedType: ReservationType) => {
      if (editedType) {
        this.reservationTypes.push(editedType);
      }
    });
  }

  onEditTypeClicked(type: ReservationType) {
    const dialog = this.dialogService.open(AddReservationTypeComponent, {
      header: this.translateService.instant('Dialog.EditReservationType'),
      data: { reservationTypeId: type.id }
    });

    dialog.onClose.subscribe((editedType: ReservationType) => {
      if (editedType) {
        const idx = this.reservationTypes.indexOf(type);
        this.reservationTypes[idx] = editedType;
      }
    });
  }

  onDeleteTypeClicked(type: ReservationType) {
    const data = new MessageBoxData();
    data.title = this.translateService.instant('Dialog.ConfirmDeletingReservationType');
    data.message = this.translateService.instant('Dialog.DeletingReservationType');
    data.result.subscribe((result) => {
      if (result === 'yes') {
        this.deleteReservationType(type.id);
      }
      data.result.unsubscribe();
    });
    this.messageBoxService.show(data);
  }

  deleteReservationType(typeId: number) {
    this.reservationTypeService.delete(this.selectedCompany.company.id, typeId)
      .subscribe({
        next: () => {
          this.translateService.get('Info.SuccessfullyDeletedReservationType')
          .subscribe(msg => this.alertService.success(msg, { autoClose: true }));
          this.reservationTypes = this.reservationTypes.filter(u => u.id !== typeId);
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }
}
