import { User } from '..';
import { IConvertable } from '../../../../helper/interfaces';

export class ActionLog implements IConvertable<any> {
    id: number;
    dateTime: Date;
    user: User;
    url: string;
    controllerName: string;
    actionName: string;
    responseStatusCode: number;
    duration: number;
    httpMethod: string;

    responseValueObj: any;
    requestParametersObj: any;
    isResponseValueJson: boolean;

    convertFrom(input: any): this {
        Object.assign(this, input);

        try { // responseValue nie zawsze jest jsonem
            let responseVal = input.responseValue as string;

            if (responseVal[0] === '"' && '"' === responseVal[responseVal.length - 1]) {
                responseVal = responseVal.slice(1, -1).replace(/\\"/gi, '"');
            }
            this.responseValueObj = JSON.parse(responseVal);
            this.isResponseValueJson = true;
        } catch (Error) {
            this.responseValueObj = input.responseValue;
            this.isResponseValueJson = false;
        }

        this.requestParametersObj = JSON.parse(input.requestParameters);

        this.dateTime = input.dateTime;

        return this;
    }
}
