import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CoreService } from 'src/app/core/core.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private coreService: CoreService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const language = this.coreService.selectedLanguageValue?.id;

    if (language) {
      request = request.clone({
        setHeaders: {
          // 'Content-Type': 'application/json',
          LanguageId: language.toString()
        }
      });
    }
    return next.handle(request);
  }
}
