import { AddWarehouseComponent } from './_popups/add-warehouse/add-warehouse.component';
import { AddLocationComponent } from './_popups/add-location/add-location.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule } from './../alert/alert.module';
import { SharedModule } from './../shared/shared.module';
import { CoreModule } from '../core/core.module';

import {
  ModeratorPanelComponent,
  ModeratorUsersManagementComponent,
  ModeratorLocationsManagementComponent,
  ModeratorReservationTypesManagementComponent,
  ModeratorReservationStatusesManagementComponent,
  ModeratorReservationReportComponent,
} from './';

import {
  EditUserComponent,
  AddReservationTypeComponent,
  AddReservationStatusComponent,
  AddUserComponent
} from './_popups';
import { AddSlotComponent } from './_popups/add-slot/add-slot.component';

@NgModule({
  declarations: [
    ModeratorPanelComponent,
    ModeratorUsersManagementComponent,
    ModeratorLocationsManagementComponent,
    ModeratorReservationTypesManagementComponent,
    ModeratorReservationStatusesManagementComponent,
    EditUserComponent,
    AddReservationTypeComponent,
    AddReservationStatusComponent,
    AddUserComponent,
    AddLocationComponent,
    AddWarehouseComponent,
    AddSlotComponent,
    ModeratorReservationReportComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CoreModule
  ],
  entryComponents: [
    EditUserComponent,
    AddReservationTypeComponent,
    AddReservationStatusComponent,
    AddUserComponent,
    AddLocationComponent,
    AddWarehouseComponent,
    AddSlotComponent
  ],
  exports: [
    CommonModule,
    ModeratorPanelComponent,
    ModeratorUsersManagementComponent,
    ModeratorLocationsManagementComponent,
    ModeratorReservationTypesManagementComponent,
    ModeratorReservationStatusesManagementComponent,
    EditUserComponent,
    AddReservationTypeComponent,
    AddReservationStatusComponent
  ],
})
export class ModeratorModule { }
