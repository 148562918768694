import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthService } from '../../shared/services';
import { AlertService } from 'src/app/alert';
import { Language } from '../../shared/models/base/internal/language.model';

import { CoreService } from 'src/app/core/core.service';
import { ValidatorService } from 'src/app/helper/validator.service';

@Component({
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit, OnDestroy {

  supportedLanguagesSub: Subscription;
  supportedLanguages: Language[];

  activateAccountForm: FormGroup;

  loading = false;
  submitted = false;

  returnUrl: string;

  get form() {
    return this.activateAccountForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private userService: UserService,
    private alertService: AlertService,
    private authService: AuthService,
    private translateService: TranslateService,
    private validatorService: ValidatorService
  ) { }

  ngOnDestroy(): void {
    this.supportedLanguagesSub.unsubscribe();
  }

  ngOnInit() {

    if (this.coreService.userValue) {
      this.authService.logout(true);
    }

    this.activateAccountForm = this.formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      repeatPassword: new FormControl('', Validators.required),
      language: new FormControl(null, Validators.required)
    }, {
      validator: this.validatorService.mustMatchValidator('password', 'repeatPassword')
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/login';

    this.supportedLanguagesSub = this.coreService.supportedLanguages$.subscribe({
      next: (next) => {
        if (!next) {
          return;
        }
        this.supportedLanguages = next;
        this.route.queryParams.subscribe(query => {
          const languageId = query.languageId;
          if (languageId) {
            const language = next.find(lang => lang.id.toString() === query.languageId);
            this.selectLanguage(language);
          } else {
            this.selectLanguage(next[0]);
          }
        }).unsubscribe();
      }
    });
  }

  onSelectedLanguageChanged(event) {
    this.selectLanguage(event.value);
  }

  selectLanguage(language: Language) {
    this.form.language.setValue(language);
    this.coreService.selectLanguage(language);
  }

  onActivateAccount() {

    this.submitted = true;
    this.alertService.clear();

    if (this.activateAccountForm.invalid) {
      return;
    }

    this.loading = true;

    let token: string;
    let userId: string;

    this.route.params.subscribe(params => userId = params.userId).unsubscribe();
    this.route.queryParams.subscribe(query => token = query.token).unsubscribe();

    this.userService.activateAccount(this.form.firstName.value, this.form.lastName.value, this.form.password.value,
                                     this.form.language.value.id, token, userId)
      .subscribe({
        next: () => {
          this.translateService.get('Info.ActivateAccountSuccesfull')
          .subscribe(msg => this.alertService.success(msg, {keepAfterRouteChange: true, autoClose: true}));
          this.router.navigate([this.returnUrl]);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }
}
