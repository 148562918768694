import { MainModule } from './../main/main.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreService } from './core.service';
import { NavbarComponent } from './navbar/navbar.component';
import { ApplicationVersionComponent } from './application-version/application-version.component';
import { MainContainerComponent } from './main-container/main-container.component';

import { MessageBoxModule } from './../message-box/message-box.module';
import { AlertModule } from './../alert/alert.module';
import { SharedModule } from './../shared/shared.module';
import { HomeComponent } from './home/home.component';

@NgModule({
  declarations: [
    NavbarComponent,
    ApplicationVersionComponent,
    MainContainerComponent,
    HomeComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AlertModule,
    MessageBoxModule,
    RouterModule,
    MainModule
  ],
  providers: [
    CoreService
  ],
  exports: [
    NavbarComponent,
    ApplicationVersionComponent,
    MainContainerComponent,
    HomeComponent
  ]
})
export class CoreModule { }
