<app-base-popup [loading]="loading" (cancelClicked)="cancelClicked()" (saveClicked)="saveClicked()">
    <form [formGroup]="reservationStatusForm">
        <div class="form-group">
            <div class="mt-1">
                <label for="color" translate>Label.Color</label>
                <input type="color" formControlName="color" class="form-control"
                    placeholder="{{'Label.Color' | translate}}"
                    [ngClass]="{ 'is-invalid': submitted && form.color.errors }" />
                <app-form-error-message *ngIf="submitted" [control]="form.color"></app-form-error-message>
            </div>

            <div *ngIf="(supportedLanguages && !config.data.reservationStatusId) || reservationStatus"
                class="form-group mt-4">
                <div *ngFor="let language of supportedLanguages" class="mt-1">
                    <label>{{"Label.ReservationStatusName" | translate}} - {{language.displayName}}</label>
                    <input class="form-control" [formControlName]="language.id"
                        placeholder="{{'Label.ReservationStatusName' | translate}} - {{language.displayName}}"
                        [ngClass]="{ 'is-invalid': submitted && form[language.id].errors }" />
                    <app-form-error-message *ngIf="submitted" [control]="form[language.id]">
                    </app-form-error-message>
                </div>
            </div>
        </div>
    </form>
</app-base-popup>