import { Component, OnInit } from '@angular/core';

import { LazyLoadEvent } from 'primeng/api/public_api';

import { PlatformService } from '../../shared/services';
import { AlertService } from 'src/app/alert';
import { User, ActionLog } from '../../shared/models/base';

import { CoreService } from 'src/app/core/core.service';
import { getYesterdayDate, getTodayDate, addDays } from '../../helper/date-time-helpers';
import { LocaleSettings } from 'primeng';

@Component({
  templateUrl: './action-logs-preview.component.html',
  styleUrls: ['./action-logs-preview.component.css'],
})
export class ActionLogsPreviewComponent implements OnInit {

  itemsOnPage = 100;

  calendarConfiguration: LocaleSettings = {
    firstDayOfWeek: 1,
    dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    dayNamesShort: ['Nie', 'Pon','Wt','Śr','Czw','Pią','Sob'],
    dayNamesMin: ['Nie', 'Pon','Wt','Śr','Czw','Pią','Sob'],
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecien', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    monthNamesShort: ['STY', 'LUT', 'MAR', 'KWI', 'MAJ', 'CZE', 'LIP', 'SIE', 'WRZ', 'PAŹ', 'LIS', 'GRU'],
    today: 'today',
    clear: 'clear'
  };

  logs: ActionLog[];
  totalLogsCount: number;

  rangeDates: Date[];

  users: User[];
  selectedUsers: User[];

  httpMethods = [{ label: 'GET', value: 'GET' },
                 { label: 'POST', value: 'POST' },
                 { label: 'DELETE', value: 'DELETE' }];
  selectedHttpMethods: string[];

  url: string;
  responseCodes: string;
  minimalDuration: string;
  requestParameters: string;

  loading: boolean;

  constructor(private platformService: PlatformService,
              private alertService: AlertService,
              public coreService: CoreService) { }

  ngOnInit() {

    this.loading = true;

    this.rangeDates = [getYesterdayDate(), getTodayDate()];

    this.platformService.getActionLogUsers()
      .subscribe({
        next: (next) => {
          this.users = next;
        },
        error: (error) => {
          this.alertService.error(error);
        }
      });
  }

  loadLogsLazy(event: LazyLoadEvent) {
    this.getLogs(event.first, this.itemsOnPage);
  }

  refreshLogs() {
    this.logs = null;
    this.totalLogsCount = 0;
    this.getLogs(0, this.itemsOnPage);
  }

  getLogs(skip: number, take: number) {
    this.loading = true;

    const userIds = !!this.selectedUsers ? this.selectedUsers.map(user => user.id) : null;
    const responseCodesArray = !!this.responseCodes && this.responseCodes.length > 0 ?
      this.responseCodes.split(';').map(code => parseInt(code, 10)) : null;

    this.platformService.getActionLogs(skip, take, this.rangeDates[0], addDays(this.rangeDates[1], 1), userIds,
      this.url, responseCodesArray, parseInt(this.minimalDuration, 10), true,
      this.requestParameters, this.selectedHttpMethods)
      .subscribe({
        next: (ret) => { this.logs = ret.logs; this.totalLogsCount = ret.totalItemsCount; this.loading = false; },
        error: (error) => { this.alertService.error(error); this.loading = false; },
      });
  }
}
