import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../shared/services';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService,
              private authenticationService: AuthService,
              private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 0) {
            return throwError(this.translateService.instant('Info.ConnectionError'));
          } else if (error.status === 500) {
            if (environment.showServerErrorMessage) {
              return throwError(getErrorMessage(error));
            } else {
              return throwError(this.translateService.instant('Info.ServerError'));
            }
          } else if (error.status === 401) {
            this.authenticationService.logout();
            const msg = getErrorMessage(error);
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
            if (msg) {
              return throwError(msg);
            } else {
              return throwError(this.translateService.instant('Info.TokenExpired'));
            }
          } else if (error.status === 400 || error.status === 403 || error.status === 404 || error.status === 422) {
            const msg = getErrorMessage(error);
            if (msg) {
              return throwError(msg);
            } else {
              return throwError(this.translateService.instant('Info.ErrorContactWithAdmin'));
            }
          } else {
            return throwError(this.translateService.instant('Info.UnknownError'));
          }
        } else {
          return throwError(this.translateService.instant('Info.UnknownError'));
        }
      }));
  }
}

function getErrorMessage(error: HttpErrorResponse) {
  const appError = error.headers.get('Application-Error');
  if (appError) {
    return appError;
  }

  const serverError = error.error;
  let modelStateErrors = '';

  if (serverError && serverError.errors && typeof serverError.errors === 'object') {
    for (const key in serverError.errors) {
      if (serverError.errors[key]) {
        modelStateErrors += serverError.errors[key] + '\n';
      }
    }
  }
  if (modelStateErrors !== '') {
    return modelStateErrors;
  } else if (serverError !== null) {
    return serverError;
  }
  if (error !== null && serverError !== null && serverError.message) {
    return serverError.message;
  }
}
