import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { LazyLoadEvent } from 'primeng/api/public_api';

import { CompanyService } from '../../../shared/services';
import { Company } from '../../../shared/models/base';
import { AlertService } from 'src/app/alert';

@Component({
  selector: 'app-company-management-list',
  templateUrl: './company-management-list.component.html',
  styleUrls: ['./company-management-list.component.css']
})
export class CompanyManagementListComponent implements OnInit {

  constructor(private companyService: CompanyService,
              private alertService: AlertService,
              private router: Router,
              private route: ActivatedRoute) { }

  companies: Company[] = [];
  totalCompaniesCount: number;

  loading: boolean;

  ngOnInit() {
    this.loading = true;
  }

  onRowEdit(company: Company) {
    this.router.navigate([company.id], { relativeTo: this.route });
  }

  loadCompaniesLazy(event: LazyLoadEvent) {
    this.loading = true;

    this.companyService.getCompanies(event.first, event.rows)
      .subscribe({
        next: (next) => {
          this.companies = next.companies,
          this.totalCompaniesCount = next.totalItemsCount;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        },
        complete: () => this.loading = false
      });
  }
}
