import { IConvertable } from '../../../helper/interfaces';

export class Role implements IConvertable<any> {
  public id: number;
  public name: string;

  public isModerator: boolean;

  convertFrom(input: any): this {
    this.id = input.id;
    this.name = input.displayName;

    // TODO: Jak rozpoznać moderatora
    this.isModerator = this.name === 'Moderator';

    return this;
  }
}
