<app-base-popup [loading]="loading" (cancelClicked)="cancelClicked()" (saveClicked)="saveClicked()">
  <form [formGroup]="editUserForm" style="min-height: 200px;">

    <div class="form-group">
      <label for="role" translate>Label.Role</label>
      <p-dropdown [autoDisplayFirst]="false" [options]="availableRoles" formControlName="role" optionLabel="name"
        [style]="{'min-width':'0', 'width': '100%'}" [ngClass]="{ 'is-invalid': submitted && form.role.errors }"
        required>
      </p-dropdown>
      <app-form-error-message *ngIf="submitted" [control]="form.role"></app-form-error-message>
    </div>

    <div class="form-group">
      <label translate>Label.Slots</label>
      <p-multiSelect [options]="slots" formControlName="slots" [filter]="false" optionLabel="fullSlotName"
          [style]="{'width': '100%', 'height':'35px'}"defaultLabel="">
      </p-multiSelect>
  </div>
  </form>
</app-base-popup>