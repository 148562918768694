import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';

import { ReservationStatusService } from '../../shared/services';
import { AlertService } from 'src/app/alert';
import { ReservationStatus } from '../../shared/models/base';
import { CompanyRole } from '../../shared/models/base/companyRole.model';
import { CoreService } from 'src/app/core/core.service';
import { MessageBoxService, MessageBoxData } from 'src/app/message-box';
import { AddReservationStatusComponent } from '../_popups';

@Component({
  selector: 'app-moderator-reservation-statuses-management',
  templateUrl: './moderator-reservation-statuses-management.component.html',
  styleUrls: ['./moderator-reservation-statuses-management.component.css'],
  providers: [DialogService]
})
export class ModeratorReservationStatusesManagementComponent implements OnInit, OnDestroy {

  selectedCompany: CompanyRole;
  selectedCompanySub: Subscription;

  reservationStatuses: ReservationStatus[];

  loading: boolean;

  constructor(private coreService: CoreService,
              private reservationStatusService: ReservationStatusService,
              private alertService: AlertService,
              private translateService: TranslateService,
              private messageBoxService: MessageBoxService,
              private dialogService: DialogService) { }

  ngOnInit() {
    this.loading = true;
    this.selectedCompanySub = this.coreService.selectedCompany$.subscribe(comp => {
      this.selectedCompany = comp;
      this.getStatuses();
    });
  }

  ngOnDestroy() {
    this.selectedCompanySub.unsubscribe();
  }

  getStatuses() {
    if (!this.selectedCompany) {
      return;
    }

    this.reservationStatusService.getStatuses(this.selectedCompany.company.id)
      .subscribe({
        next: (next) => {
          // Korzystamy z api, żeby mieć świeże dane
          this.coreService.saveReservationStatuses(next);
          this.reservationStatuses = next;
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  onAddStatusClicked() {
    const dialog = this.dialogService.open(AddReservationStatusComponent, {
      header: this.translateService.instant('Dialog.AddReservationStatus'),
      data: { }
    });

    dialog.onClose.subscribe((editedStatus: ReservationStatus) => {
      if (editedStatus) {
        this.reservationStatuses.push(editedStatus);
      }
    });
  }

  onEditStatusClicked(status: ReservationStatus) {
    const dialog = this.dialogService.open(AddReservationStatusComponent, {
      header: this.translateService.instant('Dialog.EditReservationStatus'),
      data: { reservationStatusId: status.id }
    });

    dialog.onClose.subscribe((editedStatus: ReservationStatus) => {
      if (editedStatus) {
        const idx = this.reservationStatuses.indexOf(status);
        this.reservationStatuses[idx] = editedStatus;
      }
    });
  }

  onDeleteStatusClicked(status: ReservationStatus) {
    const data = new MessageBoxData();
    data.title = this.translateService.instant('Dialog.ConfirmDeletingReservationStatus');
    data.message = this.translateService.instant('Dialog.DeletingReservationStatus');
    data.result.subscribe((result) => {
      if (result === 'yes') {
        this.deleteReservationStatus(status.id);
      }
      data.result.unsubscribe();
    });
    this.messageBoxService.show(data);
  }

  deleteReservationStatus(statusId: number) {
    this.reservationStatusService.delete(this.selectedCompany.company.id, statusId)
      .subscribe({
        next: () => {
          this.translateService.get('Info.SuccessfullyDeletedReservationStatus')
          .subscribe(msg => this.alertService.success(msg, { autoClose: true }));
          this.reservationStatuses = this.reservationStatuses.filter(u => u.id !== statusId);
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  getColorPreviewStyle(color: string) {
    const style = {};
    // tslint:disable-next-line: no-string-literal
    style['border'] = '1px solid #000';
    style['background-color'] = color;
    // tslint:disable-next-line: no-string-literal
    style['height'] = '20px';

    return style;
  }
}
