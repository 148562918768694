import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import polishLanguage from './../assets/i18n/pl.json';
import englishLanguage from './../assets/i18n/en.json';

import { AuthService, PlatformService } from './shared/services';
import { AlertService } from 'src/app/alert';
import { CoreService } from 'src/app/core/core.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {

  private selectedLangSub: Subscription;

  constructor(translate: TranslateService,
              private authService: AuthService,
              private coreService: CoreService,
              private translateService: TranslateService,
              private alertService: AlertService,
              private platformService: PlatformService) {

    translate.setTranslation('pl', polishLanguage);
    translate.setTranslation('en', englishLanguage);
    translate.setDefaultLang('pl');
  }

  ngOnInit(): void {

    if (this.coreService.userValue) {
      this.authService.getLogged().subscribe({
        error: (error) => {
          this.alertService.error(error);
        }
      });
    }

    this.selectedLangSub = this.coreService.selectedLanguage$.subscribe(lang => {
      if (lang) {
        this.translateService.use(lang.name);
      }
    });

    this.platformService.getLanguages().subscribe({
      error: (error) => {
        this.alertService.error(error);
      }
    });
  }

  ngOnDestroy(): void {
    this.coreService.selectCompany(null);
    this.selectedLangSub.unsubscribe();
  }
}
