import { UserRole } from './../base/userRole.model';
import { IConvertable } from '../../../helper/interfaces';

export class UserRoleOutput implements IConvertable<UserRole> {

  email: string;
  roleId: number;
  languageId: number;
  slotsIds: number[];

  convertFrom(input: UserRole): this {
    this.email = input.user?.email;
    this.languageId = input.user?.languageId;
    this.roleId = input.role?.id;
    this.slotsIds = (input.slots != null && input.slots.length > 0  ) ? input.slots.map(slot => slot.id) : null;
    return this;
  }
}
