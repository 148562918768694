import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CoreService } from 'src/app/core/core.service';

@Injectable({ providedIn: 'root' })
export class ModeratorGuard implements CanActivate {

  constructor(private router: Router,
              private coreService: CoreService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.coreService.isLoggedIn) {
      if (this.coreService.userValue.isAdmin || this.coreService.selectedCompanyValue?.role?.isModerator) {
        return true;
      }
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
