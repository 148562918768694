import { IConvertable } from '../../../../helper/interfaces';

export class ServerInfo implements IConvertable<any> {

    version: string;
    compatibleDbVersion: string;

    convertFrom(input: any): this {
        this.version = input.version;
        this.compatibleDbVersion = input.compatibleDbVersion;

        return this;
    }
}
