<div class="row">
    <div class="col-xl-10 offset-xl-1 box-shadow jumbotron bg-light">
        <div class="m-1">
            <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.PlatformLogsPreview</h2>
            <p-scrollPanel [style]="{width: '100%'}">
                <p-table [value]="logs" dataKey="id" [lazy]="true" (onLazyLoad)="loadLogsLazy($event)"
                    [style]="{'min-width':'700px','min-height':'600px'}" [paginator]="true" [rows]="itemsOnPage"
                    [totalRecords]="totalLogsCount" [loading]="loading">

                    <ng-template pTemplate="caption">
                        <div style="text-align: left">
                            <button class="btn btn-sm btn-outline-dark" (click)="refreshLogs()"
                                translate>Button.RefreshLogs</button>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 40px"></th>
                            <th style="width: 60px" translate>Label.Id</th>
                            <th style="width: 250px" translate>Label.DateTime</th>
                            <th style="width: 230px" translate>Label.LogLevel</th>
                            <th translate>Label.Message</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>
                                <p-calendar [dateFormat]="coreService.dateTimePatternValue"
                                    [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true"
                                    (onClose)="refreshLogs()" [style]="{'width':'100%'}"
                                    [inputStyle]="{'width':'100%'}"
                                    [locale] = "calendarConfiguration"></p-calendar>
                            </th>
                            <th>
                                <p-multiSelect [options]="logLevels" [(ngModel)]="selectedLogLevels" [filter]="false"
                                    optionLabel="name" [style]="{'width': '100%'}"
                                    defaultLabel="{{'Label.AllLogLevels' | translate}}" (onPanelHide)="refreshLogs()">
                                </p-multiSelect>
                            </th>
                            <th>
                                <input type="text" class="form-control" placeholder="{{'Label.Message' | translate}}"
                                    [(ngModel)]="message" (keyup.enter)="refreshLogs()">
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-expanded="expanded" let-log>
                        <tr>
                            <td>
                                <a href="#" [pRowToggler]="log">
                                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                                </a>
                            </td>
                            <td>
                                {{log.id}}
                            </td>
                            <td>
                                {{log.dateTime | date:'dd-MM-yyyy HH:mm:ss'}} 
                            </td>
                            <td>
                                {{log.logLevel}}
                            </td>
                            <td class="partMessage">
                                {{log.message}}
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="rowexpansion" let-log>
                        <tr>
                            <td [attr.colspan]="5">
                                <div class="container-fluid" style="font-size:14px">
                                    <div class="row">
                                        <p-scrollPanel class="col-12 col-sm-12"
                                            [style]="{width: '100%', height: '350px'}">
                                            <div class="breakLines">
                                                {{log.message }}
                                            </div>
                                        </p-scrollPanel>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-scrollPanel>
        </div>
    </div>
</div>