import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { LazyLoadEvent } from 'primeng/api/public_api';
import { DialogService } from 'primeng/dynamicdialog';

import { UserService, CompanyService } from '../../../shared/services';
import { Company, User } from '../../../shared/models/base';
import { UserExt } from '../../../shared/models/extended';
import { AlertService } from 'src/app/alert';

import {
  UserManagementAddUserComponent
} from '../user-management-add-user/user-management-add-user.component';

import {MessageBoxData, MessageBoxService} from 'src/app/message-box';

@Component({
  templateUrl: './user-management-list.component.html',
  styleUrls: ['./user-management-list.component.css'],
  providers: [DialogService]
})
export class UserManagementListComponent implements OnInit {

  selectedCompany: Company;
  companies: Company[];
  users: UserExt[];
  totalUsersCount: number;

  loading: boolean;

  constructor(private userService: UserService,
              private alertService: AlertService,
              private companyService: CompanyService,
              private router: Router,
              private route: ActivatedRoute,
              private messageBoxService: MessageBoxService,
              private translateService: TranslateService,
              private dialogService: DialogService) { }

  ngOnInit() {
    this.loading = true;

    this.companyService.getCompanies().subscribe({
      next: (next) => {
        this.companies = next.companies;
      },
      error: (error) => this.alertService.error(error)
    });
  }

  onAddUserClicked() {
    const addUserDialog = this.dialogService.open(UserManagementAddUserComponent, {
      header: this.translateService.instant('Dialog.AddUser'),
    });

    addUserDialog.onClose.subscribe((user: User) => {
      if (user) {
        this.userService.add(user).subscribe({
          next: (next) => {
            this.translateService.get('Info.UserAddedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, {keepAfterRouteChange: true, autoClose: true}));
            this.router.navigate([next.id], { relativeTo: this.route });
            this.loading = false;
          },
          error: (error) => {
            this.alertService.error(error);
            this.loading = false;
          }
        });
      }
    });
  }

  onEditUserClicked(user: UserExt) {
    this.router.navigate([user.id], { relativeTo: this.route });
  }

  onDeleteUserClicked(user: UserExt) {

    const data = new MessageBoxData();
    data.title = this.translateService.instant('Dialog.ConfirmDeletingUser');
    data.message = this.translateService.instant('Dialog.DeletingUser', { userName: user.email });
    data.result.subscribe((result) => {
      if (result === 'yes') {
        this.deleteUser(user.id);
      }
      data.result.unsubscribe();
    });
    this.messageBoxService.show(data);
  }

  onSendActivationEmail(user: UserExt) {
      const data = new MessageBoxData();
      data.title = this.translateService.instant('Dialog.EmailActivation');
      data.message = this.translateService.instant('Dialog.EmailActivationMessage', {userEmail: user.email});
      data.result.subscribe((result) => {
        if (result === 'yes'){
          this.sendActivationEmail(user);
        }
        data.result.unsubscribe;
      })
      this.messageBoxService.show(data);
  }

  onCompanyFilterChanged() {
    this.getUsers(0, 20);
  }

  sendActivationEmail(user: UserExt) {
    this.userService.sendActivationEmail(user)
    .subscribe({
      next: () => {
        this.translateService.get('Info.ActivationEmailSent')
        .subscribe(msg => this.alertService.success(msg, {autoClose: true}));
        this.loading = false;
      },
      error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }

  resetCompanyFilter() {
    this.selectedCompany = null;
    this.onCompanyFilterChanged();
  }

  loadUsersLazy(event: LazyLoadEvent) {
    this.getUsers(event.first, event.rows);
  }


  deleteUser(userId: string) {
    this.userService.delete(userId)
      .subscribe({
        next: () => {
          this.translateService.get('Info.SuccessfullyDeletedUser')
          .subscribe(msg => this.alertService.success(msg, {autoClose: true}));
          this.users = this.users.filter(u => u.id !== userId);
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  getUsers(skip: number, take: number) {
    this.loading = true;

    this.userService.getUsers(skip, take, this.selectedCompany?.id)
      .subscribe({
        next: (next) => {
          this.users = next.users;
          this.totalUsersCount = next.totalItemsCount;
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        },
      });
  }

  isModerator(user: UserExt) {
    return user.companies.some(company => company.role.id === this.selectedCompany.id);
  }
}
