<div class="row">
    <div class="col-xl-10 offset-xl-1 box-shadow jumbotron bg-light">
        <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.ModeratorReservationStatusesManagement</h2>

        <p-scrollPanel [style]="{width: '100%'}">
            <p-table [value]="reservationStatuses" dataKey="id" [lazy]="true"
                [paginator]="true" [rows]="20" [loading]="loading"
                [style]="{'min-width':'800px' }">

                <ng-template pTemplate="caption">
                    <div style="text-align:left">
                        <button type="button"
                            class="btn btn-sm btn-outline-dark" (click)="onAddStatusClicked()"
                            translate>Button.AddStatus</button>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th translate>Label.Name</th>
                        <th translate>Label.Color</th>
                        <th translate>Label.Options</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-status>
                    <tr>
                        <td>
                            {{status.name}}
                        </td>
                        <td>
                            <div [style]="getColorPreviewStyle(status.color)">

                            </div>
                        </td>

                        <td style="text-align:center">
                            <button type="button" class="btn btn-sm btn-outline-dark mr-1 pi pi-pencil"
                                title="{{'Title.EditStatus' | translate}}" (click)="onEditStatusClicked(status)"></button>

                            <button type="button" class="btn btn-sm btn-outline-dark pi pi-trash"
                                title="{{'Title.DeleteStatus' | translate}}"
                                (click)="onDeleteStatusClicked(status)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-scrollPanel>
    </div>
</div>