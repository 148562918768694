import { Company } from './../base/company.model';
import { IConvertable } from '../../../helper/interfaces';

export class CompaniesLazy implements IConvertable<any> {

  public companies: Company[];
  public totalItemsCount: number;

  convertFrom(input: any): this {
    Object.assign(this, input);

    if (input.items !== undefined) {
      this.companies = input.items.map(c => new Company().convertFrom(c));
    }

    return this;
  }
}
