import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  ModeratorPanelComponent,
  ModeratorUsersManagementComponent,
  ModeratorLocationsManagementComponent,
  ModeratorReservationTypesManagementComponent,
  ModeratorReservationStatusesManagementComponent,
  ModeratorReservationReportComponent
} from './moderator';

import {
  RequestResetPasswordComponent,
  ResponseResetPasswordComponent,
  ActivateAccountComponent,
  AccountPreviewComponent,
  LoginComponent
} from './user';

import { HomeComponent } from './core';
import {
  AdminPanelComponent,
  CompanyManagementComponent,
  CompanyManagementListComponent,
  CompanyManagementAddComponent,
  CompanyManagementEditComponent,
  UserManagementComponent,
  UserManagementListComponent,
  UserManagementEditComponent,
  ActionLogsPreviewComponent,
  PlatformLogsPreviewComponent
} from './admin';

import {
  LoggedGuard,
  AdminGuard,
  ModeratorGuard
} from './helper/guards';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [LoggedGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'request-reset-password', component: RequestResetPasswordComponent },
  { path: 'response-reset-password', component: ResponseResetPasswordComponent },
  { path: 'activate-account/:userId', component: ActivateAccountComponent },
  { path: 'account', component: AccountPreviewComponent },
  {
    path: 'admin',
    component: AdminPanelComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: '',
        redirectTo: 'company-management',
        pathMatch: 'full'
      },
      {
        path: 'company-management',
        component: CompanyManagementComponent,
        children: [
          {
            path: '',
            component: CompanyManagementListComponent
          },
          {
            path: 'add',
            component: CompanyManagementAddComponent
          },
          {
            path: ':companyId',
            component: CompanyManagementEditComponent
          }
        ]
      },
      {
        path: 'user-management',
        component: UserManagementComponent,
        children: [
          {
            path: '',
            component: UserManagementListComponent
          },
          {
            path: ':userId',
            component: UserManagementEditComponent
          }
        ]
      },
      {
        path: 'action-logs-preview',
        component: ActionLogsPreviewComponent
      },
      {
        path: 'platform-logs-preview',
        component: PlatformLogsPreviewComponent
      },
    ]
  },
  {
    path: 'moderator',
    component: ModeratorPanelComponent,
    canActivate: [ModeratorGuard],
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      },
      {
        path: 'users',
        component: ModeratorUsersManagementComponent
      },
      {
        path: 'locations',
        component: ModeratorLocationsManagementComponent
      },
      {
        path: 'reservation-types',
        component: ModeratorReservationTypesManagementComponent
      },
      {
        path: 'reservation-statuses',
        component: ModeratorReservationStatusesManagementComponent
      },
      {
        path: 'reservation-report',
        component: ModeratorReservationReportComponent
      }
    ]
  },

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const appRoutingModule = RouterModule.forRoot(routes);
