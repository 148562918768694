<app-base-popup (cancelClicked)="cancelClicked()" (saveClicked)="saveClicked()">
    <form [formGroup]="addUserForm">
        <div class="form-group">
            <label for="email" translate>Label.EmailAddress</label>
            <input type="email" formControlName="email" class="form-control"
                placeholder="{{'Label.EmailAddress' | translate}}" required
                [ngClass]="{ 'is-invalid': submitted && form.email.errors }" />
            <app-form-error-message *ngIf="submitted" [control]="form.email"></app-form-error-message>
        </div>

        <div class="form-group">
            <label for="language" translate>Label.Language</label>
            <p-dropdown [options]="supportedLanguages" formControlName="language"
                [style]="{'min-width':'0', 'width': '100%'}" optionLabel="displayName"
                [ngClass]="{ 'is-invalid': submitted && form.language.errors }" required>
            </p-dropdown>
            <app-form-error-message *ngIf="submitted" [control]="form.language"></app-form-error-message>
        </div>

        <div class="form-check ml-3">
            <input class="form-check-input" type="checkbox" formControlName="isAdmin" id='isAdminCb'
                [ngClass]="{ 'is-invalid': submitted && form.isAdmin.errors }">
            <label class="form-check-label" for="isAdminCb" translate>
                Label.Administrator
            </label>
        </div>
    </form>
</app-base-popup>