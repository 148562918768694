import { AlertModule } from './../alert/alert.module';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  ReservationComponent,
  SlotsCarouselComponent,
  SlotComponent,
  ReservationAddComponent,
  ReservationPreviewComponent,
} from './';

@NgModule({
  declarations: [
    SlotComponent,
    ReservationComponent,
    SlotsCarouselComponent,
    ReservationAddComponent,
    ReservationPreviewComponent,
  ],
  entryComponents: [
    ReservationAddComponent,
    ReservationPreviewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule
  ],
  exports: [
    SlotsCarouselComponent
  ]
})
export class MainModule { }
