<div class="row m-2">
  <div class="jumbotron bg-light col-sm-8 col-lg-6 col-xl-4 offset-sm-2 offset-lg-3 offset-xl-4">

    <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.ResetPassword</h2>

    <form [formGroup]="resetPasswordForm" (ngSubmit)="onReset()">
      <div class="form-group">
        <label for="email" translate>Label.EmailAddress</label>
        <input type="email" formControlName="email" class="form-control"
          placeholder="{{'Label.EmailAddress' | translate}}" required
          [ngClass]="{ 'is-invalid': submitted && form.email.errors }" autofocus />

        <app-form-error-message *ngIf="submitted" [control]="form.email"></app-form-error-message>
      </div>

      <div class="form-group">
        <button [disabled]="loading" type="submit" class="btn btn-outline-dark" translate>
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Button.ResetPassword
        </button>
      </div>
    </form>
  </div>
</div>