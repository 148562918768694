import { ReservationStatus } from '../shared/models/base/reservation/reservation-status.model';
import { ReservationType } from '../shared/models/base/reservation/reservation-type.model';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserExt } from '../shared/models/extended';
import { CompanyRole } from '../shared/models/base/companyRole.model';
import { Language, Role } from '../shared/models/base';

@Injectable()
export class CoreService {
  // tslint:disable: variable-name

  private _dateTimePattern;

  private _tokenSubject: BehaviorSubject<string>;
  public token$: Observable<string>;

  private _userSubject: BehaviorSubject<UserExt>;
  public user$: Observable<UserExt>;

  private _selectedCompanySubject: BehaviorSubject<CompanyRole>;
  public selectedCompany$: Observable<CompanyRole>;

  private _selectedLanguageSubject: BehaviorSubject<Language>;
  public selectedLanguage$: Observable<Language>;

  private _supportedLanguagesSubject: BehaviorSubject<Language[]>;
  public supportedLanguages$: Observable<Language[]>;

  private _companyTypesSubject: BehaviorSubject<ReservationType[]>;
  public companyTypes$: Observable<ReservationType[]>;

  private _companyStatusesSubject: BehaviorSubject<ReservationStatus[]>;
  public companyStatuses$: Observable<ReservationStatus[]>;

  private _companyRolesSubject: BehaviorSubject<Role[]>;
  public companyRoles$: Observable<Role[]>;


  public get dateTimePatternValue(): string {
    return this._dateTimePattern;
  }

  public get userValue(): UserExt {
    return this._userSubject.value;
  }

  public get tokenValue(): string {
    return this._tokenSubject.value;
  }

  public get selectedCompanyValue(): CompanyRole {
    return this._selectedCompanySubject.value;
  }

  public get selectedLanguageValue(): Language {
    return this._selectedLanguageSubject.value;
  }

  public get isLoggedIn(): boolean {
    return !!this.tokenValue && !!this.userValue;
  }

  constructor(private translateService: TranslateService) {
    this._tokenSubject = new BehaviorSubject<string>(localStorage.getItem('token'));
    this.token$ = this._tokenSubject.asObservable();

    this._userSubject = new BehaviorSubject<UserExt>(JSON.parse(localStorage.getItem('currentUser')));
    this.user$ = this._userSubject.asObservable();

    this._selectedCompanySubject = new BehaviorSubject<CompanyRole>(null);
    this.selectedCompany$ = this._selectedCompanySubject.asObservable();

    this._selectedLanguageSubject = new BehaviorSubject<Language>(JSON.parse(localStorage.getItem('language')));
    this.selectedLanguage$ = this._selectedLanguageSubject.asObservable();

    this._supportedLanguagesSubject = new BehaviorSubject<Language[]>(null);
    this.supportedLanguages$ = this._supportedLanguagesSubject.asObservable();

    this._companyStatusesSubject = new BehaviorSubject<ReservationStatus[]>(null);
    this.companyStatuses$ = this._companyStatusesSubject.asObservable();

    this._companyTypesSubject = new BehaviorSubject<ReservationType[]>(null);
    this.companyTypes$ = this._companyTypesSubject.asObservable();

    this._companyRolesSubject = new BehaviorSubject<Role[]>(null);
    this.companyRoles$ = this._companyRolesSubject.asObservable();

    this._dateTimePattern = 'dd-mm-yy';
  }

  public saveRoles(roles: Role[]) {
    this._companyRolesSubject.next(roles);
  }

  public selectCompany(company: CompanyRole) {
    if (company?.company?.id !== this.selectedCompanyValue?.company?.id) {
      localStorage.setItem('selectedCompany', JSON.stringify(company));
      this._selectedCompanySubject.next(company);
    }
  }

  public selectLanguage(language: Language) {
    if (language) {
      localStorage.setItem('language', JSON.stringify(language));
      this._selectedLanguageSubject.next(language);
      this.translateService.use(language.name);
    }
  }

  public saveToken(token: string) {
    localStorage.setItem('token', token);
    this._tokenSubject.next(token);
  }

  public saveUser(user: UserExt) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this._userSubject.next(user);

    if (this._supportedLanguagesSubject.value && user) {
      this.selectLanguage(this._supportedLanguagesSubject.value.find(lang => lang.id === user.languageId));
    }
  }

  public saveReservationTypes(types: ReservationType[]) {
    this._companyTypesSubject.next(types);
  }

  public saveReservationStatuses(statuses: ReservationStatus[]) {
    this._companyStatusesSubject.next(statuses);
  }

  public saveSupportedLanguages(languages: Language[]) {
    this._supportedLanguagesSubject.next(languages);

    if (this.userValue) {
      this.selectLanguage(languages.find(lang => lang.id === this.userValue.languageId));
    }
  }
}
