<div class="row m-2">
  <div class="jumbotron bg-light col-sm-10 col-lg-6 col-xl-4 offset-sm-1 offset-lg-3 offset-xl-4">

    <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.ActivateAccount</h2>

    <form [formGroup]="activateAccountForm" (ngSubmit)="onActivateAccount()">
      <div class="form-group">
        <label for="firstName" translate>Label.FirstName</label>
        <input type="text" formControlName="firstName" class="form-control"
          placeholder="{{'Label.FirstName' | translate}}" required
          [ngClass]="{ 'is-invalid': submitted && form.firstName.errors }" />

        <app-form-error-message *ngIf="submitted" [control]="form.firstName"></app-form-error-message>
      </div>

      <div class="form-group">
        <label for="lastName" translate>Label.LastName</label>
        <input type="text" formControlName="lastName" class="form-control"
          placeholder="{{'Label.LastName' | translate}}" required
          [ngClass]="{ 'is-invalid': submitted && form.lastName.errors }" />

        <app-form-error-message *ngIf="submitted" [control]="form.lastName"></app-form-error-message>
      </div>

      <div class="form-group">
        <label for="password" translate>Label.Password</label>
        <input type="password" formControlName="password" class="form-control"
          placeholder="{{'Label.Password' | translate}}" required
          [ngClass]="{ 'is-invalid': submitted && form.password.errors }" />

        <app-form-error-message *ngIf="submitted" [control]="form.password"></app-form-error-message>
      </div>

      <div class="form-group">
        <label for="password" translate>Label.RepeatPassword</label>
        <input type="password" formControlName="repeatPassword" class="form-control"
          placeholder="{{'Label.RepeatPassword' | translate}}" required
          [ngClass]="{ 'is-invalid': submitted && form.repeatPassword.errors }" />

        <app-form-error-message *ngIf="submitted" [control]="form.repeatPassword"></app-form-error-message>
      </div>

      <div class="form-group">
        <label for="language" translate>Label.Language</label>
        <p-dropdown [options]="supportedLanguages" formControlName="language"
          [style]="{'min-width':'0', 'width': '100%'}" optionLabel="displayName"
          [ngClass]="{ 'is-invalid': submitted && form.language.errors }" (onChange)="onSelectedLanguageChanged($event)"
          required>
        </p-dropdown>
        <app-form-error-message *ngIf="submitted" [control]="form.language"></app-form-error-message>
      </div>

      <div class="form-group">
        <button [disabled]="loading" type="submit" class="btn btn-outline-dark">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{'Button.Accept' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>