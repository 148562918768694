import { IConvertable } from '../../../../helper/interfaces';

export class Language implements IConvertable<any> {
    id: number;
    name: string;
    displayName: string;

    convertFrom(input: any): this {
        this.id = input.id;
        this.name = input.name;
        this.displayName = input.name;
        return this;
    }
}
