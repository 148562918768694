import { IConvertable } from '../../../helper/interfaces';

export class Warehouse implements IConvertable<any> {

    public id: number;
    public name: string;
    public isActive: boolean;
    public maxUnitsCount: number;

    convertFrom(input: any): this {
        this.id = input.id;
        this.name = input.name;
        this.isActive = input.isActive;
        this.maxUnitsCount = input.maxUnitsCount;

        return this;
    }
}
