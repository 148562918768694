import { MilisecondsToMinutes } from '../../../../helper/duration-helpers';
import { IConvertable } from '../../../../helper/interfaces';
import { Translation } from '../internal/translation.model';

export class ReservationType implements IConvertable<any> {

    id: number;
    name: string;
    duration: number;
    unitsCount: number;
    isReservationDurationChangeAvailable: boolean;

    displayNames: Translation[];

    get durationMinutes() {
        return MilisecondsToMinutes(this.duration);
    }

    convertFrom(input: any): this {
        this.id = input.id;
        this.name = input.displayName;
        this.duration = input.duration;
        this.unitsCount = input.unitsCount;
        this.isReservationDurationChangeAvailable = input.isReservationDurationChangeAvailable;

        if (input.displayNames !== undefined) {
            this.displayNames = input.displayNames.map(c => new Translation().convertFrom(c));
        }

        return this;
    }
}
