import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ManagementPanelComponent } from 'src/app/shared/components';

@Component({
  templateUrl: '../../shared/components/management-panel/management-panel.component.html',
  styleUrls: ['../../shared/components/management-panel/management-panel.component.scss']
})
export class AdminPanelComponent extends ManagementPanelComponent implements OnInit {

  constructor(public translate: TranslateService,
              public router: Router) {
    super(translate, router);
  }

  ngOnInit() {
    this.items = [
      { label: this.translate.instant('Label.Companies'), routerLink: '/admin/company-management' },
      { label: this.translate.instant('Label.Users'), routerLink: '/admin/user-management' },
      { label: this.translate.instant('Label.ActionLogsPreview'), routerLink: '/admin/action-logs-preview' },
      { label: this.translate.instant('Label.PlatformLogsPreview'), routerLink: '/admin/platform-logs-preview' },
    ];

    this.selectedItem = this.items.find(x => x.routerLink === this.router.url);
  }
}
