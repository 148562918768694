import { IConvertable } from 'src/app/helper/interfaces';
import { HistoryReportModel } from './history-report.model';

export class ReservationHistoryReport implements IConvertable<any> {

    dateTimeFrom: Date;
    dateTimeTo: Date;
    location: string;
    warehouse: string;
    reservations: HistoryReportModel[];

    convertFrom(input: any): this {
        this.dateTimeFrom = input.dateTimeFrom;
        this.dateTimeTo = input.dateTimeTo;
        this.location = input.location;
        this.warehouse = input.warehouse;
        if (input.reservations) {

            this.reservations = [];
            input.reservations.forEach(reservation => {
                reservation.changeHistory?.forEach(history => {
                    const model = new HistoryReportModel().convertFrom(history);
                    model.id = reservation.id;
                    model.slotName = reservation.slot.name;
                    this.reservations.push(model);
                });
            });
        }
        return this;
    }
}
