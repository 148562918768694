<nav class="navbar navbar-expand-md navbar-dark border-bottom box-shadow bg-dark">
    <div class="container">

        <img src="../../../assets/agilero_logo.png" routerLink=''
            style="cursor: pointer; height: 25px; margin-right: 10px;">
        <a class="navbar-brand d-none d-sm-block" routerLink='' translate>Page.ReservationAgilero</a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto" *ngIf="isLoggedIn()">
                <li class="nav-item mr-md-0 mr-lg-4">
                    <div *ngIf="currentUser?.companies && currentUser?.companies.length > 0" class="dropdown">
                        <button class="btn btn-dark dropdown-toggle btn-block text-left" style="width: 100%;"
                            type="button" id="dropdownProfile" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            {{selectedCompany?.company?.name}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownProfile">
                            <div *ngFor="let company of currentUser.companies">
                                <button class="dropdown-item"
                                    (click)="selectCompany(company)">{{company?.company.name}}</button>
                                <div class="dropdown-divider"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="dropdown">
                        <button class="btn btn-dark dropdown-toggle btn-block text-left" style="width: 100%;"
                            type="button" id="dropdownProfile" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            {{'Label.LoggedAs' | translate}} {{currentUser.firstName}} {{currentUser.lastName}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownProfile">
                            <div>
                                <a class="dropdown-item" routerLink="." translate>Button.HomePage</a>
                                <div class="dropdown-divider"></div>
                            </div>
                            <div *ngIf="currentUser.isAdmin">
                                <a class="dropdown-item" routerLink="/admin" translate>Button.AdminPanel</a>
                                <div class="dropdown-divider"></div>
                            </div>
                            <div *ngIf="currentUser.isAdmin || selectedCompany?.role?.isModerator">
                                <!-- <a class="dropdown-item" translate>Button.ModeratorPanel</a> -->
                                <a class="dropdown-item" routerLink="/moderator" translate>Button.ModeratorPanel</a>
                                <div class="dropdown-divider"></div>
                            </div>
                            <a class="dropdown-item" routerLink="/account" translate>Button.YourProfile</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" (click)="logOut()" routerLink="/home" translate>Button.Logout</a>
                        </div>
                    </div>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto" *ngIf="!isLoggedIn()">
                <li class="nav-item">
                    <button class="btn btn-dark my-2 my-sm-0" type="submit" routerLink="/login"
                        translate>Button.Login</button>
                </li>
            </ul>
        </div>
    </div>
</nav>