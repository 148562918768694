import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, Validator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AuthService, UserService } from '../../shared/services';
import { AlertService } from 'src/app/alert';

import { CoreService } from 'src/app/core/core.service';
import { ValidatorService } from 'src/app/helper/validator.service';

@Component({
  templateUrl: './response-reset-password.component.html',
  styleUrls: ['./response-reset-password.component.css']
})
export class ResponseResetPasswordComponent implements OnInit, OnDestroy {

  supportedLanguagesSub: Subscription;

  resetForm: FormGroup;

  loading = false;
  submitted = false;

  returnUrl: string;

  get form() {
    return this.resetForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private coreService: CoreService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private validatorService: ValidatorService
  ) { }

  ngOnInit() {

    if (this.coreService.userValue) {
      this.authService.logout(true);
    }

    this.supportedLanguagesSub = this.coreService.supportedLanguages$.subscribe({
      next: (next) => {
        if (!next) {
          return;
        }
        this.route.queryParams.subscribe(query => {
          const languageId = query.languageId;
          if (languageId) {
            const language = next.find(lang => lang.id.toString() === query.languageId);
            this.coreService.selectLanguage(language);
          }
        }).unsubscribe();
      }
    });


    this.resetForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      repeatPassword: new FormControl('', Validators.required)
    }, {
      validator: this.validatorService.mustMatchValidator('password', 'repeatPassword')
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/login';
  }

  ngOnDestroy(): void {
    this.supportedLanguagesSub.unsubscribe();
  }

  onResetPassword() {
    this.submitted = true;
    this.alertService.clear();

    if (this.resetForm.invalid) {
      return;
    }

    this.loading = true;

    let token: string;
    this.route.queryParams.subscribe(query => token = query.token).unsubscribe();

    this.userService.confirmResetPassword(this.form.email.value, this.form.password.value, token)
      .subscribe({
        next: () => {
          this.translateService.get('Info.PasswordResetSuccesfull')
          .subscribe(msg => this.alertService.success(msg, {keepAfterRouteChange: true, autoClose: true}));
          this.router.navigate([this.returnUrl]);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }
}
