import { parseDate } from '../../helper/date-time-helpers';
import { UserRolesLazy } from '../models/lazy/user-roles.lazy.model';
import { createHttpParams } from '../../helper/http-params-creator-helpers';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CompanyExt } from '../models/extended';
import { CompaniesLazy, LocationsLazy, WarehousesLazy } from '../models/lazy';
import { CompanyCreate } from '../models/output/company.create.model';
import { CompanyEdit } from '../models/output/company.edit.model';
import { Company, WarehouseLoad } from '../models/base';
import { UserRole } from '../models/base/userRole.model';
import { UserRoleOutput } from '../models/output/userRole.output.model';
import { CompanyRole } from '../models/base/companyRole.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  baseUrl = environment.baseUrl + '/companies';

  constructor(private http: HttpClient) { }

  getCompanies(skip?: number, take?: number): Observable<CompaniesLazy> {
    const params = createHttpParams({
      skip,
      take
    });

    return this.http.get(this.baseUrl, { params })
      .pipe(map(companiesLazy => new CompaniesLazy().convertFrom(companiesLazy)));
  }

  getCompany(id: number): Observable<CompanyExt> {
    return this.http.get(this.baseUrl + '/' + id)
      .pipe(map(company => new CompanyExt().convertFrom(company)));
  }

  sendActivationEmail(user: UserRole, company: CompanyRole){
    return this.http.post(this.baseUrl + '/' + company.company.id + '/users/' + user.user.id + '/send-activate-account-notification', {});
  }

  patchCompany(comp: CompanyExt): Observable<CompanyExt> {
    return this.http.post(this.baseUrl + '/' + comp.id, new CompanyEdit().convertFrom(comp))
      .pipe(map(company => new CompanyExt().convertFrom(company)));
  }

  addCompany(comp: CompanyExt): Observable<Company> {
    return this.http.post(this.baseUrl, new CompanyCreate().convertFrom(comp))
      .pipe(map(company => new Company().convertFrom(company)));
  }

  getCompanyUsers(companyId: number, skip?: number, take?: number): Observable<UserRolesLazy> {
    const params = createHttpParams({ skip, take });
    return this.http.get(this.baseUrl + '/' + companyId + '/users', { params })
      .pipe(map(usersLazy => new UserRolesLazy().convertFrom(usersLazy)));
  }

  deleteUser(companyId: number, userId: string) {
    return this.http.delete(this.baseUrl + '/' + companyId + '/users/' + userId);
  }

  addUser(companyId: number, user: UserRole) {
    return this.http.post(this.baseUrl + '/' + companyId + '/users', new UserRoleOutput().convertFrom(user));
  }

  editUser(companyId: number, user: UserRole) {
    return this.http.post(this.baseUrl + '/' + companyId + '/users/' + user.user.id, new UserRoleOutput().convertFrom(user));
  }
}
