import { IConvertable } from 'src/app/helper/interfaces';

export class HistoryReportModel  implements IConvertable<any> {

    id: number;
    slotName: string;

    startDateTime: Date;
    endDateTime: Date;
    unitsCount: number;
    statusName: string;
    companyName: string;
    changedDateTime: Date;
    changedBy: string;
    deletedDateTime: Date;
    documentNumber: string;
    type: string;

    convertFrom(input: any): this {
        this.startDateTime = input.startDateTime;
        this.endDateTime = input.endDateTime;
        this.unitsCount = input.unitsCount;
        this.statusName = input.status.displayName;
        this.companyName = input.companyName;
        this.changedDateTime = input.changedDateTime;
        this.changedBy = input.changedBy.firstName + ' ' + input.changedBy.lastName;
        this.deletedDateTime = input.deletedDateTime;
        this.documentNumber = input.documentNumber;
        this.type = input.type.displayName;

        return this;
    }
}
