import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { Reservation } from '../../shared/models/base/reservation/reservation.model';
import { ReservationService, FilesService } from '../../shared/services';
import { AlertService } from 'src/app/alert';
import { MessageBoxService, MessageBoxData } from 'src/app/message-box';
import { CoreService } from 'src/app/core/core.service';
import { ValidatorService } from 'src/app/helper/validator.service';
import { MilisecondsToMinutes } from 'src/app/helper/duration-helpers';
import { addMinutes, countDuration } from 'src/app/helper/date-time-helpers';
import { LocaleSettings } from 'primeng';

@Component({
  selector: 'app-reservation-add',
  templateUrl: './reservation-add.component.html',
  styleUrls: ['./reservation-add.component.css']
})
export class ReservationAddComponent implements OnInit, OnDestroy {

  typesSubscription: Subscription;
  statusesSubscription: Subscription;

  calendarConfiguration: LocaleSettings = {
    firstDayOfWeek: 1,
    dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    dayNamesShort: ['Nie', 'Pon','Wt','Śr','Czw','Pią','Sob'],
    dayNamesMin: ['Nie', 'Pon','Wt','Śr','Czw','Pią','Sob'],
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecien', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    monthNamesShort: ['STY', 'LUT', 'MAR', 'KWI', 'MAJ', 'CZE', 'LIP', 'SIE', 'WRZ', 'PAŹ', 'LIS', 'GRU'],
    today: 'today',
    clear: 'clear'
  };


  availableTypes: any[];
  availableStatuses: any[];

  maxReservationDurationMinutes: number;
  reservation: Reservation;

  public submitted = false;
  public submitting = false;
  public addReservationForm: FormGroup;

  get form() {
    return this.addReservationForm.controls;
  }

  get isDurationChangeDisabled() {
    if (!this.form.reservationType.value) {
      return false;
    } else {
      return !this.form.reservationType.value.isReservationDurationChangeAvailable;
    }
  }

  constructor(private formBuilder: FormBuilder,
              private ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              public coreService: CoreService,
              private reservationService: ReservationService,
              private messageBoxService: MessageBoxService,
              private translateService: TranslateService,
              private filesService: FilesService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private validatorService: ValidatorService) {

    this.maxReservationDurationMinutes = MilisecondsToMinutes(config.data.maxReservationDuration);
    this.reservation = config.data.reservation;
  }

  ngOnDestroy(): void {
    this.statusesSubscription.unsubscribe();
    this.typesSubscription.unsubscribe();

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { reservationId: null, operation: null },
      queryParamsHandling: 'merge'
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const operation = params.operation;

      if (!operation) {
        this.ref.close('cancel');
      }
    });

    this.addReservationForm = this.formBuilder.group({
      reservationType: new FormControl(null, Validators.required),
      unitsCount: new FormControl('', [Validators.required, Validators.min(0)]),
      durationMinutes: new FormControl('', [Validators.required, Validators.min(0), Validators.max(this.maxReservationDurationMinutes)]),
      startDateTime: new FormControl(this.config.data.startDateTime, [Validators.required,
      this.validatorService.timeInternalValidator(this.config.data.slotInterval)]),
      endDateTime: new FormControl('', [Validators.required,
      this.validatorService.timeInternalValidator(this.config.data.slotInterval)]),
      companyName: new FormControl('', Validators.required),
      documentNumber: new FormControl('', Validators.required),
      vehicle: new FormControl('', Validators.required),
      status: new FormControl(null, Validators.required),
      isCycle: new FormControl(false),
      periodicityDays: new FormControl(1, [Validators.min(1), Validators.max(365)]),
      remarks: new FormControl(''),
      attachements: new FormControl(null),
      attachementsSource: new FormControl(null),
    }, {
      validator: this.validatorService.durationLessThanPeriodValidator('durationMinutes', 'periodicityDays')
    });

    this.addReservationForm.get('endDateTime').valueChanges.subscribe(_ => this.endDateTimeChanged());
    this.addReservationForm.get('startDateTime').valueChanges.subscribe(_ => this.startDateTimeChanged());

    this.statusesSubscription = this.coreService.companyStatuses$.subscribe({
      next: (next) => {
        this.availableStatuses = next;
        if (this.reservation) {
          this.form.status.setValue(this.availableStatuses.find(s => s.id === this.reservation.status.id));
        }
        this.updateForm();
      },
      error: (error) => {
        this.alertService.error(error, { componentId: 'add-reservation-alert' });
        this.config.closable = true;
      }
    });

    this.typesSubscription = this.coreService.companyTypes$.subscribe({
      next: (next) => {
        this.availableTypes = next;
        if (this.reservation) {
          this.form.reservationType.setValue(this.availableTypes.find(t => t.id === this.reservation.type.id));
        }
        this.updateForm();
      },
      error: (error) => {
        this.alertService.error(error, { componentId: 'add-reservation-alert' });
        this.config.closable = true;
      }
    });

    if (this.config.data.reservationId) {
      this.reservationService.getReservation(this.config.data.companyId, this.config.data.slotId, this.config.data.reservationId)
        .subscribe({
          next: (next) => {
            this.reservation = next;
            this.setReservationValuesToForm();
            this.updateForm();
            if (!this.reservation.canModify) {
              this.alertService.warning(this.translateService.instant('Info.ThisReservationIsReadOnly'),
                { componentId: 'add-reservation-alert' });
            }
          },
          error: (error) => {
            this.alertService.error(error, { componentId: 'add-reservation-alert' });
            this.config.closable = true;
          }
        });
    }
  }

  deleteReservation() {
    this.submitting = true;
    this.updateForm();

    this.reservationService.delete(this.config.data.companyId, this.config.data.slotId, this.reservation.id)
      .subscribe({
        next: () => {
          this.ref.close('delete');
        },
        error: (error) => {
          this.alertService.error(error, { componentId: 'add-reservation-alert' });
          this.submitting = false;
          this.updateForm();
        }
      });
  }

  editReservation(reservation: Reservation) {

    this.submitting = true;
    this.updateForm();

    this.reservationService.edit(this.config.data.companyId, this.config.data.slotId, reservation.id, reservation)
      .subscribe({
        next: () => {
          this.uploadFiles(reservation.id);
        },
        error: (error) => {
          this.alertService.error(error, { componentId: 'add-reservation-alert' });
          this.submitting = false;
          this.updateForm();
        }
      });
  }

  addReservation(reservation: Reservation) {

    this.submitting = true;
    this.updateForm();

    this.reservationService.add(this.config.data.companyId, this.config.data.slotId, reservation)
      .subscribe({
        next: (next) => {
          this.uploadFiles(next.id);
        },
        error: (error) => {
          this.alertService.error(error, { componentId: 'add-reservation-alert' });
          this.submitting = false;
          this.updateForm();
        }
      });
  }

  getAttachmentNames(): string {
    const formValue = this.form.attachementsSource.value as File[];
    if (!formValue) {
      return;
    }

    return (Array.from(formValue)).map(v => v?.name).join(', ');
  }

  uploadFiles(reservationId: number) {
    if (!this.form.attachementsSource.value) {
      this.ref.close(this.config.data.type);
    } else {
      this.filesService.upload(this.config.data.companyId, this.config.data.slotId, reservationId, this.form.attachementsSource.value)
        .subscribe({
          next: () => {
            this.ref.close(this.config.data.type);
          },
          error: (error) => {
            this.alertService.error(error, { componentId: 'add-reservation-alert' });
            this.submitting = false;
            this.updateForm();
          }
        });
    }
  }

  deleteAttachment(attachmentId: number) {
    this.filesService.delete(this.config.data.companyId, this.config.data.slotId, this.reservation.id, attachmentId)
      .subscribe({
        next: () => {
          this.reservation.attachments = this.reservation.attachments.filter(att => att.id !== attachmentId);
          this.submitting = false;
          this.updateForm();
        },
        error: (error) => {
          this.alertService.error(error, { componentId: 'add-reservation-alert' });
          this.submitting = false;
          this.updateForm();
        }
      });
  }

  attachementChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files;
      this.addReservationForm.patchValue({
        attachementsSource: file
      });
    }
  }

  reservationTypeChanged() {
    this.form.unitsCount.setValue(this.form.reservationType.value.unitsCount);
    this.form.durationMinutes.setValue(MilisecondsToMinutes(this.form.reservationType.value.duration));
    this.form.endDateTime.setValue(addMinutes(this.form.startDateTime.value,
      MilisecondsToMinutes(this.form.reservationType.value.duration)));

    this.updateForm();
  }

  durationChanged() {
    const x = addMinutes(this.form.startDateTime.value, this.form.durationMinutes.value);
    this.form.endDateTime.setValue(x);
  }

  startDateTimeChanged() {
    if (this.form.startDateTime.value && this.form.durationMinutes.value) {
      const x = addMinutes(this.form.startDateTime.value, this.form.durationMinutes.value);
      this.form.endDateTime.setValue(x);
    }
  }

  endDateTimeChanged() {
    if (!this.form.startDateTime.value || !this.form.endDateTime.value) {
      return;
    }
    // Validation, end date must be in future --> change to message in future
    if (this.form.startDateTime.value > this.form.endDateTime.value) {
      this.form.durationMinutes.setValue('');
    } else {
      this.form.durationMinutes.setValue(countDuration(this.form.startDateTime.value, this.form.endDateTime.value));
    }
  }

  downloadAttachmentClicked(attachmentId: number) {
    this.submitting = true;
    this.updateForm();
    this.filesService.download(this.config.data.companyId, this.config.data.slotId, this.reservation.id, attachmentId)
      .subscribe({
        next: (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', this.reservation.attachments.find(att => att.id === attachmentId).name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.submitting = false;
          this.updateForm();
        },
        error: (error) => {
          this.alertService.error(error, { componentId: 'add-reservation-alert' });
          this.submitting = false;
          this.updateForm();
        }
      });
  }

  deleteAttachmentClicked(attachmentId: number) {
    const mboxData = this.createMessageBox(this.translateService.instant('Dialog.ConfirmDeleteAttachment'),
      this.translateService.instant('Dialog.DeletingAttachment',
        { attachmentName: this.reservation.attachments.find(att => att.id === attachmentId).name }));

    mboxData.result.subscribe(result => {
      if (result === 'yes') {
        this.submitting = true;
        this.updateForm();
        this.deleteAttachment(attachmentId);
      }
      mboxData.result.unsubscribe();
    });
    this.messageBoxService.show(mboxData);
  }

  cancelClicked() {
    this.ref.close('cancel');
  }

  deleteClicked() {
    this.updateForm();

    const mboxData = this.createMessageBox(this.translateService.instant('Dialog.ConfirmDeleteReservation'),
      this.translateService.instant('Dialog.DeletingReservation'));
    mboxData.result.subscribe(result => {
      if (result === 'yes') {
        this.deleteReservation();
      }
      mboxData.result.unsubscribe();
    });
    this.messageBoxService.show(mboxData);
  }

  saveClicked() {
    this.submitted = true;

    if (this.addReservationForm.invalid) {
      return;
    }
    const reservation = this.getReservationFromForm();

    if (this.config.data.type === 'add') {
      this.addReservation(reservation);
    } else if (this.config.data.type === 'edit') {
      if (reservation.periodicityDays > 0) {
        const mboxData = this.createMessageBox(this.translateService.instant('Dialog.ConfirmEditPeriodicReservation'),
          this.translateService.instant('Dialog.EditingPeriodicReservation'));
        mboxData.result.subscribe(result => {
          if (result === 'yes') {
            this.editReservation(reservation);
          }
          mboxData.result.unsubscribe();
        });
        this.messageBoxService.show(mboxData);
      } else {
        this.editReservation(reservation);
      }
    }
  }

  isCycleChanged() {
    if (this.form.isCycle.value) {
      this.form.periodicityDays.setValue(1);
    } else {
      this.form.controls.setValue(0);
    }
  }

  createMessageBox(title: string, message: string): MessageBoxData {
    const data = new MessageBoxData();
    data.title = title;
    data.message = message;
    return data;
  }

  getReservationFromForm(): Reservation {
    const reservation = new Reservation();
    reservation.id = this.config.data.reservationId;
    reservation.companyName = this.form.companyName.value;
    reservation.documentNumber = this.form.documentNumber.value;
    if (this.form.isCycle.value) {
      reservation.periodicityDays = this.form.periodicityDays.value;
    } else {
      reservation.periodicityDays = 0;
    }
    reservation.startDateTime = this.form.startDateTime.value;
    reservation.endDateTime = this.form.endDateTime.value;
    reservation.type = this.form.reservationType.value;
    reservation.vehicle = this.form.vehicle.value;
    reservation.status = this.form.status.value;
    reservation.unitsCount = this.form.unitsCount.value;
    reservation.remarks = this.form.remarks.value;

    return reservation;
  }

  setReservationValuesToForm() {
    if (!this.reservation) {
      return;
    }
    this.form.companyName.setValue(this.reservation.companyName);
    this.form.documentNumber.setValue(this.reservation.documentNumber);
    this.form.periodicityDays.setValue(this.reservation.periodicityDays);
    if (this.reservation.periodicityDays > 0) {
      this.form.isCycle.setValue(true);
    }
    this.form.startDateTime.setValue(this.reservation.startDateTime);
    this.form.endDateTime.setValue(this.reservation.endDateTime);
    this.form.vehicle.setValue(this.reservation.vehicle);
    this.form.durationMinutes.setValue(this.reservation.durationMinutes);
    this.form.unitsCount.setValue(this.reservation.unitsCount);
    this.form.remarks.setValue(this.reservation.remarks);

    if (this.availableTypes) {
      this.form.reservationType.setValue(this.availableTypes.find(s => s.id === this.reservation.type.id));
    }
    if (this.availableStatuses) {
      this.form.status.setValue(this.availableStatuses.find(s => s.id === this.reservation.status.id));
    }
  }

  setFormStyle() {
    const style = {};
    // tslint:disable-next-line: no-string-literal
    style['opacity'] = !this.isReadyToShow() ? 0.5 : 1;

    return style;
  }

  isReadyToShow(): boolean {
    return (!!this.config.data.reservationId === !!this.reservation) &&
      !!this.availableStatuses && !!this.availableTypes && !this.submitting;
  }

  updateForm() {
    if (this.isReadyToShow()) {
      this.config.closable = true;
      this.addReservationForm.enable();

      if (this.isDurationChangeDisabled) {
        this.addReservationForm.controls.endDateTime.disable();
        this.addReservationForm.controls.durationMinutes.disable();
      } else {
        this.addReservationForm.controls.endDateTime.enable();
        this.addReservationForm.controls.durationMinutes.enable();
      }
    } else {
      this.config.closable = false;
      this.addReservationForm.disable();
    }
  }
}
