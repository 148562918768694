<div class="row">
  <div class="col-xl-10 offset-xl-1 box-shadow jumbotron bg-light">

    <div class="row row-offcanvas row-offcanvas-right">
      <div class="bg-light border-right col-lg-2 col-md-3 col-sm-12 col-12">
        <div class="list-group list-group-flush" role="tablist">
          <a class="list-group-item list-group-item-action bg-light btn" routerLink="." routerLinkActive="activatedRoute" [routerLinkActiveOptions]="{exact: true}"
            translate>Button.CompanyList</a>
          <a class="list-group-item list-group-item-action bg-light btn" routerLink="add" routerLinkActive="activatedRoute"
            translate>Button.AddCompany</a>
        </div>
      </div>

      <div class="col-lg-10 col-md-9 col-sm-12 col-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>