<div class="m-1">
  <h2 class="form-title text-center pb-lg-3 pb-sm-1  mb-5 mb-xl-3" translate>Page.CompanyList</h2>

  <p-scrollPanel [style]="{width: '100%'}">
    <p-table [value]="companies" [lazy]="true" (onLazyLoad)="loadCompaniesLazy($event)" dataKey="id" [paginator]="true"
      [rows]="20" [totalRecords]="totalCompaniesCount" [loading]="loading" [style]="{'min-width':'500px' }" >
      <ng-template pTemplate="header">
        <tr>
          <th translate>Label.Name</th>
          <th translate>Label.CompanyActive</th>
          <th translate>Label.Options</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-company>
        <tr>
          <td>
            {{company.name}}
          </td>

          <td>
            <div class="col-6 align-self-center offset-5">
              <div *ngIf="company.isActive" translate>
                Label.Yes
              </div>
              <div *ngIf="!company.isActive" translate>
                Label.No
              </div>
            </div>
          </td>

          <td style="text-align:center">
            <button type="button" class="btn btn-sm btn-outline-dark pi pi-pencil"
              title="{{'Title.EditCompany' | translate}}" (click)="onRowEdit(company)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-scrollPanel>
</div>