<div class="row">
    <div class="col-xl-10 offset-xl-1 box-shadow jumbotron bg-light">
        <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.ModeratorLocationsManagement</h2>
        <p-treeTable [value]="nodes" [paginator]="true" [rows]="20" [totalRecords]="totalItemsCount" [loading]="loading"
            [lazy]="true" (onLazyLoad)="loadLocationsLazy($event)" (onNodeExpand)="onNodeExpand($event)">
            <ng-template pTemplate="caption">
                <div style="text-align:left">
                    <button type="button" enabled="false" class="btn btn-sm btn-outline-dark"
                        (click)="onAddLocationClicked()" translate>Button.AddLocation</button>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th translate>Label.Name</th>
                    <th translate>Label.IsActive</th>
                    <th translate>Label.MaxUnitsCount</th>
                    <th translate>Label.Options</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <tr>
                    <td>
                        <div style="display: flex; justify-content: space-between">

                        <p-treeTableToggler [rowNode]="rowNode" style="align-self: center; padding-top: 3px"></p-treeTableToggler>
                        <div style="display: flex; justify-content: space-between; width:100%">
                        <span style="align-self: center">
                            {{rowData.displayName}}
                        </span>
                        <button *ngIf="rowData.type=='location'" type="button" class="btn btn-sm btn-outline-dark mr-1"
                            title="{{'Title.EditLocation' | translate}}" (click)="onAddRow(rowData)">Dodaj
                            magazyn</button>
                        <button *ngIf="rowData.type=='warehouse'" type="button" class="btn btn-sm btn-outline-dark mr-1"
                            title="{{'Title.EditWarehouse' | translate}}" (click)="onAddRow(rowData)">Dodaj
                            slot</button>
                        </div>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="rowData.object.isActive">
                            {{'Label.Yes' | translate}}
                        </div>
                        <div *ngIf="!rowData.object.isActive">
                            {{'Label.No' | translate}}
                        </div>
                    </td>
                    <td>
                        {{rowData.object.maxUnitsCount}}
                    </td>
                    <td style="text-align:center">
                        <button type="button" enabled="false" class="btn btn-sm btn-outline-dark mr-1 pi pi-pencil"
                            title="{{'Title.Edit' | translate}}" (click)="onEditRow(rowData)"></button>
                        <button type="button" enabled="false" class="btn btn-sm btn-outline-dark pi pi-trash"
                            title="{{'Title.Delete' | translate}}" (click)="onDeleteRow(rowData)"></button>
                    </td>
                </tr>
            </ng-template>
        </p-treeTable>
    </div>
</div>