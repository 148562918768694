<div class="row">
    <app-slots-filters #filters style="width:100%" (filterChanged)="onFilterChanged($event)"></app-slots-filters>
</div>
<p-carousel #carousel [value]="slots" (onPage)="onPageChanged($event)">
    <ng-template let-slot pTemplate="item">
        <div class="m-0 m-sm-1 m-md-1 m-lg-2">
            <app-slot (slotChanged)="onSlotChanged($event)" [slotData]="slot" [date]="selectedDate"
                [companyId]="selectedCompany.company.id"></app-slot>
        </div>
    </ng-template>
</p-carousel>
