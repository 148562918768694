import { MilisecondsToMinutes } from '../../../helper/duration-helpers';
import { IConvertable } from '../../../helper/interfaces';
import { SlotItem } from './slot-item/slot-item.model';

export class Slot implements IConvertable<any> {

    id: number;
    name: string;
    locationCode: string;
    warehouseName: string;
    isActive: boolean;
    items: SlotItem[];
    maxReservationDuration: number;
    timeInterval: number;

    lastRefreshDateTime: Date;

    get timeIntervalMinutes(): number {
        return MilisecondsToMinutes(this.timeInterval);
    }

    get fullSlotName(): string {
        return this.locationCode + ' / ' + this.warehouseName + ' / ' + this.name;
    }

    convertFrom(input: any): this {
        this.id = input.id;
        this.name = input.name;
        this.locationCode = input.locationCode;
        this.warehouseName = input.warehouseName;
        this.isActive = input.isActive;
        this.maxReservationDuration = input.maxReservationDuration;
        this.timeInterval = input.timeInterval;

        return this;
    }
}
