<div class="row m-2">
    <div class="col-12 col-sm-12 col-xl-10 offset-xl-1 box-shadow jumbotron bg-light">
        <h2 class="form-title text-center pb-lg-3 pb-sm-1" translate>Page.AccountPreview</h2>
        <form [formGroup]="editUserForm" (ngSubmit)="onEditUser()" class="row">

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mt-4">
                <h5 class="form-title pb-lg-3 pb-sm-1" translate>Label.UserData</h5>

                <div class="form-group">
                    <label for="firstName" translate>Label.FirstName</label>
                    <input type="text" formControlName="firstName" class="form-control"
                        placeholder="{{'Label.FirstName' | translate}}" required
                        [ngClass]="{ 'is-invalid': submitted && form.firstName.errors }" />
                    <app-form-error-message *ngIf="submitted" [control]="form.firstName"></app-form-error-message>
                </div>

                <div class="form-group">
                    <label for="lastName" translate>Label.LastName</label>
                    <input type="text" formControlName="lastName" class="form-control"
                        placeholder="{{'Label.LastName' | translate}}" required
                        [ngClass]="{ 'is-invalid': submitted && form.lastName.errors }" />
                    <app-form-error-message *ngIf="submitted" [control]="form.lastName"></app-form-error-message>
                </div>

                <div class="form-group">
                    <label for="email" translate>Label.Email</label>
                    <input type="text" formControlName="email" class="form-control" />
                </div>

                <div class="form-group">
                    <label for="language" translate>Label.Language</label>
                    <p-dropdown [autoDisplayFirst]="false" [options]="supportedLanguages" formControlName="language"
                        [style]="{'min-width':'0', 'width': '100%'}" optionLabel="displayName"
                        [ngClass]="{ 'is-invalid': submitted && form.role.errors }" required>
                    </p-dropdown>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mt-4">
                <h5 class="form-title pb-lg-3 pb-sm-1" translate>Label.AssignedCompanies</h5>

                <p-scrollPanel [style]="{'width': '100%'}">
                    <p-table [value]="companies" dataKey="company.id" editMode="row" [loading]="loading"
                        [style]="{'min-width':'430px', 'min-height':'300px' }">
                        <ng-template pTemplate="header">
                            <tr>
                                <th translate>Label.CompanyName</th>
                                <th translate>Label.Role</th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-company let-editing="editing">
                            <tr [pEditableRow]="company">
                                <td>
                                    {{company.company?.name}}
                                </td>

                                <td>
                                    {{company.role?.name}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-scrollPanel>
            </div>

            <div class="form-group mt-4 ml-3">
                <button [disabled]="submiting || loading" type="submit" class="btn btn-outline-dark" translate>
                    <span *ngIf="submiting" class="spinner-border spinner-border-sm mr-1"></span>
                    <a translate>Button.SaveChanges</a>
                </button>
            </div>
        </form>
    </div>
</div>