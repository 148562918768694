import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { MessageBoxData } from './message-box.model';

@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {

  private subject = new Subject<any>();

  constructor() { }

  onShow(): Observable<any> {
    return this.subject.asObservable();
  }

  show(messageBoxData: MessageBoxData) {
    this.subject.next(messageBoxData);
  }
}
