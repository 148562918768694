import {Router, ActivatedRoute} from '@angular/router';
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

import {Slot} from '../../shared/models/base';
import {
  countDuration, getMinutesToMidnight, addMinutes,
  getMinutesFromMidnight, max, min, addDays
} from '../../helper/date-time-helpers';
import {ReservationAddComponent} from './../reservation-add/reservation-add.component';
import {AlertService} from 'src/app/alert';
import {ReservationSlotItem} from '../../shared/models/base/slot-item/reservation-slot-item.model';
import {LockedSlotItem} from '../../shared/models/base/slot-item/locked-slot-item.model';

// tslint:disable: no-string-literal

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.css'],
  providers: [DialogService]
})
export class SlotComponent implements OnInit {

  @Input() slotData: Slot;
  @Input() date: Date;
  @Input() companyId: number;

  @Output() slotChanged: EventEmitter<number> = new EventEmitter();

  standardHeightVal = 7;

  constructor(private dialogService: DialogService,
              private translateService: TranslateService,
              private alertService: AlertService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
  }

  // showReservationPreviewDialog(reservationId: number): DynamicDialogRef {
  //   const previewReservation = this.dialogService.open(ReservationPreviewComponent, {
  //     header: this.translateService.instant('Dialog.PreviewReservation'),
  //     data: {
  //       reservationId: reservationId,
  //       slotId: this.slotData.id,
  //       companyId: this.companyId
  //     }
  //   });
  //   previewReservation.onClose.subscribe((returnValue) => {
  //     if (returnValue.edit) {
  //       this.handleEditingReservation(reservationId);
  //     } else if (returnValue.delete) {

  //     }
  //   });

  //   return previewReservation;
  // }

  reservationClicked(reservation: ReservationSlotItem) {
    if (reservation.canPreview) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {reservationId: reservation.id, operation: 'edit'},
        queryParamsHandling: 'merge'
      });
      this.handleEditingReservation(reservation.id, reservation.canModify);
    }
  }

  freeTimeClicked(freeTime?: Date) {
    console.log('free time clicked');
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {operation: 'add'},
      queryParamsHandling: 'merge'
    });
    this.handleAddingReservation(freeTime);
  }

  handleAddingReservation(dateTime: Date) {
    const addReservationDialog = this.createReservationDialog(this.translateService.instant('Dialog.AddReservation'), 'add', dateTime);

    addReservationDialog.onClose.subscribe((returnVal) => {
      if (returnVal && returnVal === 'add') {
        this.slotChanged.emit(this.slotData.id);
        this.alertService.success(this.translateService.instant('Info.ReservationAdded'),
          {keepAfterRouteChange: true, autoClose: true, sticky: true});
      }
    });
  }

  handleEditingReservation(reservationId: number, canModify: boolean) {
    const editReservationDialog = this.createReservationDialog(this.translateService.instant('Dialog.EditReservation'),
      'edit', null, reservationId, canModify);

    editReservationDialog.onClose.subscribe((returnVal: any) => {
      if (returnVal && returnVal !== 'cancel') {
        this.slotChanged.emit(this.slotData.id);
        if (returnVal === 'edit') {
          this.alertService.success(this.translateService.instant('Info.ReservationEdited'),
            {keepAfterRouteChange: true, autoClose: true, sticky: true});
        } else if (returnVal === 'delete') {
          this.alertService.success(this.translateService.instant('Info.ReservationDeleted'),
            {keepAfterRouteChange: true, autoClose: true, sticky: true});
        }
      }
    });
  }

  createReservationDialog(header: string, type: string, startDateTime?: Date, reservationId?: number,
                          canModify?: boolean): DynamicDialogRef {
    return this.dialogService.open(ReservationAddComponent, {
      header,
      closable: !reservationId,
      data: {
        companyId: this.companyId,
        slotInterval: this.slotData.timeIntervalMinutes,
        slotId: this.slotData.id,
        maxReservationuration: this.slotData.maxReservationDuration,
        startDateTime,
        reservationId,
        type
      }
    });
  }

  calculateHeight(index: number): string {
    const selectedReservation = this.slotData.items[index];

    if (!selectedReservation) {
      return '0em';
    }

    if (selectedReservation.startDateTime.getDate() < this.date.getDate()) {
      return getMinutesFromMidnight(selectedReservation.endDateTime) / this.slotData.timeIntervalMinutes * this.standardHeightVal + 'em';
    } else if (selectedReservation.endDateTime.getDate() > this.date.getDate()) {
      return getMinutesToMidnight(selectedReservation.startDateTime) / this.slotData.timeIntervalMinutes * this.standardHeightVal + 'em';
    } else {
      return selectedReservation.durationMinutes / this.slotData.timeIntervalMinutes * this.standardHeightVal + 'em';
    }
  }

  createTimeArray(durationMinutes: number, startTime: Date): Array<Date> {
    const array = new Array<Date>(durationMinutes / this.slotData.timeIntervalMinutes);

    for (let i = 0; i < array.length; i++) {
      array[i] = addMinutes(startTime, this.slotData.timeIntervalMinutes * i);
    }
    return array;
  }

  getFreeTimeBetweenReservations(index: number, afterReservation: boolean = false, slotData?): Array<any> {
    if (slotData?.name === 'Klatka B') {
      /*      console.log(slotData);
            console.log(index);*/
    }
    if (index === -1) { // Gdy slot jest pusty
      return this.createTimeArray(1440, this.date); // Liczba minut w dobie
    }

    const selectedReservation = this.slotData.items[index];

    if (index === 0 && !afterReservation) { // Dla pierwszej awizacji liczymy czas od północy
      if (selectedReservation.startDateTime.getDate() < this.date.getDate() ||
        selectedReservation.startDateTime.getMonth() < this.date.getMonth() ||
        selectedReservation.startDateTime.getFullYear() < this.date.getFullYear()) { // Jeśli awizacja zaczęła się poprzedniego dnia
        return new Array(0);
      } else {
        return this.createTimeArray(getMinutesFromMidnight(selectedReservation.startDateTime), this.date);
      }
    } else if (index === this.slotData.items.length - 1 && afterReservation) { // Dla ostatniej liczymy czas do północy
      if (selectedReservation.endDateTime.getDate() === this.date.getDate()) {
        return this.createTimeArray(getMinutesToMidnight(selectedReservation.endDateTime),
          selectedReservation.endDateTime);
      } else {
        return new Array(0);
      }
    } else { // Dla innych czas pomiędzy awizacjami
      const previousReservation = this.slotData.items[index - 1];
      return this.createTimeArray(countDuration(previousReservation.endDateTime, selectedReservation.startDateTime),
        previousReservation.endDateTime);
    }
  }

  getLockedItemTimeSlots(index: number): Array<any> {
    const selecteditem = this.slotData.items[index];

    const startDate = max(this.date, selecteditem.startDateTime);
    const endDate = min(addDays(this.date, 1), selecteditem.endDateTime);

    return this.createTimeArray(countDuration(startDate, endDate), startDate);
  }

  getFreeTimeStyle(): any {
    const style = {};
    style['height'] = this.standardHeightVal + 'em';
    style['cursor'] = 'pointer';
    return style;
  }

  getLockedTimeStyle(index: number): any {
    const style = {};
    if (this.slotData.items[index].canModify) {
      style['height'] = this.standardHeightVal + 'em';
      style['cursor'] = 'pointer';
    } else {
      style['height'] = this.calculateHeight(index);
    }
    style['background-size'] = this.standardHeightVal + 'em ' + this.standardHeightVal + 'em';
    return style;
  }

  getReservationStyle(index: number): any {
    const style = {};
    style['height'] = this.calculateHeight(index);
    style['display'] = 'block';
    if ((this.slotData.items[index] as ReservationSlotItem).canPreview) {
      style['cursor'] = 'pointer';
    }

    return style;
  }

  getLockedSlotItem(index: number): LockedSlotItem {
    return this.slotData.items[index] as LockedSlotItem;
  }
}
