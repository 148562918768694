import { IConvertable } from '../../../helper/interfaces';
import { Slot, User } from '../base';
import { CompanyRole } from '../base/companyRole.model';

export class UserExt extends User implements IConvertable<any> {
  public companies: CompanyRole[];
  public slots: Slot[];

  constructor() {
    super();
  }

  convertFrom(input: any): this {
    Object.assign(this, input);

    if (input.companies !== undefined) {
      this.companies = input.companies.map(uc => new CompanyRole().convertFrom(uc));
    }
    if (input.slots !== undefined) {
      this.slots = input.slots.map(s => new Slot().convertFrom(s));
    }

    return this;
  }
}
