import { IConvertable } from '../../../helper/interfaces';
import { UserExt } from '../extended';

export class UsersExtLazy implements IConvertable<any> {

  public users: UserExt[];
  public totalItemsCount: number;

  convertFrom(input: any): this {
    Object.assign(this, input);

    if (input.items !== undefined) {
      this.users = input.items.map(c => new UserExt().convertFrom(c));
    }

    return this;
  }
}
