<app-base-popup (cancelClicked)="cancelClicked()" (saveClicked)="saveClicked()">
    <form [formGroup]="warehouseForm">
        <div class="form-group">
            <label for="name" translate>Label.Name</label>
            <input formControlName="name" class="form-control" placeholder="{{'Label.Name' | translate}}" required
                [ngClass]="{ 'is-invalid': submitted && form.name.errors }" />
            <app-form-error-message *ngIf="submitted" [control]="form.name"></app-form-error-message>
        </div>

        <div class="form-group mt-1">
            <label for="maxUnitsCount" translate>Label.MaxUnitsCount</label>
            <input type="number" formControlName="maxUnitsCount" class="form-control"
                placeholder="{{'Label.MaxUnitsCount' | translate}}"
                [ngClass]="{ 'is-invalid': submitted && form.maxUnitsCount.errors }" />
            <app-form-error-message *ngIf="submitted" [control]="form.maxUnitsCount"></app-form-error-message>
        </div>

        <div class="form-check ml-3">
            <input class="form-check-input" type="checkbox" formControlName="isActive" id='isActiveCb'
                [ngClass]="{ 'is-invalid': submitted && form.isActive.errors }">
            <label class="form-check-label" for="isActiveCb" translate>
                Label.Active
            </label>
        </div>
    </form>
</app-base-popup>