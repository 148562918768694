import { ReservationStatus } from './reservation-status.model';
import { IConvertable } from '../../../../helper/interfaces';
import { countDuration } from '../../../../helper/date-time-helpers';
import { ReservationType } from './reservation-type.model';
import { Attachment } from './attachment.model';

export class Reservation implements IConvertable<any> {

    id: number;
    startDateTime: Date;
    endDateTime: Date;
    companyName: string;
    documentNumber: string;
    vehicle: string;
    periodicityDays: number;
    type: ReservationType;
    status: ReservationStatus;
    unitsCount: number;
    remarks: string;
    attachments: Attachment[];

    canPreview: boolean;
    canModify: boolean;

    get durationMinutes(): number {
        return countDuration(this.startDateTime, this.endDateTime);
    }

    convertFrom(input: any): this {
        this.id = input.id;
        this.startDateTime = new Date(input.startDateTime);
        this.endDateTime = new Date(input.endDateTime);
        this.companyName = input.companyName;
        this.documentNumber = input.documentNumber;
        this.vehicle = input.vehicle;
        this.unitsCount = input.unitsCount;
        this.remarks = input.remarks;
        this.periodicityDays = input.periodicityDays;

        this.canModify = input.canModify;
        this.canPreview = input.canPreview;

        if (input.status) {
            this.status = new ReservationStatus().convertFrom(input.status);
        }
        if (input.type) {
            this.type = new ReservationType().convertFrom(input.type);
        }
        if (input.attachments) {
            this.attachments = [];
            input.attachments.map(att => this.attachments.push(new Attachment().convertFrom(att)));
        }
        return this;
    }
}
