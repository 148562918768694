import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { Language, User } from '../../../shared/models/base';
import { CoreService } from 'src/app/core/core.service';

@Component({
  selector: 'app-user-management-add-user',
  templateUrl: './user-management-add-user.component.html',
  styleUrls: ['./user-management-add-user.component.css']
})
export class UserManagementAddUserComponent implements OnInit {

  supportedLanguages: Language[];

  public submitted = false;
  public addUserForm: FormGroup;

  get form() {
    return this.addUserForm.controls;
  }

  constructor(private formBuilder: FormBuilder,
              private ref: DynamicDialogRef,
              private coreService: CoreService) { }

  ngOnInit() {
    this.addUserForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      isAdmin: new FormControl(false, [Validators.required]),
      language: new FormControl('', Validators.required)
    });

    this.coreService.supportedLanguages$.subscribe({
      next: (next) => {
        this.supportedLanguages = next;
        this.form.language.setValue(next[0]);
      }
    }).unsubscribe();
  }

  cancelClicked() {
    this.ref.close();
  }

  saveClicked() {
    this.submitted = true;

    if (this.addUserForm.invalid) {
      return;
    }
    const u = new User();
    u.email = this.form.email.value;
    u.isAdmin = this.form.isAdmin.value;
    u.languageId = this.form.language.value.id;

    this.ref.close(u);
  }
}
