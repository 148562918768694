import { IConvertable } from '../../../../helper/interfaces';

export class Translation implements IConvertable<any> {

    public displayName: string;
    public languageId: number;

    convertFrom(input: any): this {
        this.displayName = input.displayName;
        this.languageId = input.languageId;

        return this;
    }
}
