<p-card>
    <p-header>
        <div [ngStyle]="setHeaderStyle()" style="height: 19px;">
            <div class="headerPart" style="margin-left: 4px;">
                {{reservation.startDateTime | date:'HH:mm'}} - {{reservation.endDateTime | date:'HH:mm'}}
            </div>

            <div style="float: right; margin-right: 2px;" class="headerPart">
                <i class="pi pi-paperclip headerIcon" [hidden]="!reservation.isAttachmentExists"></i>

                <ng-container *ngIf="reservation.periodicityDays > 0">
                    <i class="pi pi-replay headerIcon"></i>
                    {{reservation.periodicityDays}}
                </ng-container>
            </div>
        </div>
    </p-header>
    <div class="cardContent" style="overflow:hidden">
        <div *ngIf="!reservation.canPreview">
            {{"Label.HiddenReservation" | translate}}
        </div>
        <div>
            <b>{{reservation.typeName}}</b>
        </div>

        <div *ngIf="reservation.companyName">
            {{"Label.Company" | translate}}: {{reservation.companyName}}
        </div>

        <div *ngIf="reservation.documentNumber">
            {{"Label.Document" | translate}}: {{reservation.documentNumber}}
        </div>

        <div *ngIf="reservation.vehicle">
            {{"Label.Vehicle" | translate}}: {{reservation.vehicle}}
        </div>
    </div>
</p-card>