import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DialogService } from 'primeng/dynamicdialog';

import { CompanyService, UserService } from '../../../shared/services';
import { CompanyExt } from '../../../shared/models/extended';
import { Role } from '../../../shared/models/base';
import { UserRole } from '../../../shared/models/base/userRole.model';
import { AlertService } from 'src/app/alert';
import { MessageBoxService, MessageBoxData } from 'src/app/message-box';
import { CompanyAddUserPopupComponent } from '../company-management-add-user-popup/company-add-user-popup.component';

@Component({
  selector: 'app-company-management-edit',
  templateUrl: './company-management-edit.component.html',
  styleUrls: ['./company-management-edit.component.css'],
  providers: [DialogService]
})
export class CompanyManagementEditComponent implements OnInit {

  company: CompanyExt;
  availableRoles: Role[];

  editCompanyForm: FormGroup;

  loading: boolean;
  submiting: boolean;
  submitted: boolean;

  get form() {
    return this.editCompanyForm.controls;
  }

  constructor(private alertService: AlertService,
              private formBuilder: FormBuilder,
              private companyService: CompanyService,
              private userService: UserService,
              private translateService: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private dialogService: DialogService,
              private messageBoxService: MessageBoxService) { }

  ngOnInit() {
    this.editCompanyForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      isActive: new FormControl(false, Validators.required),
    });

    if (this.route.snapshot.paramMap.has('companyId')) {
      const companyId = Number(this.route.snapshot.paramMap.get('companyId'));

      this.loading = true;

      this.companyService.getCompany(companyId)
        .subscribe({
          next: (next) => {
            this.company = next;
            this.form.name.setValue(next.name);
            this.form.isActive.setValue(next.isActive);
          },
          error: (error) => {
            this.alertService.error(error, true);
            this.loading = false;
          },
          complete: () => this.loading = false
        });

      this.userService.getRoles().subscribe({
        next: (next) => this.availableRoles = next,
        error: (error) => this.alertService.error(error)
      });
    }
  }

  onEditCompany() {
    this.submitted = true;
    this.alertService.clear();

    if (this.editCompanyForm.invalid) {
      return;
    }

    this.submiting = true;

    this.company.name = this.form.name.value;
    this.company.isActive = this.form.isActive.value;

    this.companyService.patchCompany(this.company)
      .subscribe({
        next: () => {
          this.translateService.get('Info.CompanyEditedSuccessfully')
            .subscribe(msg => this.alertService.success(msg, { keepAfterRouteChange: true, autoClose: true }));
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        },
        error: (error) => {
          this.alertService.error(error);
          this.submiting = false;
        }
      });
  }

  onUserDeleteClicked(user: UserRole) {
    const data = new MessageBoxData();
    data.title = this.translateService.instant('Dialog.ConfirmDeletingUser');
    data.message = this.translateService.instant('Dialog.DeletingUserFromList', { email: user.user.email });
    data.result.subscribe((result) => {
      if (result === 'yes') {
        this.company.users = this.company.users.filter(u => u !== user);
      }
      data.result.unsubscribe();
    });
    this.messageBoxService.show(data);
  }

  showAddUserDialog() {
    const addUserDialog = this.dialogService.open(CompanyAddUserPopupComponent, {
      header: this.translateService.instant('Dialog.AddUser'),
    });

    addUserDialog.onClose.subscribe((userRole: UserRole) => {
      if (userRole) {
        this.addUser(userRole);
      }
    });
  }

  addUser(newUser: UserRole) {
    if (newUser != null && newUser !== undefined) {
      if (this.company.users.find(u => u.user.email === newUser.user.email)) {
        this.alertService.warning(this.translateService.instant('Info.UserAlreadyExistsOnList'));
      } else {
        this.company.users.push(newUser);
      }
    }
  }
}
