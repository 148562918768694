import { WarehouseService } from './../../shared/services/warehouse.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, LazyLoadEvent, TreeNode } from 'primeng';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/alert';
import { CoreService } from 'src/app/core/core.service';
import { CompanyRole } from 'src/app/shared/models/base/companyRole.model';
import { LocationService, SlotService } from 'src/app/shared/services';
import { AddLocationComponent } from '../_popups/add-location/add-location.component';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '../../shared/models/base/location.model';
import { MessageBoxData, MessageBoxService } from 'src/app/message-box';
import { AddWarehouseComponent } from '../_popups/add-warehouse/add-warehouse.component';
import { Slot, Warehouse } from 'src/app/shared/models/base';
import { AddSlotComponent } from '../_popups/add-slot/add-slot.component';

@Component({
  selector: 'app-moderator-locations-management',
  templateUrl: './moderator-locations-management.component.html',
  styleUrls: ['./moderator-locations-management.component.css'],
  providers: [DialogService]
})
export class ModeratorLocationsManagementComponent implements OnInit, OnDestroy {

  selectedCompanySubscription: Subscription;
  selectedCompany: CompanyRole;

  totalItemsCount: number;

  nodes: TreeNode[];

  loading: boolean;

  constructor(public coreService: CoreService,
              private alertService: AlertService,
              private slotSevice: SlotService,
              private locationService: LocationService,
              private warehouseService: WarehouseService,
              private messageBoxService: MessageBoxService,
              private dialogService: DialogService,
              private translateService: TranslateService) { }

  ngOnInit() {
    this.selectedCompanySubscription = this.coreService.selectedCompany$.subscribe(company => {
      this.selectedCompany = company;
      if (this.selectedCompany) {
        this.getLocations(0, 20);
      }
    });
  }

  ngOnDestroy(): void {
    this.selectedCompanySubscription.unsubscribe();
  }

  onNodeExpand(event) {
    if (event.node.data.type === 'location') {
      this.getWarehouses(event.node);
    } else if (event.node.data.type === 'warehouse') {
      this.getSlots(event.node);
    }
  }

  loadLocationsLazy(event: LazyLoadEvent) {
    this.getLocations(event.first, event.rows);
  }

  getLocations(skip: number, take: number) {
    if (!this.selectedCompany) {
      return;
    }

    this.loading = true;

    this.locationService.getLocations(this.selectedCompany.company.id, null, skip, take).subscribe({
      next: (next) => {
        this.nodes = next.locations.map(loc => ({ data: { displayName: loc.code, object: loc, type: 'location' }, leaf: false }));
        this.totalItemsCount = next.totalItemsCount;
        this.loading = false;
      },
      error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }

  getWarehouses(node: TreeNode) {
    this.loading = true;

    this.warehouseService.getWarehouses(this.selectedCompany.company.id, node.data.object.id, null, null, null).subscribe({
      next: (next) => {
        node.children = next.warehouses
          .map(war => ({ data: { displayName: war.name, locationId: node.data.object.id, object: war, type: 'warehouse' }, leaf: false }));

        this.nodes = [...this.nodes];
        this.loading = false;
      },
      error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }

  getSlots(node: TreeNode) {
    this.loading = true;

    this.slotSevice.getSlots(this.selectedCompany.company.id, null, node.data.locationId, node.data.object.id)
      .subscribe({
        next: (next) => {
          node.children = next.map(slot => ({
            data: {
              displayName: slot.name,
              object: slot,
              locationId: node.data.locationId,
              warehouseId: node.data.object.id,
              type: 'slot'
            }, leaf: true
          }));

          this.nodes = [...this.nodes];
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  onAddLocationClicked() {
    const addLocationDialog = this.dialogService.open(AddLocationComponent, {
      header: this.translateService.instant('Dialog.AddLocation'),
    });

    addLocationDialog.onClose.subscribe((location: Location) => {
      if (location) {
        this.nodes = [...this.nodes, { data: { displayName: location.code, object: location, type: 'location' }, leaf: false }];
        this.totalItemsCount += 1;
      }
    });
  }

  onEditRow(nodeData: any) {
    if (nodeData.type === 'location') {
      const editLocationDialog = this.dialogService.open(AddLocationComponent, {
        header: this.translateService.instant('Dialog.EditLocation'),
        data: {
          location: nodeData.object,
        }
      });

      editLocationDialog.onClose.subscribe((location: Location) => {
        if (location) {
          const locationNodeIdx = this.nodes.findIndex(node => node.data.object.id === nodeData.object.id);
          this.nodes[locationNodeIdx] = {
            data:
            {
              displayName: location.code,
              locationId: nodeData.object.id,
              object: location,
              type: 'location'
            }, leaf: false
          };
          this.nodes = [... this.nodes];
        }
      });
    } else if (nodeData.type === 'warehouse') {
      const editWarehouseDialog = this.dialogService.open(AddWarehouseComponent, {
        header: this.translateService.instant('Dialog.EditWarehouse'),
        data: {
          locationId: nodeData.locationId,
          warehouse: nodeData.object
        }
      });

      editWarehouseDialog.onClose.subscribe((warehouse: Warehouse) => {
        if (warehouse) {
          const locationNodeIdx = this.nodes.findIndex(node => node.data.object.id === nodeData.locationId);
          const locationNode = this.nodes[locationNodeIdx];

          const warehouseNodeIdx = locationNode.children.findIndex(node => node.data.object.id === warehouse.id);
          locationNode.children[warehouseNodeIdx] = {
            data:
            {
              displayName: warehouse.name,
              locationId: nodeData.locationId,
              object: warehouse,
              type: 'warehouse'
            }, leaf: false
          };
          this.nodes = [... this.nodes];
        }
      });
    } else if (nodeData.type === 'slot') {
      const editSlotDialog = this.dialogService.open(AddSlotComponent, {
        header: this.translateService.instant('Dialog.EditSlot'),
        data: {
          slot: nodeData.object
        }
      });
      editSlotDialog.onClose.subscribe((slot: any) => {
        if (slot) {
          const locationNodeIdx = this.nodes.findIndex(node => node.data.object.id === nodeData.locationId);
          const locationNode = this.nodes[locationNodeIdx];

          const warehouseNodeIdx = locationNode.children.findIndex(node => node.data.object.id === nodeData.warehouseId);
          const warehouseNode = locationNode.children[warehouseNodeIdx];

          const slotNodeIdx = warehouseNode.children.findIndex(node => node.data.object.id === slot.id);

          const newSlot = new Slot();
          newSlot.id = slot.id;
          newSlot.name = slot.name;
          newSlot.isActive = slot.isActive;

          warehouseNode.children[slotNodeIdx] = {
            data: {
              displayName: newSlot.name,
              object: newSlot,
              locationId: nodeData.locationId,
              warehouseId: nodeData.object.id,
              type: 'slot'
          }, leaf: true };
          this.nodes = [... this.nodes];
        }
      });
    }
  }

  onDeleteRow(nodeData: any) {
    const mbData = new MessageBoxData();

    if (nodeData.type === 'location') {
      mbData.title = this.translateService.instant('Dialog.ConfirmDeletingLocation');
      mbData.message = this.translateService.instant('Dialog.DeletingLocation');
    } else if (nodeData.type === 'warehouse') {
      mbData.title = this.translateService.instant('Dialog.ConfirmDeletingWarehouse');
      mbData.message = this.translateService.instant('Dialog.DeletingWarehouse');
    } else if (nodeData.type === 'slot') {
      mbData.title = this.translateService.instant('Dialog.ConfirmDeletingSlot');
      mbData.message = this.translateService.instant('Dialog.DeletingSlot');
    }

    mbData.result.subscribe((result) => {
      if (result === 'yes') {
        this.deleteRow(nodeData);
      }
      mbData.result.unsubscribe();
    });
    this.messageBoxService.show(mbData);
  }

  deleteRow(nodeData: any) {
    this.loading = true;

    if (nodeData.type === 'location') {
      this.locationService.delete(this.selectedCompany.company.id, nodeData.object.id).subscribe({
        next: (next) => {
          this.nodes = this.nodes.filter(n => n.data.object.id !== nodeData.object.id);
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    } else if (nodeData.type === 'warehouse') {
      this.warehouseService.delete(this.selectedCompany.company.id, nodeData.locationId, nodeData.object.id).subscribe({
        next: (next) => {
          const locationNodeIdx = this.nodes.findIndex(node => node.data.object.id === nodeData.locationId);
          const locationNode = this.nodes[locationNodeIdx];
          locationNode.children = locationNode.children.filter(childNode => childNode.data.object.id !== nodeData.object.id);
          this.nodes[locationNodeIdx] = { ...locationNode };
          this.nodes = [... this.nodes];
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    } else if (nodeData.type === 'slot') {
      this.slotSevice.delete(this.selectedCompany.company.id, nodeData.object.id).subscribe({
        next: (next) => {
          const locationNodeIdx = this.nodes.findIndex(node => node.data.object.id === nodeData.locationId);
          const locationNode = this.nodes[locationNodeIdx];

          const warehouseNodeIdx = locationNode.children.findIndex(node => node.data.object.id === nodeData.warehouseId);
          const warehouseNode = locationNode.children[warehouseNodeIdx];
          warehouseNode.children = warehouseNode.children.filter(childNode => childNode.data.object.id !== nodeData.object.id);

          locationNode.children[warehouseNodeIdx] = { ...warehouseNode };
          this.nodes[locationNodeIdx] = { ...locationNode };
          this.nodes = [... this.nodes];
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    }
  }

  onAddRow(nodeData: any) {
    if (nodeData.type === 'location') { // Jak naciśniemy na lokalizację to chcemy dodać magazyn do tej lokalizacji
      const addWarehouseDialog = this.dialogService.open(AddWarehouseComponent, {
        header: this.translateService.instant('Dialog.AddWarehouse'),
        data: {
          locationId: nodeData.object.id,
        }
      });

      addWarehouseDialog.onClose.subscribe((warehouse: Warehouse) => {
        if (warehouse) {
          const locationNodeIdx = this.nodes.findIndex(node => node.data.object.id === nodeData.object.id);
          const locationNode = this.nodes[locationNodeIdx];
          locationNode.children = [...locationNode.children,
          { data: { displayName: warehouse.name, locationId: nodeData.object.id, object: warehouse, type: 'warehouse' }, leaf: false }];
          this.nodes[locationNodeIdx] = { ...locationNode };
          this.nodes = [... this.nodes];
        }
      });
    } else if (nodeData.type === 'warehouse') { // Jak naciśniemy na magazyn to chcemy dodać slot do wybranego magazynu
      const addSlotDialog = this.dialogService.open(AddSlotComponent, {
        header: this.translateService.instant('Dialog.AddSlot'),
        data: {
          locationId: nodeData.locationId,
          warehouseId: nodeData.object.id
        }
      });

      addSlotDialog.onClose.subscribe((slot: any) => {
        if (slot) {
          const locationNodeIdx = this.nodes.findIndex(node => node.data.object.id === nodeData.locationId);
          const locationNode = this.nodes[locationNodeIdx];

          const warehouseNodeIdx = locationNode.children.findIndex(node => node.data.object.id === slot.warehouseId);
          const warehouseNode = locationNode.children[warehouseNodeIdx];

          const newSlot = new Slot();
          newSlot.id = slot.id;
          newSlot.name = slot.name;
          newSlot.isActive = slot.isActive;

          warehouseNode.children = [...warehouseNode.children,
          {
            data:
            {
              displayName: newSlot.name,
              object: newSlot,
              type: 'slot'
            }, leaf: true
          }];
          this.nodes = [... this.nodes];
        }
      });
    }
  }
}
