import { UserRoleOutput } from './userRole.output.model';
import { IConvertable } from '../../../helper/interfaces';
import { CompanyExt } from '../extended';

export class CompanyEdit implements IConvertable<CompanyExt> {

  name: string;
  isActive: boolean;
  users: UserRoleOutput[];

  convertFrom(input: CompanyExt): this {
    Object.assign(this, input);

    if (input.users !== undefined && input.users !== null) {
      this.users = input.users.map(ur => new UserRoleOutput().convertFrom(ur));
    }
    return this;
  }
}
