import { IConvertable } from '../../../helper/interfaces';

export class User implements IConvertable<any> {
  public id: string;
  public email: string;
  public firstName: string;
  public lastName: string;

  public languageId: number;
  public isAdmin: boolean;
  public isConfirmed: boolean;

  public get $firstAndLastName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  convertFrom(input: any): this {

    this.id = input.id;
    this.email = input.email;
    this.firstName = input.firstName;
    this.lastName = input.lastName;

    if (input.language) {
      this.languageId = input.language.id;
    } else {
      this.languageId = input.languageId;
    }
    this.isAdmin = input.isAdmin;
    this.isConfirmed = input.isConfirmed;

    return this;
  }
}
