import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createHttpParams } from 'src/app/helper/http-params-creator-helpers';
import { environment } from 'src/environments/environment';
import { LocationsLazy } from '../models/lazy';
import { LocationOutput } from '../models/output/location.output.model';
import {Location} from '../models/base';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl + '/companies';

  getLocations(companyId: number, isAvailable?: boolean, skip?: number, take?: number): Observable<LocationsLazy> {
    const params = createHttpParams({ skip, take, isAvailable });

    return this.http.get(this.baseUrl + '/' + companyId + '/locations', { params })
      .pipe(map(locationsLazy => new LocationsLazy().convertFrom(locationsLazy)));
  }

  add(companyId: number, location: Location) {
    return this.http.post(this.baseUrl + '/' + companyId + '/locations', new LocationOutput().convertFrom(location));
  }

  edit(companyId: number, locationId: number, location: Location) {
    return this.http.post(this.baseUrl + '/' + companyId + '/locations/' + locationId, new LocationOutput().convertFrom(location));
  }

  delete(companyId: number, locationId: number) {
    return this.http.delete(this.baseUrl + '/' + companyId + '/locations/' + locationId);
  }
}
