<app-base-popup [loading]="loading" (cancelClicked)="cancelClicked()" (saveClicked)="saveClicked()">
    <form [formGroup]="reservationTypeForm">
        <div class="form-group">
            <div class="mt-1">
                <label for="durationMinutes" translate>Label.DurationMinutes</label>
                <input type="number" formControlName="durationMinutes" class="form-control"
                    placeholder="{{'Label.DurationMinutes' | translate}}"
                    [ngClass]="{ 'is-invalid': submitted && form.durationMinutes.errors }" required />
                <app-form-error-message *ngIf="submitted" [control]="form.durationMinutes"></app-form-error-message>
            </div>

            <div class="mt-1">
                <label for="unitsCount" translate>Label.UnitsCount</label>
                <input type="number" formControlName="unitsCount" class="form-control"
                    placeholder="{{'Label.UnitsCount' | translate}}"
                    [ngClass]="{ 'is-invalid': submitted && form.unitsCount.errors }" />
                <app-form-error-message *ngIf="submitted" [control]="form.unitsCount"></app-form-error-message>
            </div>

            <div class="form-check ml-3 mt-1">
                <input class="form-check-input" type="checkbox" formControlName="isReservationDurationChangeAvailable"
                    id='isReservationDurationChangeAvailableCb'
                    [ngClass]="{ 'is-invalid': submitted && form.isReservationDurationChangeAvailable.errors }">
                <label class="form-check-label" for="isReservationDurationChangeAvailableCb" translate>
                    Label.IsReservationDurationChangeAvailable
                </label>
            </div>

            <div *ngIf="(supportedLanguages && !config.data.reservationTypeId) || reservationType"
                class="form-group mt-4">
                <div *ngFor="let language of supportedLanguages" class="mt-1">
                    <label>{{"Label.ReservationTypeName" | translate}} - {{language.displayName}}</label>
                    <input class="form-control" [formControlName]="language.id"
                        placeholder="{{'Label.ReservationTypeName' | translate}} - {{language.displayName}}"
                        [ngClass]="{ 'is-invalid': submitted && form[language.id].errors }" />
                    <app-form-error-message *ngIf="submitted" [control]="form[language.id]"></app-form-error-message>
                </div>
            </div>
        </div>
    </form>
</app-base-popup>