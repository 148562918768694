import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { addDays, parseDate } from 'src/app/helper/date-time-helpers';
import { createHttpParams } from 'src/app/helper/http-params-creator-helpers';
import { environment } from 'src/environments/environment';

import { ReservationHistoryReport } from './../models/base/history-report/reservation-history.model';

@Injectable({
  providedIn: 'root'
})
export class ReservationHistoryService {

  baseUrl = environment.baseUrl + '/companies';

  constructor(private http: HttpClient) { }

  GetReservationsHistoryReport(companyId: number, ddateTimeFrom: Date, ddateTimeTo: Date,
                               warehouseId: number, locationId: number): Observable<ReservationHistoryReport> {

    const dateTimeFrom = parseDate(ddateTimeFrom);
    const dateTimeTo = parseDate(addDays(ddateTimeTo, 1));

    const params = createHttpParams({ dateTimeFrom, dateTimeTo, warehouseId, locationId });

    return this.http.get(this.baseUrl + '/' + companyId + '/reports/reservations-history', { params })
    .pipe(map(report => new ReservationHistoryReport().convertFrom(report)));
  }
}
