import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '../../shared/services';
import { AlertService } from 'src/app/alert';

@Component({
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.css']
})
export class RequestResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;

  loading = false;
  submitted = false;

  returnUrl: string;

  get form() {
    return this.resetPasswordForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {  }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/login';
  }

  onReset() {
    this.submitted = true;
    this.alertService.clear();

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.resetPassword(this.form.email.value)
      .subscribe({
        next: () => {
          this.translateService.get('Info.ResetPasswordEmailSent')
          .subscribe(x => this.alertService.success(x, {keepAfterRouteChange: true, autoClose: true}));
          this.router.navigate([this.returnUrl]);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }
}
