<table class="table table-hover table-scallable">
    <thead>
        <tr style="background-color:#667380;">
            <th style="color: #fff; font-weight: 400; border-radius: 3px;">
                {{slotData.name}}
            </th>
        </tr>
    </thead>
    <tbody style="background-color:#fafafa;">
        <ng-container *ngFor="let i = index; let reservation of slotData?.items">
            <!-- Czas wolny przed obiektem -->
            <tr *ngFor="let j = index; let freeTime of getFreeTimeBetweenReservations(i, false, slotData)">
                <td [style]="getFreeTimeStyle()" (click)="freeTimeClicked(freeTime)">{{freeTime | date:'HH:mm'}}</td>
            </tr>

            <!-- Jeśli obiekt jest awizacją -->
            <tr *ngIf="reservation.itemType=='reservation'">
                <td style="padding: 0px;" (click)="reservationClicked(reservation)">
                    <app-reservation [reservation]="reservation" [style]="getReservationStyle(i)"></app-reservation>
                </td>
            </tr>
            <!-- Jeśli obiekt jest blokadą -->
            <ng-container *ngIf="reservation.itemType=='lock'">
                <tr *ngIf="getLockedSlotItem(i).canModify == false" >
                    <td class="lock-not-editable" [style]="getLockedTimeStyle(i)">
                        {{reservation.startDateTime | date:'HH:mm'}} - {{reservation.endDateTime | date:'HH:mm'}}<br>
                        <p class="reason-text">{{getLockedSlotItem(i)?.reason}}</p>
                    </td>
                </tr>

                <ng-container *ngIf="getLockedSlotItem(i).canModify == true">
                    <tr *ngFor="let j = index; let freeTime of getLockedItemTimeSlots(i)">
                        <td class="lock-editable" [style]="getLockedTimeStyle(i)" (click)="freeTimeClicked(freeTime)">
                            {{freeTime | date:'HH:mm'}}<br>
                            <p class="reason-text">{{getLockedSlotItem(i)?.reason}}</p>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>

            <!-- Czas wolny po awizacji (dla ostatniej danego dnia) -->
            <ng-container *ngIf="slotData.items.length - 1 == i">
                <tr *ngFor="let j = index; let freeTime of getFreeTimeBetweenReservations(i, true)">
                    <td [style]="getFreeTimeStyle()" (click)="freeTimeClicked(freeTime)">{{freeTime | date:'HH:mm'}}
                    </td>
                </tr>
            </ng-container>
        </ng-container>

        <!-- Rysowanie, gdy slot jest pusty -->
        <ng-container *ngIf="slotData?.items?.length == 0">
            <tr *ngFor="let j = index; let freeTime of getFreeTimeBetweenReservations(-1)">
                <td [style]="getFreeTimeStyle()" (click)="freeTimeClicked(freeTime)">{{freeTime | date:'HH:mm'}}</td>
            </tr>
        </ng-container>
    </tbody>
</table>
