import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from 'src/app/alert';
import { Subscription } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';

import { CoreService } from 'src/app/core/core.service';
import { ReservationHistoryService } from './../../shared/services/reservation-history.service';
import { CompanyRole } from 'src/app/shared/models/base/companyRole.model';
import { ReservationHistoryReport } from 'src/app/shared/models/base/history-report/reservation-history.model';
import { parseDate } from 'src/app/helper/date-time-helpers';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-moderator-reservation-report',
  templateUrl: './moderator-reservation-report.component.html',
  styleUrls: ['./moderator-reservation-report.component.scss'],
  providers: [DialogService]
})
export class ModeratorReservationReportComponent implements OnInit, OnDestroy {

  rangeDates: Date[];

  selectedCompany: CompanyRole;
  selectedCompanySub: Subscription;

  loading: boolean;
  canExport: boolean;
  canGetHistory: boolean;

  selectedDate: Date[];
  selectedLocation: any;
  selectedWarehouse: any;

  reservationHistrory: ReservationHistoryReport;

  constructor(public coreService: CoreService,
              private reservationHistoryService: ReservationHistoryService,
              private alertService: AlertService,
              private datePipe: DatePipe) { }

  ngOnInit() {
    this.selectedCompanySub = this.coreService.selectedCompany$.subscribe(comp => {
      this.selectedCompany = comp;
    });
  }

  ngOnDestroy() {
    this.selectedCompanySub.unsubscribe();
  }

  onFilterChanged(event: any) {
    this.selectedDate = event.date;
    this.selectedLocation = { id: event.locationId, code: event.locationCode };
    this.selectedWarehouse = { id: event.warehouseId, name: event.warehouseName };

    this.canExport = false;
    this.canGetHistory = !!this.selectedDate && !!this.selectedLocation?.code && !!this.selectedWarehouse?.name;
  }

  getReservationHistory() {
    if (!this.selectedCompany) {
      return;
    }

    const dateFrom = this.selectedDate[0];
    let dateTo = this.selectedDate[1];

    if (!dateTo) {
      dateTo = dateFrom;
    }

    this.loading = true;

    this.reservationHistoryService.GetReservationsHistoryReport(this.selectedCompany.company.id, dateFrom,
      dateTo, this.selectedWarehouse.id, this.selectedLocation.id)
      .subscribe({
        next: (next) => {
          // Korzystamy z api, żeby mieć świeże dane
          this.reservationHistrory = next;
          this.loading = false;
          this.canExport = true;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  exportExcel() {
    import('xlsx').then(xlsx => {
      let reservationHistoryReservationsExcel = this.reservationHistrory.reservations as any[];
      reservationHistoryReservationsExcel.map(reservation => {
        reservation.startDateTime = this.datePipe.transform(reservation.startDateTime, 'yyyy-MM-dd HH:mm:ss');
        reservation.endDateTime = this.datePipe.transform(reservation.endDateTime, 'yyyy-MM-dd HH:mm:ss');
        reservation.changedDateTime = this.datePipe.transform(reservation.changedDateTime, 'yyyy-MM-dd HH:mm:ss');
        reservation.deletedDateTime = this.datePipe.transform(reservation.deletedDateTime, 'yyyy-MM-dd HH:mm:ss');
      });
      const objToExport = this.reservationHistrory.reservations as any[];

      const headers = {
        id: 'Id', startDateTime: 'Data i godzina awizacji', endDateTime: 'Data i godzina zakończenia załadunku',
        unitsCount: 'Ilość jednostek', companyName: 'Nazwa klienta', slotName: 'Slot', documentNumber: 'Numer dokumentu',
        type: 'Typ awizacji', statusName: 'Status',changedDateTime: 'Data i godzina zmiany', 
        changedBy: 'Osoba zmieniająca'
      };

      objToExport.unshift(headers);

      const worksheet = xlsx.utils.book_new();
      xlsx.utils.sheet_add_aoa(worksheet, [['RAPORT AWIZACJI'], [this.selectedLocation.code], [this.selectedWarehouse.name],
      [parseDate(this.selectedDate[0]) + ' - ' + parseDate(this.selectedDate[1])]]);
      xlsx.utils.sheet_add_json(worksheet, objToExport, { origin: 'A6', skipHeader: true, dateNF: this.coreService.dateTimePatternValue });

      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'awizacje');

      objToExport.shift();
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
}
