import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-management-panel',
  templateUrl: './management-panel.component.html',
  styleUrls: ['./management-panel.component.scss']
})
export class ManagementPanelComponent implements OnInit {

  public items: MenuItem[];
  public selectedItem: MenuItem;

  constructor(public translate: TranslateService,
              public router: Router) { }

  ngOnInit() {
  }
}
