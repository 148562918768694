import { HttpParams } from '@angular/common/http';

export function createHttpParams(params: {}): HttpParams {
  let httpParams: HttpParams = new HttpParams();
  Object.keys(params).forEach(param => {
    if (params[param]) {
      if (params[param] instanceof Array) {
        params[param].forEach(paramElement => {
          httpParams = httpParams.append(param, paramElement);
        });
      } else {
        httpParams = httpParams.append(param, params[param]);
      }
    }
  });
  return httpParams;
}
